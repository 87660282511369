<template>
  <div
    v-if="processes"
    :class="[
      'min-w-64',
      {
        'min-h-max lg:h-[700px] lg:w-[1000px] w-full': visibleMonthsStartDate.length > 8,
      },
    ]"
  >
    <div
      :class="[
        'flex flex-col justify-content-stretch justify-between ',
        { 'w-full h-full': visibleMonthsStartDate.length > 8 },
      ]"
      v-if="!isLoading"
    >
      <div
        class="relative flex items-center mb-4 md:items-stretch"
        v-if="visibleMonthsStartDate.length > 8"
      >
        <!-- Pagination Buttons -->
        <button
          type="button"
          class="flex h-6 w-8 items-center justify-center rounded-l border-y border-x border-gray-300 pr-1 text-gray-400 focus:relative md:w-9 md:pr-0 md:hover:bg-yellow-100 disabled:text-gray-200 disabled:border-gray-200 hover:border-yellow hover:text-yellow disabled:hover:bg-transparent"
          title="Previous"
          :disabled="currentPage === 1"
          @click="prevPage"
        >
          <span class="sr-only">Previous</span>
          <ChevronLeftIcon class="h-4 w-4" aria-hidden="true" />
        </button>
        <button
          type="button"
          class="flex h-6 w-8 items-center justify-center rounded-r border-y border-x border-gray-300 pl-1 text-gray-400 focus:relative md:w-9 md:pl-0 md:hover:bg-yellow-100 disabled:text-gray-200 disabled:border-gray-200 hover:border-yellow hover:text-yellow disabled:hover:bg-transparent"
          title="Next"
          :disabled="currentPage === totalPages"
          @click="nextPage"
        >
          <span class="sr-only">Next</span>
          <ChevronRightIcon class="h-4 w-4" aria-hidden="true" />
        </button>
      </div>
      <div
        :class="[
          'relative gap-x-8 gap-y-4 flex-1',
          visibleMonthsStartDate.length >= 4
            ? 'grid sm:grid-cols-2 lg:grid-cols-4 md:grid-cols-3'
            : 'flex flex-wrap',
        ]"
      >
        <section
          v-for="(firstDayOfMonth, monthIdx) in paginatedMonths"
          :key="monthIdx"
          :class="[
            visibleMonthsStartDate.length === 1 && 'sm:mx-auto',
            'mx-auto sm:mx-0 sm:w-auto w-full',
          ]"
        >
          <h2 class="text-sm font-semibold text-gray-900">
            {{ $t(`calendar.months.${format(firstDayOfMonth, "M")}.long`) }}
            {{ format(firstDayOfMonth, "yyyy") }}
          </h2>
          <div class="mt-2 grid grid-cols-7 text-xs cursor-default leading-6 text-gray-500 z-10">
            <template v-for="index in 7" :key="index">
              <div>
                {{ $t(`calendar.week_days.${index}.short`).charAt(0) }}
              </div>
            </template>
          </div>
          <div
            ref="calendar"
            class="mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-xs lg:text-sm shadow ring-1 ring-gray-200"
          >
            <div
              v-for="(day, dayIdx) in allCalendarDates[monthIdx]"
              :key="dayIdx"
              class="lg:p-1.5 p-0.5 focus:z-10 relative text-center"
              @click="
                isWithinWorkingInterval(day) && isCurrentMonth(firstDayOfMonth, day)
                  ? goToActivityLog(day)
                  : ''
              "
              :class="[
                dayIdx === 0 && 'rounded-tl-lg',
                dayIdx === 6 && 'rounded-tr-lg',
                processDetailMap[day]?.processes?.length > 0 && isCurrentMonth(firstDayOfMonth, day)
                  ? 'cursor-pointer'
                  : 'cursor-default',
                dayIdx === allCalendarDates[monthIdx].length - 7 && 'rounded-bl-lg',
                dayIdx === allCalendarDates[monthIdx].length - 1 && 'rounded-br-lg',
                tooltipDay === day && 'opacity-60',
                {
                  'bg-gray-400 text-white':
                    isWithinWorkingInterval(day) && isCurrentMonth(firstDayOfMonth, day),
                  'bg-white-500 hover:bg-gray-100':
                    (!isWithinWorkingInterval(day) && !processDetailMap[day]) ||
                    !isCurrentMonth(firstDayOfMonth, day),
                  [`diagonal-${processDetailMap[day]?.status}`]:
                    processDetailMap[day]?.working_day_count === 0.5 &&
                    isCurrentMonth(firstDayOfMonth, day),
                  startDate:
                    day === format(intervalOrFallback[0], 'yyyy-MM-dd') &&
                    isCurrentMonth(firstDayOfMonth, day),
                  endDate:
                    day === format(intervalOrFallback[1], 'yyyy-MM-dd') &&
                    isCurrentMonth(firstDayOfMonth, day),
                },
              ]"
              :style="{
                backgroundColor: getBackgroundColor(day, firstDayOfMonth),
                color:
                  (!isWithinWorkingInterval(day) && !processDetailMap[day]) ||
                  !isCurrentMonth(firstDayOfMonth, day)
                    ? 'gray'
                    : 'white',
              }"
            >
              <div v-if="isCurrentMonth(firstDayOfMonth, day)">
                <span
                  class="topLeftTriangle"
                  v-if="processDetailMap[day]?.non_critical_disturbances.length > 0"
                ></span>

                <span
                  class="bottomLeftTriangle"
                  v-if="
                    (processDetailMap[day]?.holidays.length > 0 ||
                      processDetailMap[day]?.critical_disturbances.length > 0) &&
                    processDetailMap[day].processes.length > 0
                  "
                ></span>
                <div
                  :class="[
                    'group relative',
                    processDetailMap[day]?.processes?.length && isCurrentMonth(firstDayOfMonth, day)
                      ? 'cursor-pointer'
                      : 'cursor-default',
                  ]"
                  @mouseover="handleMouseMoveOnDate($event, day)"
                  @mouseleave="tooltipDay = ''"
                >
                  {{ format(new Date(day), "d") }}
                  <Teleport to="#tooltip" v-if="tooltipDay === day">
                    <div
                      v-if="isWithinWorkingInterval(day)"
                      :style="{ top: topVal + 'px', left: leftVal + 'px' }"
                      class="tooltip-text tooltip-center h-max max-w-32 text-left flex-col gap-1.5 min-w-48 text-sm w-max fixed py-2 px-3 z-[9999] border rounded bg-white text-gray-600 flex"
                    >
                      <span class="text-sm"
                        >{{ format(new Date(day), "dd.MM.yyyy") }},
                        {{ $t(`calendar.week_days.${getDay(new Date(day))}.long`) }}</span
                      >
                      <hr class="w-full" />
                      <div
                        class="flex items-center gap-0.5"
                        v-if="processDetailMap[day].working_day_count > 0"
                      >
                        <span
                          class="w-1.5 h-1.5 rounded-full"
                          :style="{ backgroundColor: getBackgroundColor(day, firstDayOfMonth) }"
                        ></span>
                        <small>
                          {{ $t("analytics.reports.working_hours", 2) }}:
                          {{ calculateWorkHoursSum(processDetailMap[day]?.processes, true) }}
                        </small>
                      </div>
                      <div
                        class="flex mb-0.5 gap-y-0.5 gap-x-1 leading-none"
                        v-for="processElement in getUniqueProcessElements(
                          processDetailMap[day]?.processes,
                        )"
                        :key="processElement"
                      >
                        <div
                          class="w-1"
                          :style="`background: ${processElementColor[processElement as ProcessElement]}`"
                        />
                        <small>
                          {{ $t(`process_classes.${processElement}`) }}:
                          {{
                            calculateWorkHoursSum(
                              processDetailMap[day]?.processes.filter((item: ShortenedProcess) => {
                                return (
                                  getProcessClassFromEncodedLabel[item?.encoded_label]
                                    .processElement === processElement
                                );
                              }),
                              true,
                            )
                          }}
                          {{ $t("time.hour") }}
                        </small>
                      </div>
                      <div v-if="processDetailMap[day].outages.length">
                        <div
                          v-for="outage in processDetailMap[day].outages"
                          :key="outage.camera_id"
                          class="grid grid-cols-2 gap-1 mt-1 text-xs items-center justify-between"
                        >
                          <small class="capitalize truncate col-span-1"
                            >{{ getCameraNameById(outage.camera_id) }}:</small
                          >
                          <small
                            :style="{ backgroundColor: workingDaysColorCodes.outage }"
                            class="rounded px-1 text-white py-0.5 col-span-1 text-center"
                            >{{ format(outage.start_time, "HH:mm") }} -
                            {{ format(outage.end_time, "HH:mm") }}</small
                          >
                        </div>
                      </div>

                      <div
                        v-for="(holiday, key) in processDetailMap[day].holidays"
                        :key="key"
                        class="overflow-hidden truncate whitespace-nowrap w-full"
                      >
                        <small
                          class="rounded px-1 text-white py-0.5 mt-1"
                          :style="{ backgroundColor: '#bfdbfe4c', color: '#1d4ed8' }"
                        >
                          {{ holiday }}
                        </small>
                      </div>
                      <div
                        v-for="(critical_disturbance, index) in processDetailMap[day]
                          ?.non_critical_disturbances"
                        :key="index"
                        class="overflow-hidden truncate whitespace-nowrap w-full"
                        :title="critical_disturbance"
                      >
                        <small class="bg-yellow-300 text-yellow-700 px-1.5 py-0.5 rounded">
                          {{ critical_disturbance }}
                        </small>
                      </div>
                      <div v-if="processDetailMap[day].working_day_count === 0">
                        <small class="bg-gray-400 text-white px-1.5 py-0.5 rounded">{{
                          $t("working_day.non_working_day")
                        }}</small>
                      </div>
                    </div>
                  </Teleport>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        :class="[
          'grid gap-1 mt-6 text-sm justify-center w-auto',
          visibleMonthsStartDate.length > 1 && 'sm:grid-cols-2 sm:justify-stretch',
        ]"
      >
        <div>
          <div v-for="item in ['active', 'inactive', 'outage']" :key="item">
            <div
              class="flex items-center gap-1"
              v-if="processDetailSummary[item as keyof typeof processDetailSummary] !== 0"
            >
              <span
                :style="{ backgroundColor: workingDaysColorCodes[item] }"
                class="w-2.5 h-2.5 rounded-full"
              />
              <small class="capitalize">
                {{ $t(`working_day.${item}`) }}:
                <span>{{ processDetailSummary[item as keyof typeof processDetailSummary] }}</span>
                {{
                  processDetailSummary[item as keyof typeof processDetailSummary] > 1
                    ? $t("working_day.working_day", 2)
                    : $t("working_day.working_day", 1)
                }}
              </small>
            </div>
          </div>
        </div>
        <div :class="visibleMonthsStartDate.length > 1 && 'sm:justify-self-end'">
          <div v-for="item in ['holiday', 'critical_disturbance']" :key="item">
            <div
              class="flex items-center gap-1"
              v-if="processDetailSummary[item as keyof typeof processDetailSummary] !== 0"
            >
              <div
                :style="{ backgroundColor: workingDaysColorCodes[item] }"
                class="w-2.5 h-2.5 rounded-full"
              />
              <small class="capitalize">
                {{ $t(`working_day.${item}`) }}:
                <span>{{ processDetailSummary[item as keyof typeof processDetailSummary] }}</span>
                {{
                  processDetailSummary[item as keyof typeof processDetailSummary] > 1
                    ? $t("working_day.working_day", 2)
                    : $t("working_day.working_day", 1)
                }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="[
          'text-sm mt-3 text-center sm:text-left',
          visibleMonthsStartDate.length === 1 && 'sm:text-center',
        ]"
        v-if="totalDuration"
      >
        <small
          >{{ $t("working_day.working_day", totalDuration) }}:
          {{ totalDuration }}
        </small>
      </div>
    </div>
    <div class="flex justify-center items-center w-full h-full" v-else>
      <LoadingSpinner size="w-6 h-6" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import {
  ProcessClass,
  ProcessElement,
  useIsWorkingDay,
  useNonWorkingDaysByDaySet,
  useProjectDurationSettings,
} from "oai-services";
import { computed, Ref, ref } from "vue";
import { useOutagesByRange } from "@/composables/outages";
import { useProcessClasses } from "@/composables/process";
import { useStreams } from "@/composables/stream";
import { processElementColor } from "@/constants/processClasses";
import { workingDaysColorCodes } from "@/constants/workingDays";
import { apiClient } from "@/repositories/clients";
import router from "@/router";
import processDurationService from "@/services/processDurationService";
import { ShortenedProcess } from "@/types/Process";
import LoadingSpinner from "../loading_state/LoadingSpinner.vue";

const processClasses = useProcessClasses();

const props = defineProps<{ processes: ShortenedProcess[]; interval?: [Date, Date] }>();

const calendar = ref(null);
const { streams } = useStreams();
const { projectDurationSettings } = useProjectDurationSettings(apiClient);
const nonWorkingDaysByDaySet = useNonWorkingDaysByDaySet(apiClient);
const isWorkingDay = useIsWorkingDay(apiClient);

const intervalOrFallback: Ref<[Date, Date]> = computed(() => {
  const firstProcessDate = processDurationService.getFirstProcessDate(props.processes);
  const lastProcessDate = processDurationService.getLastProcessDate(props.processes);
  return props.interval || [firstProcessDate, lastProcessDate];
});

const outagesInterval = computed(() => ({
  start: intervalOrFallback.value[0],
  end: intervalOrFallback.value[1],
}));

const tooltipDay = ref();

const { outagesByRange, isLoading } = useOutagesByRange(outagesInterval);
const intervalDateStrings = computed(
  () =>
    new Set<string>(
      eachDayOfInterval({
        start: intervalOrFallback.value[0],
        end: intervalOrFallback.value[1],
      }).map((date) => format(date, "yyyy-MM-dd")),
    ),
);

const visibleMonthsStartDate = computed(() =>
  eachMonthOfInterval({
    start: intervalOrFallback.value[0],
    end: intervalOrFallback.value[1],
  }),
);

const getProcessClassFromEncodedLabel = processClasses.value.reduce((acc, processClass) => {
  acc[processClass.encodedLabel] = processClass;
  return acc;
}, {} as Record<number, ProcessClass>);

const outages = computed(() => {
  if (!nonWorkingDaysByDaySet.value) {
    return outagesByRange.value;
  }

  return processDurationService.useCorrectOutages(
    outagesByRange.value,
    streams.value,
    nonWorkingDaysByDaySet.value,
    isWorkingDay.value,
  );
});

const processDetailMap = computed(() => {
  if (!projectDurationSettings.value || !outages.value) {
    return {};
  }

  return processDurationService.getProcessDetailMap(
    props.processes,
    projectDurationSettings.value,
    outages.value,
    props.interval && eachDayOfInterval({ start: props.interval[0], end: props.interval[1] }),
  );
});
const processDetailSummary = computed(() => {
  return processDurationService.getProcessDetailSummary(processDetailMap.value);
});

const totalDuration = computed(() => {
  return processDurationService.getTotalDuration(processDetailSummary.value);
});

const isWithinWorkingInterval = (day: string) => {
  return intervalDateStrings.value.has(day);
};

const isCurrentMonth = (currMonth: Date, date: string) => {
  return currMonth.getMonth() === new Date(date).getMonth();
};

const getBackgroundColor = (day: string, firstDayOfMonth: Date) => {
  if (!isCurrentMonth(firstDayOfMonth, day)) {
    return "";
  }

  const item = processDetailMap.value[day];

  if (item && item.working_day_count > 0) {
    if (item.status === "holiday") {
      return workingDaysColorCodes.holiday;
    }
    if (item.status === "critical_disturbance") {
      return workingDaysColorCodes.critical_disturbance;
    }
    if (item.non_critical_disturbances.length === 0) {
      if (item.status === "active") {
        return workingDaysColorCodes.active;
      } else if (item.status === "outage") {
        return workingDaysColorCodes.outage;
      } else {
        return workingDaysColorCodes.inactive;
      }
    }
    if (item.working_day_count > 0 && item.status) {
      return workingDaysColorCodes[item.status] || "";
    }
  }
  return "";
};

const calculateWorkHoursSum = (processes: ShortenedProcess[], decimalHours = false) => {
  return processDurationService.calculateWorkHoursSum(processes, decimalHours);
};

const getUniqueProcessElements = (processes: ShortenedProcess[]) => {
  const uniqueLabels: number[] = [
    ...new Set(processes.map((item: ShortenedProcess) => item?.encoded_label)),
  ];

  return [
    ...new Set(uniqueLabels.map((label) => getProcessClassFromEncodedLabel[label]?.processElement)),
  ];
};

const topVal = ref();
const leftVal = ref();

const handleMouseMoveOnDate = (event: MouseEvent, day: string) => {
  const target = event.target as HTMLElement | null;
  if (target) {
    const divLocation = target?.getBoundingClientRect();
    topVal.value = divLocation.y + 25;
    leftVal.value = divLocation.x + 10;
    tooltipDay.value = day;
  }
};

const currentPage = ref(1);
const itemsPerPage = 8;

const totalPages = computed(() => {
  return Math.ceil(visibleMonthsStartDate.value.length / itemsPerPage);
});

const paginatedMonths = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return visibleMonthsStartDate.value.slice(startIndex, endIndex);
});

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const allCalendarDates = computed(() => {
  return paginatedMonths.value.map((monthStartDate) => {
    const startOfCurrentMonth = startOfMonth(monthStartDate);
    const endOfCurrentMonth = endOfMonth(monthStartDate);
    const start = startOfWeek(startOfCurrentMonth, { weekStartsOn: 1 });
    const end = endOfWeek(endOfCurrentMonth, { weekStartsOn: 1 });

    return eachDayOfInterval({ start: startOfDay(start), end: endOfDay(end) }).map((day) =>
      format(day, "yyyy-MM-dd"),
    );
  });
});

const goToActivityLog = (date: string) => {
  if (processDetailMap.value[date].processes.length === 0) {
    return;
  }
  const processList = props.processes
    .filter((item) => item.date === date)
    .flatMap((item) => item._id);
  const link = {
    name: "ProcessesTable",
    query: {
      process_ids: processList.join(","),
    },
  };

  const routeData = router.resolve(link);

  if (routeData) {
    window.open(routeData.href, "_blank");
  }
};

const getCameraNameById = (id: string) => {
  const camera = streams.value.find((item) => item.camera_id === id);
  return camera ? camera.name : null;
};
</script>

<style scoped>
.startDate {
  @apply border-l-2 border-solid border-red-500 !important;
}
.endDate {
  @apply border-r-2 border-solid border-red-500 !important;
}
.diagonal-active {
  background-image: linear-gradient(45deg, #336260 50%, #9ca3af 50%);
}
.diagonal-inactive {
  background-image: linear-gradient(45deg, #991b1b 50%, #9ca3af 50%);
}
.diagonal-critical_disturbance {
  background-image: linear-gradient(45deg, #e7bc66 50%, #9ca3af 50%);
}
.diagonal-outage {
  background-image: linear-gradient(45deg, #fca5a5 50%, #9ca3af 50%);
}
.diagonal-holiday {
  background-image: linear-gradient(45deg, #add8e6 50%, #9ca3af 50%);
}

.topLeftTriangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 0; /* Adjust triangle size here */
  border-color: #e7bc66 transparent transparent transparent; /* Adjust triangle color */
}

.bottomLeftTriangle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent transparent #336260 transparent; /* Adjust triangle color */
}
.tooltip-text::after {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.tooltip-text.tooltip-center::after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-center {
  @apply bottom-full left-1/2 -translate-x-1/2;
}
</style>
