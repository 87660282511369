import { AxiosInstance } from "axios";
import { parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import PlannerRepository from "../repositories/PlannerRepository";
import { ShortenedProcessWithTags } from "../types/Process";

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

export default (client: AxiosInstance) => {
  const loadShortenedProcessesWithTags = (
    customerName: string,
    siteId: string,
  ): Promise<ShortenedProcessWithTags[]> =>
    client
      .get<ShortenedProcessWithTags<string>[]>(
        `/ops-process/shortened-processes-with-tags/${customerName}/${siteId}`,
      )
      .then((response) =>
        response.data.map((process) => {
          const mappedProcess = {
            ...process,
            start_time: parseUtcDate(process.start_time),
            end_time: parseUtcDate(process.end_time),
            work_intervals: process.work_intervals.map((workInterval) => ({
              ...workInterval,
              start_time: parseUtcDate(workInterval.start_time),
              end_time: parseUtcDate(workInterval.end_time),
            })),
          };
          return {
            ...mappedProcess,
            breaks: PlannerRepository(client).calculateProcessBreaks(mappedProcess),
          };
        }),
      );

  return {
    loadShortenedProcessesWithTags,
  };
};
