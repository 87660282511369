import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { isAxiosError } from "axios";
import {
  ActualEventChanges,
  PlanConfig,
  useCurrentCustomerName,
  useCurrentSiteId,
} from "oai-services";
import { useI18n } from "vue-i18n";
import { useCustomToast } from "@/composables/toast";
import PlannerRepository from "@/repositories/PlannerRepository";
import logger from "@/services/logger";

export const usePlanConfig = (customerName: string, siteId: string) => {
  const { t } = useI18n();
  const {
    data: planConfig,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["plan-config", customerName, siteId],
    queryFn: () => PlannerRepository.loadPlanConfig(customerName, siteId),
    useErrorBoundary: (error) => {
      if (!isAxiosError(error) || error?.response?.status !== 404) {
        logger.error(error);
        useCustomToast().error(t("analytics.planner.unable_to_load_plan"));
      }
      return false;
    },
  });

  return { planConfig, isLoading, error };
};

export const useUpdateActualEvents = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const { t } = useI18n();
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateActualEvents,
    isLoading: isLoading,
    error,
  } = useMutation<
    {
      added_ids: {
        _id: string;
        db_id: string;
      }[];
    },
    Error,
    ActualEventChanges
  >({
    mutationFn: (changes) =>
      PlannerRepository.saveActualEventChanges(customerName, siteId, changes),
    onSuccess: (_result, changes) => {
      const modifiedById = changes.modified.reduce((acc, item) => {
        acc[item._id] = item;
        return acc;
      }, {} as Record<string, (typeof changes.modified)[0]>);
      queryClient.setQueryData(["plan-config", customerName, siteId], (planConfig?: PlanConfig) => {
        if (!planConfig) {
          return undefined;
        }
        return {
          ...planConfig,
          actual_events: planConfig.actual_events.map((actualEvent) =>
            actualEvent._id in modifiedById
              ? { ...actualEvent, ...modifiedById[actualEvent._id] }
              : actualEvent,
          ),
        };
      });
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.planner.unable_to_save_changes"));
      return false;
    },
  });

  return { updateActualEvents, isLoading, error };
};
