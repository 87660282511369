import { createI18n } from "vue-i18n";
import de from "./assets/i18n/de";
import en from "./assets/i18n/en";
import fr from "./assets/i18n/fr";

const messages = {
  en,
  de,
  fr,
};

const customPluralRule = (choice: number) => {
  return choice === 1 ? 0 : 1;
};

const i18n = createI18n({
  locale: localStorage.getItem("lang") || "de",
  messages,
  pluralRules: {
    en: customPluralRule,
    de: customPluralRule,
    fr: customPluralRule,
  },
});

export default i18n;
