import { AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { EncodedLabel, OpsProcessesRepository } from "oai-services";
import PlannerRepository from "@/repositories/PlannerRepository";
import { LastSeenProcessResult, Process, TagGroupedProcessResponse } from "@/types/Process";
import { apiClient } from "./clients";

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

const mapProcess = (process: Process<string>) => ({
  ...process,
  start_time: parseUtcDate(process.start_time),
  end_time: parseUtcDate(process.end_time),
});

const loadTagGroupedProcesses = (customerName: string, siteId: string) =>
  apiClient
    .get<TagGroupedProcessResponse>(`/ops-process/group-tag/${customerName}/${siteId}`)
    .then((response) => {
      const mappedProcesses = response.data.data.map((process) => ({
        ...process,
        processes: process.processes.map((process) => {
          const mappedProcess = {
            ...process,
            start_time: parseUtcDate(process.start_time as unknown as string),
            end_time: parseUtcDate(process.end_time as unknown as string),
            work_intervals: (process.work_intervals || []).map((workInterval) => ({
              ...workInterval,
              start_time: parseUtcDate(workInterval.start_time as unknown as string),
              end_time: parseUtcDate(workInterval.end_time as unknown as string),
            })),
          };
          return {
            ...mappedProcess,
            breaks: PlannerRepository.calculateProcessBreaks(mappedProcess),
          };
        }),
      }));

      return {
        levelOrders: response.data.levelOrders,
        data: mappedProcesses,
      };
    });

const loadProcessVideoUrl = (
  customerName: string,
  siteId: string,
  processDbId: string,
): Promise<{ url: string }> =>
  apiClient
    .get<void, AxiosResponse<{ url: string }>>(
      `/ops-process/process-video/${customerName}/${siteId}/${processDbId}`,
    )
    .then((response) => response.data);

const loadProcessThumbnail = (
  customerName: string,
  siteId: string,
  processDbId: string,
): Promise<Blob | null> =>
  apiClient
    .get<void, AxiosResponse<Blob>>(
      `/ops-process/process-thumbnail/${customerName}/${siteId}/${processDbId}`,
      {
        responseType: "blob",
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const loadLastSeenProcess = (
  customerName: string,
  siteId: string,
): Promise<LastSeenProcessResult> =>
  apiClient
    .get<void, AxiosResponse<LastSeenProcessResult<string>>>(
      `/ops-process/last-seen-process/${customerName}/${siteId}`,
    )
    .then((response) => {
      return {
        ...response.data,
        process: response.data.process && mapProcess(response.data.process),
      };
    });

const loadNextLastSeenProcess = (
  customerName: string,
  siteId: string,
  processId: string,
): Promise<LastSeenProcessResult> =>
  apiClient
    .get<void, AxiosResponse<LastSeenProcessResult<string>>>(
      `/ops-process/last-seen-process/${customerName}/${siteId}/${processId}/next`,
    )
    .then((response) => {
      return {
        ...response.data,
        process: response.data.process && mapProcess(response.data.process),
      };
    });

const loadMappableEncodedLabels = (processDbId: string, sectionMaskId?: string | null) => {
  const searchParams = new URLSearchParams();
  if (sectionMaskId) {
    searchParams.set("section_mask_id", sectionMaskId);
  }

  return apiClient
    .get<void, AxiosResponse<EncodedLabel[]>>(
      `/prd-validation/processes/${processDbId}/mappable-encoded-labels?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

export default {
  ...OpsProcessesRepository(apiClient),
  loadTagGroupedProcesses,
  loadProcessVideoUrl,
  loadProcessThumbnail,
  loadLastSeenProcess,
  loadNextLastSeenProcess,
  loadMappableEncodedLabels,
};
