<template>
  <Disclosure v-slot="{ open }" :defaultOpen="true">
    <DisclosureButton class="flex gap-2 w-full items-center hover:bg-gray-100 px-2 item">
      <ChevronRightIcon
        :class="{ 'rotate-90': open }"
        class="w-5 h-5 shrink-0"
        v-if="plannerItem.children && plannerItem.children.length > 0"
      />
      <div v-else class="w-5 h-5 shrink-0" />
      <div class="flex gap-2 text-xs flex-1 min-w-0 my-1">
        <ProjectProgressItemName :plannerItem="plannerItem" :tags="tags" />
        <div class="dates hidden items-center gap-1 min-w-0" v-if="!simplified">
          <div
            class="rounded-lg px-2 py-0.5 text-white plannedBg truncate capitalize"
            v-if="plannedEventDates"
          >
            {{ t("analytics.planner.planned_event_name") }}
          </div>
          <div class="truncate">
            {{ plannedEventDates }}
          </div>
          <div
            class="rounded-lg px-2 py-0.5 text-white bg-green truncate ml-2 capitalize"
            v-if="actualEventDates"
          >
            {{ t("analytics.planner.actual_event_name") }}
          </div>
          <div class="truncate">
            {{ actualEventDates }}
          </div>
        </div>
        <button
          class="markAsDoneButton hidden items-center hover:underline truncate"
          type="button"
          v-if="
            plannerItem.children &&
            plannerItem.children.length === 0 &&
            plannerItemProgress.total_working_days !== plannerItemProgress.finished_working_days &&
            plannerItemProgress.total_working_days !== 0 &&
            plannerItemProgress.finished_working_days !== 0
          "
          @click.stop="
            isMarkAsDoneModalOpen = true;
            trackEvent('progress_mark-completed_click');
          "
        >
          {{ t("analytics.planner.mark_as_done_modal.mark_as_done_button") }}
        </button>
        <OaiTooltip cls="shrink-0 w-[80px] flex items-center cursor-pointer" :simple="!simplified">
          <div
            v-if="!simplified || (plannerItem.children && plannerItem.children.length === 0)"
            class="w-full h-full flex items-center justify-center rounded text-white"
            :class="{
              'bg-green-200': percentage !== null && percentage === 1,
              'bg-gray-300': percentage !== null && percentage === 0,
            }"
            :style="{
              background:
                percentage !== null && percentage > 0 && percentage < 1
                  ? `linear-gradient(90deg, #e7bc66 ${percentage * 100}%, #d1d5db ${
                      percentage * 100
                    }%)`
                  : undefined,
            }"
          >
            {{ formatPercentage(percentage) }}
          </div>
          <template #tooltip>
            <div class="text-xs" v-if="plannerItemProgress && !simplified">
              {{ getProgressTooltip(plannerItem, plannerItemProgress) }}
            </div>
            <div
              class="text-xs border bg-white p-4 rounded flex flex-col gap-4 shadow-lg max-w-[500px]"
              v-if="plannerItemProgress && simplified"
            >
              <ProjectProgressItemName :plannerItem="plannerItem" :tags="tags" :simplified="true" />
              <div>
                {{ getProgressTooltip(plannerItem, plannerItemProgress, { showPercentage: true }) }}
              </div>
              <div class="flex flex-col gap-1">
                <div class="flex items-center gap-1" v-if="plannedEventDates">
                  <div
                    class="rounded-lg px-2 py-0.5 text-white plannedBg truncate capitalize text-center min-w-[40px]"
                  >
                    {{ t("analytics.planner.planned_event_name") }}
                  </div>
                  {{ plannedEventDates }}
                </div>
                <div class="flex items-center gap-1" v-if="actualEventDates">
                  <div
                    class="rounded-lg px-2 py-0.5 text-white bg-green truncate capitalize text-center min-w-[40px]"
                  >
                    {{ t("analytics.planner.actual_event_name") }}
                  </div>
                  {{ actualEventDates }}
                </div>
              </div>
            </div>
          </template>
        </OaiTooltip>
        <div class="flex items-center w-[80px] justify-end shrink-0" v-if="!simplified">
          {{ formatPercentage(plannerItemProgress?.weight ?? null) }}
        </div>
      </div>
    </DisclosureButton>
    <DisclosurePanel>
      <div class="ml-4">
        <ProjectProgressItem
          :key="child._id"
          v-for="child in plannerItem.children"
          :plannerItem="child"
          :plannerItemProgressById="plannerItemProgressById"
          :hierarchyTags="hierarchyTags"
          :actualEventsBySourceId="actualEventsBySourceId"
          :plannedEventsByPlannerId="plannedEventsByPlannerId"
          :simplified="simplified"
          :processesBySourceId="processesBySourceId"
        />
      </div>
    </DisclosurePanel>
  </Disclosure>
  <MarkAsDoneModal
    v-if="isMarkAsDoneModalOpen && actualEvent"
    @close="isMarkAsDoneModalOpen = false"
    :actualEventId="actualEvent._id"
    :start="actualEvent.start"
    :processes="processesBySourceId[actualEvent.source_id]"
    :tags="tags"
  />
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { format } from "date-fns";
import { OaiTooltip } from "oai-components";
import { ActualEvent, PlannedEvent, PlannerItemWithChildren } from "oai-services";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTrackEvent } from "@/composables/tracking";
import projectProgressService from "@/services/projectProgressService";
import { HierarchyTagStore, HierarchyType } from "@/types/HierarchyTag";
import { PlannerItemProgress } from "@/types/Plan";
import { ShortenedProcess } from "@/types/Process";
import MarkAsDoneModal from "@/views/planner/components/MarkAsDoneModal.vue";
import ProjectProgressItemName from "@/views/planner/components/ProjectProgressItemName.vue";

const props = defineProps<{
  plannerItem: PlannerItemWithChildren;
  plannerItemProgressById: Record<string, PlannerItemProgress>;
  hierarchyTags: HierarchyTagStore[];
  actualEventsBySourceId: Record<string, ActualEvent>;
  plannedEventsByPlannerId: Record<string, PlannedEvent>;
  simplified?: boolean;
  processesBySourceId: Record<string, ShortenedProcess[]>;
}>();

const { t, locale } = useI18n();
const trackEvent = useTrackEvent();

const precision = 1;

const isMarkAsDoneModalOpen = ref(false);

const formatNumber = (number: number) =>
  number.toLocaleString(locale.value, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: false,
  });

const formatPercentage = (percentage: number | null) =>
  percentage !== null ? `${formatNumber(percentage * 100)}%` : "-";

const plannerItemProgress = computed(() => props.plannerItemProgressById[props.plannerItem._id]);

const percentage = computed(() => {
  if (!plannerItemProgress.value) {
    return null;
  }
  return projectProgressService.calculatePercentage(
    plannerItemProgress.value.finished_working_days,
    plannerItemProgress.value.total_working_days,
  );
});

const tags = computed(() => {
  const hierarchyTagsBySourceId = props.hierarchyTags.reduce((acc, tag) => {
    for (const sourceId of tag.source_ids) {
      const key = `${tag.type}_${sourceId}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(tag);
    }
    return acc;
  }, {} as Record<string, HierarchyTagStore[]>);

  const types: HierarchyType[] = ["building", "level", "section", "component"];
  return types
    .map((type) => hierarchyTagsBySourceId[`${type}_${props.plannerItem.source_id}`]?.at(0))
    .filter((tag) => tag) as HierarchyTagStore[];
});

const dateFormat = "dd.MM.yy";

const actualEventDates = computed(() => {
  const actualEvent = props.actualEventsBySourceId[props.plannerItem.source_id];
  if (!actualEvent) {
    return null;
  }
  if (!actualEvent.end) {
    return `${format(actualEvent.start, dateFormat)}-${t(
      "analytics.planner.present",
    ).toLowerCase()}`;
  }
  return `${format(actualEvent.start, dateFormat)}-${format(actualEvent.end, dateFormat)}`;
});

const plannedEventDates = computed(() => {
  const plannedEvent = props.plannedEventsByPlannerId[props.plannerItem._id];
  if (!plannedEvent) {
    return null;
  }
  return `${format(plannedEvent.start, dateFormat)}-${format(plannedEvent.end, dateFormat)}`;
});

const actualEvent = computed(() => props.actualEventsBySourceId[props.plannerItem.source_id]);

const getProgressTooltip = (
  plannerItem: PlannerItemWithChildren,
  plannerItemProgress: PlannerItemProgress,
  options?: { showPercentage?: boolean },
) => {
  const finishedWorkingDays =
    plannerItem.children && plannerItem.children.length === 0
      ? formatNumber(plannerItemProgress.actual_finished_working_days)
      : formatNumber(plannerItemProgress.finished_working_days);
  const notFinishedLabel =
    plannerItem.children &&
    plannerItem.children.length === 0 &&
    plannerItemProgress.actual_finished_working_days > plannerItemProgress.total_working_days
      ? ` (${t("analytics.reports.query_value_progress.not_finished")})`
      : "";
  const parentTooltip =
    plannerItem.children && plannerItem.children.length > 0
      ? ` (${t("analytics.reports.query_value_progress.finished_working_hours_parent_tooltip")})`
      : "";
  const percentageText = options?.showPercentage ? ` (${formatPercentage(percentage.value)})` : "";
  return `${finishedWorkingDays} / ${formatNumber(plannerItemProgress.total_working_days)} ${t(
    "analytics.planner.working_days_label",
  )}${percentageText}${notFinishedLabel}${parentTooltip}`;
};
</script>

<style scoped>
.item:hover .dates {
  display: flex;
}

.item:hover .markAsDoneButton {
  display: flex;
}
</style>
