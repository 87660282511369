<template>
  <ModalTW
    :open="true"
    @close="emit('close')"
    customCls="m-4 w-full min-w-[250px] sm:w-2/3 xl:w-1/3"
  >
    <template #content>
      <div>
        <div
          v-if="arePublicHolidaysLoading"
          class="flex items-center justify-center"
          style="height: 300px"
        >
          <LoadingSpinner />
        </div>
        <div
          v-else-if="publicHolidaysResponse && publicHolidaysResponse?.holidays.length > 0"
          class="flex items-center gap-6 flex-col text-left m-3 sm:m-0"
        >
          <div class="flex justify-between w-full items-center mt-2 gap-2">
            <div>
              {{ t("admin.durations_project_settings.public_holidays_for") }}
              <b>{{ project.zip_code }} {{ publicHolidaysResponse?.locality_name }}</b> ({{
                [publicHolidaysResponse?.state, project.country_code]
                  .filter((item) => item)
                  .join(", ")
              }})
            </div>
            <Listbox as="div" v-model="selectedYear">
              <div class="relative">
                <ListboxButton
                  class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-600 sm:text-sm sm:leading-6"
                >
                  <span class="block truncate">{{ selectedYear }}</span>
                  <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" />
                  </span>
                </ListboxButton>
                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      as="template"
                      v-for="year in years"
                      :key="year"
                      :value="year"
                      v-slot="{ active, selected }"
                    >
                      <li
                        :class="[
                          active ? 'bg-yellow-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                        ]"
                      >
                        <span
                          :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']"
                          >{{ year }}</span
                        >

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-yellow-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
          <div
            style="max-height: calc(100vh - 300px)"
            class="overflow-y-auto border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="table-fixed w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    class="sm:px-6 pl-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {{ t("admin.durations_project_settings.holidays_field_name") }}
                  </th>
                  <th
                    class="sm:px-6 px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    style="width: 130px"
                  >
                    {{ t("admin.durations_project_settings.holidays_field_date") }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="holiday in publicHolidaysResponse?.holidays"
                  :key="holiday.name"
                  class="text-gray-800"
                >
                  <td class="sm:px-6 pl-2 py-3 sm:text-sm text-xs break-words">
                    {{ holiday.name }}
                  </td>
                  <td class="sm:px-6 px-2 py-3 sm:text-sm text-xs" style="width: 130px">
                    {{ format(holiday.start_date, "dd.MM.yyyy") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            @click="handleApplyClick"
            class="flex gap-2 items-center justify-center focus:outline-none w-full rounded-md bg-yellow-500 px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 whitespace-nowrap"
          >
            {{ t("buttons.apply") }}
          </button>
        </div>
        <div v-else style="height: 300px" class="flex items-center justify-center text-gray-600">
          {{ t("admin.durations_project_settings.no_public_holiday_message") }}&nbsp;
          <b>{{ project.zip_code }}</b
          >&nbsp;({{ project.country_code }})
        </div>
      </div>
    </template>
  </ModalTW>
</template>

<script setup lang="ts">
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { format, getYear } from "date-fns";
import { usePublicHolidays } from "oai-services";
import { computed, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import { apiClient } from "@/repositories/clients";
import { Project } from "@/types/Project";

const { t } = useI18n();

const props = defineProps({
  project: {
    type: Object as PropType<Project>,
    required: true,
  },
});

const years = computed(() => {
  const startYear = getYear(props.project.planned_start) - 1;
  const endYear = Math.max(getYear(props.project.planned_end || new Date()) + 1, startYear);
  const interval = endYear - startYear + 1;
  return [...Array(interval).keys()].map((key) => (key + startYear).toString());
});

const currentYear = getYear(new Date());

const selectedYear = ref(
  years.value.includes(currentYear.toString())
    ? currentYear.toString()
    : years.value[years.value.length - 1],
);

const emit = defineEmits(["commit", "close"]);

const { publicHolidaysResponse, arePublicHolidaysLoading } = usePublicHolidays(
  props.project.country_code,
  props.project.zip_code,
  computed(() => parseInt(selectedYear.value)),
  apiClient,
);

const handleApplyClick = () => {
  emit("commit", publicHolidaysResponse.value?.holidays);
};
</script>
