<template>
  <SlideOver :open="open" @closed="$emit('closed')" maxWidth="1000px">
    <template #title> {{ resourceFullName }} </template>
    <template #content>
      <ProcessSideBarContent
        :processes="processes"
        :open="open"
        @processUpdate="$emit('processUpdate', $event)"
      />
    </template>
  </SlideOver>
</template>

<script lang="ts">
import { SimplifiedPlannerProcess } from "oai-services";
import { defineComponent, PropType } from "vue";
import SlideOver from "@/views/planner/components/SlideOver.vue";
import ProcessSideBarContent from "./ProcessSideBarContent.vue";

export default defineComponent({
  name: "ProcessSideBar",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    processes: {
      type: Array as PropType<Array<SimplifiedPlannerProcess & { name?: string }>>,
      required: true,
    },
    resourceFullName: {
      type: String,
      required: true,
    },
  },
  emits: ["closed", "processUpdate"],
  components: {
    SlideOver,
    ProcessSideBarContent,
  },
});
</script>
