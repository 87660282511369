<template>
  <div class="aspect-h-3 pt-4 w-full">
    <div class="container" :class="['container' + camera]">
      <div class="image-container">
        <img
          alt="Before image"
          class="image-before slider-image"
          :src="beforeImg.url ? beforeImg.url : default_img"
        />

        <img
          alt="After image"
          class="image-after slider-image"
          :src="afterImg.url ? afterImg.url : default_img"
        />
      </div>
      <input
        type="range"
        min="0"
        max="100"
        value="50"
        aria-label="Percentage of before photo shown"
        class="slider"
        :class="['slider' + camera]"
      />
      <div class="slider-line" aria-hidden="true"></div>
      <div class="slider-button" aria-hidden="true">
        <LoadingSpinner v-if="loadingImage" size=" h-6 w-6" />
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none"></rect>
          <line
            x1="128"
            y1="40"
            x2="128"
            y2="216"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <line
            x1="96"
            y1="128"
            x2="16"
            y2="128"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <polyline
            points="48 160 16 128 48 96"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></polyline>
          <line
            x1="160"
            y1="128"
            x2="240"
            y2="128"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <polyline
            points="208 96 240 128 208 160"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></polyline>
        </svg>
      </div>
    </div>
    <div class="flex justify-between pt-2 sm:text-sm text-xs">
      <div class="justify-self-center">
        <div class="flex justify-center items-center pt-2">
          <button
            :disabled="!beforeImg.previous"
            type="button"
            :class="['nav-arrows', !beforeImg.previous ? 'opacity-30' : '']"
            @click="prevBeforeImg()"
          >
            <ChevronLeftIcon
              :class="['h-3.5 w-3.5 text-gray-500', { 'text-yellow-500': beforeDateError }]"
              aria-hidden="true"
            />
          </button>
          <input
            type="date"
            :max="maxDate"
            class="p-0.5 border-transparent focus:border-yellow focus:shadow-none focus:ring-0 text-xs sm:text-sm bg-transparent"
            v-model="beforeTime"
            @change="changeBeforeTime(($event.target as HTMLInputElement).value)"
          />
          <button
            :disabled="!beforeImg.next"
            type="button"
            :class="['nav-arrows', !beforeImg.next ? 'opacity-30' : '']"
            @click="nextBeforeImg()"
          >
            <ChevronRightIcon
              :class="[{ 'text-yellow-500': beforeDateError }, 'h-3.5 w-3.5 text-gray-500']"
              aria-hidden="true"
            />
          </button>
        </div>
        <small class="text-red text-center" v-if="beforeDateError">{{
          $t("camera.compare.no_image")
        }}</small>
      </div>
      <div class="justify-self-center">
        <div class="flex justify-center items-center pt-2">
          <button
            :disabled="!afterImg.previous"
            @click="prevAfterImg()"
            type="button"
            :class="['nav-arrows', !afterImg.previous ? 'opacity-30' : '']"
          >
            <ChevronLeftIcon class="h-3.5 w-3.5 text-gray-500" aria-hidden="true" />
          </button>
          <input
            type="date"
            :max="maxDate"
            class="p-0.5 border-transparent focus:border-yellow focus:shadow-none focus:ring-0 sm:text-sm text-xs bg-transparent"
            v-model="afterTime"
            @change="changeAfterTime(($event.target as HTMLInputElement).value)"
          />
          <button
            :disabled="!afterImg.next"
            @click="nextAfterImg()"
            type="button"
            :class="['nav-arrows', !afterImg.next ? 'opacity-30' : '']"
          >
            <ChevronRightIcon class="h-3.5 w-3.5 text-gray-500" aria-hidden="true" />
          </button>
        </div>
        <small class="text-red text-center" v-if="afterDateError">{{
          $t("camera.compare.no_image")
        }}</small>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/solid";
import axios, { CanceledError } from "axios";
import { format, parseISO, subDays } from "date-fns";
import { defineComponent, PropType } from "vue";
import defaultProjectThumbnailUrl from "@/assets/imgs/default-project-thumbnail.jpg";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import CameraRepository from "@/repositories/CameraRepository";
import logger from "@/services/logger";
import { GalleryPaginatedImg } from "@/types/Camera";

export default defineComponent({
  name: "ImagesComparison",
  props: {
    camera: {
      type: String as PropType<string>,
      required: true,
    },
    initialBeforeDate: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    initialAfterDate: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    hideNoImageToast: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  components: { LoadingSpinner, ChevronLeftIcon, ChevronRightIcon },
  data() {
    return {
      showOverlay: true as boolean,
      default_img: defaultProjectThumbnailUrl,
      beforeImg: {} as Record<string, string | null | Date> as GalleryPaginatedImg,
      afterImg: {} as Record<string, string | null | Date> as GalleryPaginatedImg,
      beforeTime: this.initialBeforeDate as string,
      afterTime: this.initialAfterDate as string,
      loadingImage: false as boolean,
      beforeDateError: false as boolean,
      afterDateError: false as boolean,
      isFirstLoad: true as boolean,
      noData: false as boolean,
      beforeDateAbortController: null as AbortController | null,
      afterDateAbortController: null as AbortController | null,
    };
  },
  mounted() {
    this.initImg();
  },
  unmounted() {
    if (this.beforeDateAbortController) {
      this.beforeDateAbortController.abort();
    }
    if (this.afterDateAbortController) {
      this.afterDateAbortController.abort();
    }
  },
  emits: ["beforeChanged", "afterChanged", "noData"],
  watch: {
    beforeImg() {
      this.$emit("beforeChanged", this.beforeTime);
    },
    afterImg() {
      this.$emit("afterChanged", this.afterTime);
    },
    initialBeforeDate: {
      handler: function (value: string) {
        this.beforeTime = value;
        this.getCameraImages("after_sunrise", value);
      },
      immediate: true,
    },
    initialAfterDate: {
      handler: function (value: string) {
        this.afterTime = value;
        this.getCameraImages("before_sunset", value);
      },
      immediate: true,
    },
  },
  computed: {
    maxDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
  },
  methods: {
    format,
    async getCameraImages(timeFilter: string, date: string) {
      const { customer_name, site_id } = this.$route.params;
      this.loadingImage = true;
      let signal: AbortSignal;
      if (timeFilter === "after_sunrise") {
        if (this.beforeDateAbortController) {
          this.beforeDateAbortController.abort();
        }
        this.beforeDateAbortController = new AbortController();
        signal = this.beforeDateAbortController.signal;
      } else {
        if (this.afterDateAbortController) {
          this.afterDateAbortController.abort();
        }
        this.afterDateAbortController = new AbortController();
        signal = this.afterDateAbortController.signal;
      }

      try {
        const img: GalleryPaginatedImg = await CameraRepository.loadGalleryPaginatedImg(
          customer_name as string,
          site_id as string,
          this.camera as string,
          date as string,
          timeFilter as string,
          { signal: signal },
        );

        if (timeFilter === "after_sunrise") {
          if (!img.url) {
            this.beforeDateError = true;
            if (!this.isFirstLoad && !this.hideNoImageToast) {
              this.showToast(
                "error",
                this.$t("camera.compare.no_image") + " " + format(parseISO(date), "dd.MM.yyyy"),
              );
            }
          }
          this.beforeImg = img ? img : this.default_img;
        } else {
          if (!img.url) {
            this.afterDateError = true;
            if (!this.isFirstLoad && !this.hideNoImageToast) {
              this.showToast(
                "error",
                this.$t("camera.compare.no_image") + " " + format(parseISO(date), "dd.MM.yyyy"),
              );
            }
          }
          if (img) {
            this.afterImg = img;
            if (!this.initialAfterDate && !this.afterTime) {
              this.afterTime = format(parseISO(this.afterImg.timestamp), "yyyy-MM-dd"); // Use "MM" for months and "dd" for days
              const date = parseISO(this.afterImg.timestamp);
              const newDate = subDays(date, 7);
              this.beforeTime = format(newDate, "yyyy-MM-dd"); // Use "MM" for months and "dd" for days
              await this.getCameraImages("after_sunrise", this.beforeTime);
            }
          } else {
            this.afterImg = this.default_img;
          }
        }
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        if (!axios.isAxiosError(error) || error.response?.status !== 404) {
          logger.error(error);
        }

        if (axios.isAxiosError(error)) {
          if (error?.response?.data?.code === "NO_DATA_YET") {
            this.$emit("noData");
          } else if (!this.hideNoImageToast) {
            this.showToast("warning", this.$t("err.no_data_found"));
          }
        }
        this.loadingImage = false;
      } finally {
        this.loadingImage = false;
        this.isFirstLoad = false;
      }
    },
    initImg() {
      const container = document.querySelector(`.container${this.camera}`) as HTMLElement | null;
      const slider = document.querySelector(`.slider${this.camera}`) as HTMLInputElement | null;

      if (container && slider) {
        slider.addEventListener(
          "input",
          (e) => {
            const target = e.target as HTMLInputElement;
            container.style.setProperty("--position", `${target.value}%`);
            this.showOverlay = false;
          },
          { passive: true },
        ); // Mark the event listener as passive
      }
    },
    prevBeforeImg() {
      this.beforeDateError = false;
      if (this.beforeImg.previous) {
        try {
          this.getCameraImages("after_sunrise", this.beforeImg.previous);
        } finally {
          this.beforeTime = this.beforeImg.previous;
        }
      }
    },
    nextBeforeImg() {
      this.beforeDateError = false;
      if (this.beforeImg.next) {
        try {
          this.getCameraImages("after_sunrise", this.beforeImg.next);
        } finally {
          this.beforeTime = this.beforeImg.next;
        }
      }
    },
    prevAfterImg() {
      this.afterDateError = false;
      if (this.afterImg.previous) {
        try {
          this.getCameraImages("before_sunset", this.afterImg.previous);
        } finally {
          this.afterTime = this.afterImg.previous;
        }
      }
    },
    nextAfterImg() {
      this.afterDateError = false;
      if (this.afterImg.next) {
        try {
          this.getCameraImages("before_sunset", this.afterImg.next);
        } finally {
          this.afterTime = this.afterImg.next;
        }
      }
    },
    changeBeforeTime(value: string) {
      this.beforeDateError = false;
      this.getCameraImages("after_sunrise", value);
    },
    changeAfterTime(value: string) {
      this.afterDateError = false;
      this.getCameraImages("before_sunset", value);
    },
  },
});
</script>
<style scoped>
.slider-image img {
  display: block;
  max-width: 100%;
  aspect-ratio: 4/3;
}

.container {
  display: grid;
  place-content: center;
  position: relative;
  overflow: hidden;
  --position: 50%;
}

.image-container {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 48rem) {
  .slider-image img {
    aspect-ratio: 4/3;
  }
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  background-color: grey;
}

.image-before {
  position: absolute;
  inset: 0;
  width: var(--position);
  /*filter: grayscale(100%)*/
}

.slider {
  position: absolute;
  inset: 0;
  cursor: pointer;
  opacity: 0;
  /* for Firefox */
  width: 100%;
  height: 100%;
}

.slider:focus-visible ~ .slider-button {
  outline: 5px solid black;
  outline-offset: 3px;
}

.slider-line {
  position: absolute;
  inset: 0;
  width: 0.2rem;
  height: 100%;
  background-color: #fff;
  left: var(--position);
  transform: translateX(-50%);
  pointer-events: none;
}

.slider-button {
  position: absolute;
  background-color: #fff;
  color: black;
  padding: 0.5rem;
  border-radius: 100vw;
  display: grid;
  place-items: center;
  top: 50%;
  left: var(--position);
  transform: translate(-50%, -50%);
  pointer-events: none;
  box-shadow: 1px 1px 1px hsl(0, 50%, 2%, 0.5);
}

.nav-arrows {
  @apply inline-flex items-center border-transparent px-0.5 py-2 text-sm font-medium sm:px-1;
}
</style>
