import { AxiosInstance, AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import {
  ActualEventChange,
  ActualEventChanges,
  PlanConfig,
  PlanConfigWithoutIndex,
  PlanWithPlannerItemCount,
  PlanExportItem,
  MergedPlannerItem,
  SimplifiedPlannerProcess,
  SimplifiedPlannerProcessBreak,
  EndTrackingResponse,
  ReinitializeActualEventsResponseItem,
} from "../types/Plan";

export default (client: AxiosInstance) => {
  const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

  const formatUtcDate = (date: Date): string =>
    fromZonedTime(date, "UTC").toISOString().replace("Z", "+00:00");

  const mapPlanConfig = (planConfig: PlanConfigWithoutIndex<string>): PlanConfig => ({
    ...planConfig,
    planner_items: planConfig.planner_items.map((planner_item, index) => ({
      ...planner_item,
      index: index + 1,
    })),
    planned_events: planConfig.planned_events.map((plannedEvent) => ({
      ...plannedEvent,
      start: parseUtcDate(plannedEvent.start),
      end: parseUtcDate(plannedEvent.end),
    })),
    actual_events: planConfig.actual_events.map((actualEvent) => ({
      ...actualEvent,
      start: parseUtcDate(actualEvent.start),
      end: actualEvent.end ? parseUtcDate(actualEvent.end) : null,
    })),
  });

  const formatPlanConfigDates = (
    planConfig: Omit<PlanConfig, "planner_comments">,
  ): Omit<PlanConfig<string>, "planner_comments"> => ({
    ...planConfig,
    planned_events: planConfig.planned_events.map((plannedEvent) => ({
      ...plannedEvent,
      start: formatUtcDate(plannedEvent.start),
      end: formatUtcDate(plannedEvent.end),
    })),
    actual_events: planConfig.actual_events.map((actualEvent) => ({
      ...actualEvent,
      start: formatUtcDate(actualEvent.start),
      end: actualEvent.end ? formatUtcDate(actualEvent.end) : null,
    })),
  });

  type SaveActualEventChangesResponse = {
    added_ids: { _id: string; db_id: string }[];
  };

  const formatActualEventChangesDates = (
    changes: ActualEventChanges,
  ): ActualEventChanges<string> => ({
    ...changes,
    added: changes.added.map((change) => ({
      ...change,
      start: formatUtcDate(change.start),
      end: change.end ? formatUtcDate(change.end) : null,
    })),
    modified: changes.modified.map((change) => ({
      ...change,
      start: formatUtcDate(change.start),
      end: change.end ? formatUtcDate(change.end) : null,
    })),
  });

  const loadPlanConfig = (customerName: string, siteId: string): Promise<PlanConfig> => {
    return client
      .get<void, AxiosResponse<PlanConfigWithoutIndex<string>>>(
        `/planner-v2/plans/${customerName}/${siteId}`,
      )
      .then((response) => mapPlanConfig(response.data));
  };

  const parseXML = (
    customerName: string,
    siteId: string,
    file: File,
    slackNotification?: string,
  ): Promise<PlanConfig> => {
    const formData = new FormData();
    formData.append("file", file);

    const searchParams = new URLSearchParams();
    if (slackNotification) {
      searchParams.set("slackNotification", slackNotification);
    }

    return client
      .post<void, AxiosResponse<PlanConfigWithoutIndex<string>>>(
        `/planner-v2/plans/${customerName}/${siteId}/parse?${searchParams.toString()}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then((response) => mapPlanConfig(response.data));
  };
  const saveActualEventChanges = (
    customerName: string,
    siteId: string,
    changes: ActualEventChanges,
  ): Promise<SaveActualEventChangesResponse> =>
    client
      .post<ActualEventChanges, AxiosResponse<SaveActualEventChangesResponse>>(
        `/planner-v2/actual-events/${customerName}/${siteId}`,
        formatActualEventChangesDates(changes),
      )
      .then((response) => response.data);

  const loadActualEventsChanges = (
    customerName: string,
    siteId: string,
  ): Promise<ActualEventChange[]> =>
    client
      .get<void, AxiosResponse<ActualEventChange[]>>(
        `/planner-v2/actual-events/${customerName}/${siteId}/changes`,
      )
      .then((response) => response.data);

  const updateActualEventsToEndTracking = (
    customerName: string,
    siteId: string,
  ): Promise<{ status: string }> =>
    client
      .post<EndTrackingResponse, AxiosResponse<{ status: string }>>(
        `/planner-v2/actual-events/end-tracking/${customerName}/${siteId}`,
      )
      .then((response) => response.data);

  const createNewRevision = (
    customerName: string,
    siteId: string,
    planConfig: Omit<PlanConfig, "planner_comments">,
    mergedPlannerItems: MergedPlannerItem[],
  ): Promise<PlanConfig> =>
    client
      .post<PlanConfigWithoutIndex<string>, AxiosResponse<PlanConfigWithoutIndex<string>>>(
        `/planner-v2/plan-revisions/${customerName}/${siteId}`,
        {
          plan_config: formatPlanConfigDates(planConfig),
          merged_planner_items: mergedPlannerItems,
        },
      )
      .then((response) => mapPlanConfig(response.data));

  const loadPlanRevisions = (
    customerName: string,
    siteId: string,
  ): Promise<PlanWithPlannerItemCount[]> =>
    client
      .get<void, AxiosResponse<PlanWithPlannerItemCount[]>>(
        `/planner-v2/plan-revisions/${customerName}/${siteId}`,
      )
      .then((response) => response.data);

  const deletePlanRevision = (
    customerName: string,
    siteId: string,
    planId: string,
  ): Promise<void> =>
    client
      .delete<void, AxiosResponse<void>>(
        `/planner-v2/plan-revisions/${customerName}/${siteId}/${planId}`,
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unsuccessful status code");
        }
      });

  const activatePlan = (
    customerName: string,
    siteId: string,
    planId: string,
  ): Promise<PlanConfig> =>
    client
      .post<void, AxiosResponse<PlanConfigWithoutIndex<string>>>(
        `/planner-v2/plan-revisions/${customerName}/${siteId}/${planId}/activate`,
      )
      .then((response) => mapPlanConfig(response.data));

  const loadPlanExportData = (customerName: string, siteId: string): Promise<PlanExportItem[]> =>
    client
      .get<void, AxiosResponse<PlanExportItem<string>[]>>(
        `/planner-v2/plans/${customerName}/${siteId}/export-data`,
      )
      .then((response) =>
        response.data.map((obj) => ({
          ...obj,
          planned_start: obj.planned_start ? parseUtcDate(obj.planned_start) : null,
          planned_end: obj.planned_end ? parseUtcDate(obj.planned_end) : null,
          actual_start: obj.actual_start ? parseUtcDate(obj.actual_start) : null,
          actual_end: obj.actual_end ? parseUtcDate(obj.actual_end) : null,
        })),
      );

  const calculateProcessBreaks = <TDate>(
    process: Pick<SimplifiedPlannerProcess<TDate>, "work_intervals">,
  ) =>
    process.work_intervals.reduce(
      (acc, work_interval) => {
        if (acc.last_start_time === null) {
          return {
            ...acc,
            last_start_time: work_interval.end_time,
          };
        }
        return {
          breaks: [
            ...acc.breaks,
            { start_time: acc.last_start_time, end_time: work_interval.start_time },
          ],
          last_start_time: work_interval.end_time,
        };
      },
      { breaks: [], last_start_time: null } as {
        breaks: SimplifiedPlannerProcessBreak<TDate>[];
        last_start_time: TDate | null;
      },
    ).breaks;

  const loadProcesses = (
    customerName: string,
    siteId: string,
  ): Promise<SimplifiedPlannerProcess[]> =>
    client
      .get<void, AxiosResponse<{ processes: SimplifiedPlannerProcess<string>[] }>>(
        `/planner-v2/processes/${customerName}/${siteId}`,
      )
      .then((response) =>
        response.data.processes.map((process) => {
          const mappedProcess = {
            ...process,
            start_time: parseUtcDate(process.start_time),
            end_time: parseUtcDate(process.end_time),
            work_intervals: process.work_intervals.map((workInterval) => ({
              ...workInterval,
              start_time: parseUtcDate(workInterval.start_time),
              end_time: parseUtcDate(workInterval.end_time),
            })),
          };
          return {
            ...mappedProcess,
            breaks: calculateProcessBreaks(mappedProcess),
          };
        }),
      );

  const loadResourceCameraIds = (
    customerName: string,
    siteId: string,
    sourceId: string,
  ): Promise<string[]> =>
    client
      .get<void, AxiosResponse<string[]>>(
        `/planner-v2/section-masks/camera-ids/${customerName}/${siteId}/${encodeURIComponent(
          sourceId,
        )}`,
      )
      .then((response) => response.data);

  const loadReinitializeActualEventChanges = (
    customerName: string,
    siteId: string,
  ): Promise<ReinitializeActualEventsResponseItem> =>
    client
      .get<void, AxiosResponse<ReinitializeActualEventsResponseItem>>(
        `/planner-v2/actual-events/reinitialize-changes/${customerName}/${siteId}`,
      )
      .then((response) => response.data);

  return {
    mapPlanConfig,
    loadPlanConfig,
    parseXML,
    saveActualEventChanges,
    updateActualEventsToEndTracking,
    createNewRevision,
    loadActualEventsChanges,
    loadPlanRevisions,
    deletePlanRevision,
    activatePlan,
    loadPlanExportData,
    loadProcesses,
    calculateProcessBreaks,
    loadResourceCameraIds,
    loadReinitializeActualEventChanges,
  };
};
