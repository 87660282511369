<template>
  <div
    class="bg-white shadow rounded-md h-full overflow-auto"
    :class="{ oaiBaseWidgetUseWhiteIcons: useWhiteIcons }"
    @mouseenter="handleMouseEnter"
    @mousemove="handleMouseMove"
    @mouseleave="handleMouseLeave"
    @click="handleClick"
  >
    <div v-if="isLoading" class="p-4 h-full flex flex-col gap-2">
      <div class="loadingEl h-4 rounded-md bg-gray-50" :style="{ width: '40%' }" />
      <div class="loadingEl flex-1 rounded-md bg-gray-50" />
    </div>
    <div
      class="absolute top-3 right-2 z-50 vgl-item__menu items-center"
      :class="menuClass"
      v-if="!isLoading"
    >
      <ArrowsPointingOutIcon
        class="w-4 h-4 z-[9999] cursor-pointer"
        :class="{ 'text-white': useWhiteIcons }"
        @click="
          openZoomModal = true;
          emit('trackZoomClicked');
        "
        v-if="showZoomIcon"
      />
      <Menu as="div" class="relative">
        <MenuButton class="flex items-center text-gray-600">
          <EllipsisVerticalIcon class="w-6 h-6" :class="{ 'text-white': useWhiteIcons }" />
        </MenuButton>
        <MenuItems
          class="absolute -right-3 z-50 mt-2 origin-top-right rounded-md overflow-hidden bg-white shadow focus:outline-none"
          :style="{ width: '200px' }"
        >
          <slot name="menuItems" />
          <MenuItem v-slot="{ active }" @click="emit('delete')">
            <div
              :class="[
                active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
                'px-4 py-2 text-sm cursor-pointer truncate flex items-center gap-2',
              ]"
            >
              <XMarkIcon class="w-5 h-5" />
              {{ $t("dashboard.remove_widget") }}
            </div>
          </MenuItem>
        </MenuItems>
      </Menu>
    </div>
    <DashboardDragHandle
      v-if="!hideDragHandle && !isLoading"
      class="absolute left-4 z-50 top-[17px]"
      :iconClass="useWhiteIcons ? 'fill-white' : ''"
    />
    <slot v-if="!isLoading" />
    <slot name="modals" />
    <ModalTW
      :open="openZoomModal"
      @close="
        openZoomModal = false;
        zoomContainer = null;
        zoomedViewHeight = 0;
      "
      custom-cls="w-full mx-auto overflow-hidden h-full"
    >
      <template #content>
        <div class="h-full overflow-auto" ref="zoomContainer">
          <slot name="zoomedView" :height="zoomedViewHeight" v-if="zoomedViewHeight > 0" />
        </div>
      </template>
    </ModalTW>
  </div>
</template>
<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ArrowsPointingOutIcon, EllipsisVerticalIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { differenceInSeconds } from "date-fns";
import { ref, watch } from "vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import DashboardDragHandle from "@/views/dashboard/componentsV2/DashboardDragHandle.vue";

defineProps<{
  isLoading?: boolean;
  hideDragHandle?: boolean;
  menuClass?: string;
  showZoomIcon?: boolean;
  useWhiteIcons?: boolean;
}>();
const emit = defineEmits(["delete", "trackInteraction", "trackZoomClicked"]);

const openZoomModal = ref(false);

const zoomContainer = ref<HTMLDivElement | null>(null);
const zoomedViewHeight = ref<number>(0);

watch(zoomContainer, () => {
  if (zoomContainer.value) {
    zoomedViewHeight.value = zoomContainer.value.getBoundingClientRect().height;
  }
});

const totalDistance = ref(0);
const lastSeenAt = ref({ x: 0, y: 0 });
const clickCount = ref(0);
const startTime = ref();

const handleMouseEnter = () => {
  totalDistance.value = 0;
  lastSeenAt.value = { x: 0, y: 0 };
  clickCount.value = 0;
  startTime.value = new Date();
};

const handleClick = () => {
  clickCount.value += 1;
};

const handleMouseMove = (event: MouseEvent) => {
  if (lastSeenAt.value.x && lastSeenAt.value.y) {
    totalDistance.value += Math.sqrt(
      Math.pow(lastSeenAt.value.y - event.clientY, 2) +
        Math.pow(lastSeenAt.value.x - event.clientX, 2),
    );
  }
  lastSeenAt.value.x = event.clientX;
  lastSeenAt.value.y = event.clientY;
};

const handleMouseLeave = () => {
  const endTime = new Date();
  const diff = differenceInSeconds(endTime, startTime.value);
  const result = clickCount.value * 200 + Math.round(diff) * 20 + Math.round(totalDistance.value);
  if (result > 1000) {
    emit("trackInteraction");
  }
  totalDistance.value = 0;
  lastSeenAt.value = { x: 0, y: 0 };
  clickCount.value = 0;
  startTime.value = "";
};
</script>

<style>
.vgl-item:has(.oaiBaseWidgetUseWhiteIcons) .vgl-item__resizer {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="rgb(255, 255, 255)" stroke-width="2" viewBox="0 0 16 16"><path d="m9.5 14.5 5-5M4 14.5 14.5 4"></path></svg>');
}
</style>
