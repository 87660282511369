import { parseISO } from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import PlannerRepository from "@/repositories/PlannerRepository";
import { ShortenedProcess } from "@/types/Process";
import {
  Report,
  ReportFilters,
  ReportPlotType,
  ReportSummary,
  ReportSummaryContext,
  ReportToCreateOrUpdate,
} from "@/types/Report";
import { CycleTimesReportSummary } from "@/types/reports/PlotCycleTimes";
import {
  isQueryValueReportSummaryPlanAndProcessesValue,
  isQueryValueReportSummaryProcessesValue,
  isQueryValueReportSummarySingleValue,
  QueryValueReportSummary,
} from "@/types/reports/PlotQueryValue";
import { apiClient } from "./clients";

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

const formatUtcDate = (date: Date): string =>
  fromZonedTime(date, "UTC").toISOString().replace("Z", "+00:00");

const formatFilters = (filters: ReportFilters): ReportFilters<string> => {
  if ("daterange" in filters) {
    return {
      ...filters,
      daterange: {
        ...filters.daterange,
        start_date: filters.daterange.start_date && formatUtcDate(filters.daterange.start_date),
        end_date: filters.daterange.end_date && formatUtcDate(filters.daterange.end_date),
      },
    };
  }
  return filters;
};

const mapFilters = (filters: ReportFilters<string>): ReportFilters => {
  if ("daterange" in filters) {
    return {
      ...filters,
      daterange: {
        ...filters.daterange,
        start_date: filters.daterange.start_date
          ? parseUtcDate(filters.daterange.start_date)
          : null,
        end_date: filters.daterange.end_date ? parseUtcDate(filters.daterange.end_date) : null,
      },
    };
  }
  return filters;
};

const mapReport = (report: Report<string>): Report => ({
  ...report,
  filters: mapFilters(report.filters),
});

const formatReport = (report: ReportToCreateOrUpdate): ReportToCreateOrUpdate<string> => ({
  ...report,
  filters: formatFilters(report.filters),
});

const loadReports = (customerName: string, siteId: string): Promise<Report[]> =>
  apiClient
    .get<Report<string>[]>(`/report/${customerName}/${siteId}`)
    .then((response) => response.data.map((report) => mapReport(report)));

const createReport = async (
  customerName: string,
  siteId: string,
  payload: ReportToCreateOrUpdate,
) => {
  const { _id, type, ...rest } = formatReport(payload);
  return apiClient
    .post<Report<string>>(`/report/${customerName}/${siteId}/${type}`, rest)
    .then((response) => mapReport(response.data));
};

const updateReport = async (
  customerName: string,
  siteId: string,
  payload: ReportToCreateOrUpdate,
) => {
  const { _id, type, ...rest } = formatReport(payload);
  return apiClient
    .put<Report>(`/report/${customerName}/${siteId}/${type}/${_id}`, rest)
    .then((response) => mapReport(response.data));
};

const deleteReport = (customerName: string, siteId: string, id: string) =>
  apiClient
    .delete<void>(`/report/${customerName}/${siteId}/${id}`)
    .then((response) => response.data);

const mapShortenedProcess = (process: ShortenedProcess<string>): ShortenedProcess => ({
  ...process,
  start_time: parseUtcDate(process.start_time),
  end_time: parseUtcDate(process.end_time),
  work_intervals: process.work_intervals.map((workInterval) => ({
    ...workInterval,
    start_time: parseUtcDate(workInterval.start_time),
    end_time: parseUtcDate(workInterval.end_time),
  })),
});

const mapReportSummaryContext = (context: ReportSummaryContext<string>): ReportSummaryContext => ({
  ...context,
  start_date: context.start_date ? parseUtcDate(context.start_date) : null,
  end_date: context.end_date ? parseUtcDate(context.end_date) : null,
  previous_period_start_date: context.previous_period_start_date
    ? parseUtcDate(context.previous_period_start_date)
    : null,
  previous_period_end_date: context.previous_period_end_date
    ? parseUtcDate(context.previous_period_end_date)
    : null,
});

const mapReportSummary = (
  plotType: ReportPlotType,
  reportSummary: ReportSummary<string>,
): ReportSummary => {
  if (plotType === "cycle_times") {
    const data = reportSummary as CycleTimesReportSummary<string>;
    return {
      ...data,
      process_groups: data.process_groups.map((item) => ({
        ...item,
        processes: item.processes.map((process) => ({
          ...process,
          start_time: parseUtcDate(process.start_time),
          end_time: parseUtcDate(process.end_time),
          work_intervals: process.work_intervals.map((workInterval) => ({
            ...workInterval,
            start_time: parseUtcDate(workInterval.start_time),
            end_time: parseUtcDate(workInterval.end_time),
          })),
        })),
        planned_event: {
          ...item.planned_event,
          start: parseUtcDate(item.planned_event.start),
          end: parseUtcDate(item.planned_event.end),
        },
      })),
      context: mapReportSummaryContext(data.context),
    };
  }
  if (plotType === "query_value") {
    const data = reportSummary as QueryValueReportSummary<string>;
    if (isQueryValueReportSummaryProcessesValue(data)) {
      return {
        ...data,
        context: mapReportSummaryContext(data.context),
        processes: data.processes.map((process) => mapShortenedProcess(process)),
        previous_period_processes: data.previous_period_processes.map((process) =>
          mapShortenedProcess(process),
        ),
      };
    }
    if (isQueryValueReportSummarySingleValue(data)) {
      return {
        ...data,
        context: mapReportSummaryContext(data.context),
      };
    }
    if (isQueryValueReportSummaryPlanAndProcessesValue(data)) {
      return {
        ...data,
        plan: PlannerRepository.mapPlanConfig(data.plan),
        processes: data.processes.map((process) => mapShortenedProcess(process)),
        context: mapReportSummaryContext(data.context),
      };
    }
    return data as QueryValueReportSummary;
  }
  return {
    ...reportSummary,
    context: mapReportSummaryContext(reportSummary.context),
  } as ReportSummary;
};

const loadReportSummary = (
  customerName: string,
  siteId: string,
  report: Report,
  { fresh }: { fresh?: boolean } = {},
): Promise<ReportSummary> =>
  apiClient
    .post<ReportSummary<string>>(
      `/report/${customerName}/${siteId}/${report.type}/report-summary${fresh ? "/fresh" : ""}`,
      {
        name: report.name,
        filters: formatFilters(report.filters),
        config: report.config,
        aggregation: report.aggregation,
      },
    )
    .then((response) => mapReportSummary(report.type, response.data));

export default {
  loadReports,
  createReport,
  updateReport,
  deleteReport,
  loadReportSummary,
};
