import segmentPlugin from "@analytics/segment";
import Analytics from "analytics";
import { useCurrentSiteId, useCurrentCustomerName } from "oai-services";
import { useStore } from "vuex";

export const analytics = Analytics({
  app: "oai-app",
  plugins: [
    segmentPlugin({
      writeKey: "oztwLWx4eAuka846o0VkQriiFY69Zk9d",
    }),
  ],
});

export const useTrackEvent = () => {
  const store = useStore();
  const user = store?.state?.user;
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  return (event: string, props: Partial<Record<string, string>> = {}) => {
    if (process.env.VUE_APP_USE_ANALYTICS === "1") {
      return analytics.track(event, {
        email: user?.email || "unknown",
        name: user?.name || "unknown",
        site_id: currentSiteId,
        customer_name: currentCustomerName,
        ...props,
      });
    }
  };
};
