<template>
  <ModalTW :open="true" @close="emit('close')" customCls="m-4 w-full xl:w-3/4 xxl:w-1/2">
    <template #content>
      <ProjectProgressTable
        :planConfig="planConfig"
        :processes="processes"
        class="overflow-hidden h-[90vh]"
      />
    </template>
  </ModalTW>
</template>

<script lang="ts" setup>
import { PlanConfig } from "oai-services";
import ModalTW from "@/components/modals/ModalTW.vue";
import { ShortenedProcess } from "@/types/Process";
import ProjectProgressTable from "@/views/planner/components/ProjectProgressTable.vue";

defineProps<{ planConfig: PlanConfig; processes: ShortenedProcess[] }>();
const emit = defineEmits<{ (eventName: "close"): void }>();
</script>
