const translations = {
  app_features: {
    planner: "Schedule",
    processes: "Process data",
    site_activity: "Site activity",
    project_overview: "Project overview",
    project_participants: "Project participants",
    live: "Live",
    period: "Time period",
    gallery: "Gallery",
    project_timelapse: "Project timelapse",
    daily_timelapse: "Daily timelapse",
    customizable_timelapse: "Customizable timelapse",
    daily_report: "Daily report",
    compare: "Compare",
    settings: {
      user: "User overview",
      language: "Language",
    },
    languages: {
      de: "German",
      en: "English",
    },
    project_settings: "Settings",
    new_feature_badge: "New",
  },
  permissions: {
    project_admin: "Project admin",
    all_camera_features_user: "Camera features",
    planner_user: "Schedule",
    processes_user: "Process data",
    site_activity_user: "Site activity",
    daily_report_user: "Daily report",
    live_camera_feature_user: "Live image only",
  },
  partials: {
    sidebar: {
      projects: "Projects",
      show_all_projects: "Show all projects",
      log_out: "Logout",
    },
    breadcrumb: {
      projects: "Projects",
    },
  },
  dashboard: {
    home: {
      project_card: {
        period: "Period",
        address: "Address",
        status: "Status",
        status_active: "Active",
        status_completed: "Completed",
        project_link: "Go to project",
        start_date: "Start date",
      },
    },
    project: {
      planner_card: {
        current_procedures: "Current procedures",
        procedure: "Procedure",
        progress: "Progress",
        estimated_end: "Estimated end",
        no_current_procedures: "No current procedure",
        start_date: "Start date",
      },
      weather_card: {
        header: "Weather",
        current_weather: "Current weather",
        kmh: " km/h",
        no_data: "No weather data available",
      },
      stream_card: {
        detail_view: "Detail view",
        connection_lost: "Unfortunately, the connection is interrupted. Last signal: {updatedAt}.",
      },
    },
    process_widget: {
      header: "Last processes",
      no_processes: "No processes in the last 7 days",
      all_caught_up: "You have seen all the processes of the last 7 days!",
      to_overview_link: "Process overview",
      next_button: "Next",
    },
    add_widget: "Add widget",
    remove_widget: "Remove widget",
    no_widgets_to_add: "No widgets to add",
    loading_error: "Unable to load dashboard",
    reset_error: "Unable to reset dashboard",
    create_or_update_error: "Unable to update dashboard",
    categories: {
      camera: "Cameras",
      report: "Reports",
      other: "Other",
    },
    reset_confirmation: {
      header: "Confirmation",
      message: "You will lose your customizations when you reset the layout.",
      confirm: "Reset to default",
      cancel: "Cancel",
    },
    delete_confirmation: {
      header: "Confirmation",
      message: 'Dashboard "{name}" will be deleted.',
      confirm: "Delete dashboard",
      cancel: "Cancel",
    },
    default_dashboard: "Dashboard",
    menu_categories: {
      user: "For You",
      project: "For Everyone",
    },
    create_dashboard_modal: {
      title: "Your new dashboard",
      name: "Name",
      submit: "Create",
    },
    add_to_dashboard: "Add to dashboard",
    add_button: "Add",
    stream: {
      archived_stream: "completed",
    },
    drag_me: "Drag to move",
  },
  authentication: {
    validation: {
      validation_field_required: "Field required",
      validation_email_invalid: "Invalid email address",
      validation_password_not_equal: "The newly set password does not match.",
      validation_password_characters: "The new password must contain at least 10 characters.",
    },
    login: {
      email: "Email address",
      password: "Password",
      wrong_email_or_password: "Error! Mail address or password is incorrect.",
      sign_in: "Sign In",
      forgot_password: "Forgot password?",
      imprint: "Imprint",
      data_protection: "Data Protection",
      or: "or",
      social_error: "Unable to sign in. You must be invited to use oculai.",
      sign_in_with_microsoft: "Sign in with Microsoft",
    },
    forgot_password: {
      forgot_password: "Forgot password?",
      forgot_text: "Don't worry, we'll send you instructions on how to reset your password.",
      email: "Mail address",
      reset_password: "Reset password",
      back_to_login: "Back to login",
      user_not_found: "Error! User was not found",
      success_message: "Please check your email",
    },
    reset_password: {
      new_password: "New password",
      change_password: "Change password",
      back_to_login: "Back to login",
      error_invalid_code: "Error! Invalid verification code.",
      error_password_length: "Error! The new password must contain at least 10 characters.",
      error_user_not_existing: "Error! The user does not exist.",
      error_limit_exceeded: "The limit of attempts has been exceeded. Try again later.",
      error_unknown: "Unknown error! Please contact the oculai team.",
    },
    sign_up: {
      email: "Mail address",
      name: "Name",
      first_name: "First Name",
      last_name: "Last Name",
      company: "Company",
      your_role: "Your Role",
      role: "Role",
      temporary_password: "Temporary password (see mail)",
      new_password: "New password",
      repeat_new_password: "Repeat new password",
      sign_up: "Sign up",
      already_registered: "Already have an oculai account? Log in",
      error_mail_password: "Error! Email address or temporary password is incorrect.",
      error_must_be_mail: "Error! Username must be a mail address.",
      error_user_not_confirmed: "Error! The user has not been confirmed yet.",
      error_user_not_existing: "Error! The user does not exist.",
      error_unknown: "Unknown error! Please contact the oculai team.",
      error_modal_exists_text: "The user already exists",
      error_modal_login: "Please log in via the",
      error_modal_login_page: "login-page",
      error_modal_in: ".",
      terms_conditions_1: "With the creation of an oculai account, you agree to the",
      terms_conditions_2: "terms and conditions",
      terms_conditions_3: "of oculai.",
      sign_up_with_microsoft: "Sign up with Microsoft",
      social_error: "Unable to sign up. You must be invited to use oculai.",
    },
    session_expired: {
      message: "Your session has expired. Please refresh the page.",
      refresh_page: "refresh page",
    },
  },
  admin: {
    settings: {
      user: {
        title: "User overview",
        sub_title: "Here you can find information about your oculai account.",
        name: "Name",
        company: "Company",
        role: "Role",
      },
      language: {
        title: "Language settings",
        language_choice: "Language choice",
      },
    },
    project_participants: {
      participants: "Project Participants",
      add_participants: "Add Users to Project",
      table_user: "Participant",
      table_project: "Project",
      table_permissions: "Permissions",
      company_type: "Company",
      email: "Email",
      pending_invite: "Pending Invite",
      company_types: {
        general_contractor: "General Contractor",
        subcontractor: "Subcontractor",
        owner: "Owner/Developer",
        architect: "Architect/Planner",
        other: "Other",
      },
      user_roles: {
        project_management: "Project Management",
        site_management: "Site Management",
        foreman: "Foreman",
        management_board: "Management Board / Executive Management",
        division_management: "Division Management / Department Management",
        bim_management: "BIM Management",
        lean_management: "Lean Management",
        calculation: "Calculation",
        controlling: "Controlling",
        procurement: "Procurement",
        scheduling: "Scheduling",
        business_development: "Business Development",
        assistance: "Assistance",
        other: "Other",
      },
      modal_invite_user: {
        invite_users: "Invite Users",
        add_user: "Add User",
        invite: "Invite",
        error_mail_required: "Email Address is Required",
        error_mail_invalid: "Invalid Email Address",
        error_company_type_required: "Company is Required",
      },
      modal_delete_user: {
        delete_from_project: "Remove User from Project",
        delete_permanently: "Permanently Delete User Account",
      },
      modal_permission: {
        update: "Update",
        cancel: "Cancel",
      },
      error: {
        permission_update: "Error, permissions could not be updated.",
        properties_update: "Error, user properties could not be updated.",
        invite: "Error, users could not be invited.",
        delete: "Error, user could not be deleted.",
      },
    },
    project_settings: {
      tab_general: "General",
      tab_project_participants: "Project Participants",
      tab_durations: "Working hours and vacations",
      tab_dcr: "Daily Construction Report",
    },
    general_project_settings: {
      project_status: {
        active: "active",
        completed: "completed",
      },
      validation_name_required: "name is required",
      validation_street_required: "street is required",
      validation_max_characters: "max {maxCharacterCount} characters",
      field_name_label: "Name",
      field_country_code_label: "Country code",
      field_zip_code_label: "ZIP code",
      field_timezone_label: "Timezone",
      field_street_label: "Address",
      field_planned_start_label: "Start date",
      field_planned_end_label: "End date",
      field_status_label: "State",
      save_error_message: "Unable to save updates",
      logo: "Logo",
      no_logo_available: "No logo available",
    },
    durations_project_settings: {
      working_hours_header: "Working hours",
      holidays_header: "Non-working days (public holidays, company vacations and disruptions)",
      holidays_editor_header: "Add a new non working day",
      start: "Start",
      end: "End",
      breaks: "Breaks (h)",
      hours: "h",
      add: "Add",
      save_error_message: "Unable to save updates",
      validation_start_more_than_end: "start should be before end",
      validation_start_or_end_empty: "start/end is required",
      validation_working_hours_breaks:
        "break must be more than 0 and less than the available hours",
      validation_name_empty: "name is required",
      date_range: "Choose a date range",
      holidays_field_name: "Name",
      holidays_field_date: "Date",
      holidays_field_type: "Type",
      holidays_field_start: "Start time",
      holidays_field_end: "End time",
      holidays_field_duration: "Duration",
      holidays_field_disturbance_type: "Disturbance type",
      holidays_field_disturbance_description: "Disturbance description",
      holidays_field_created_by: "Created by",
      no_holidays_message: "No holidays",
      add_public_holidays_button: "Import public holidays",
      public_holidays_for: "Public holidays for",
      no_public_holiday_message: "No public holidays for",
      validation_start_required: "start time is required",
      validation_end_required: "end time is required",
      validation_date_required: "date is required",
      validation_disturbance_type_empty: "disturbance type is required",
      working_day_label: "day | days",
      total_working_days_label: "Total working days: {total}",
      total_working_hours_label: "Total working hours: {total}",
      working_days_calculation_tooltip_header: "Working days are based on the following rules:",
      working_days_calculation_tooltip_item1: "no working time configured: 0 days",
      working_days_calculation_tooltip_item2: "0h < working time < 8h: 0.5 day",
      working_days_calculation_tooltip_item3: "8h <= working time: 1 day",
      non_working_day_type_labels: {
        public_holiday: "Public holiday",
        company_holiday: "Company holiday",
        disturbance: "Disturbance",
      },
    },
  },
  camera: {
    components: {
      camera_navbar: {
        timelapse: "Timelapse",
      },
      weather_container: {
        title: "Weather",
        kmh: " km/h",
        view_more: "View more",
        view_less: "View less",
        clock: "h",
      },
    },
    period: {
      info_text:
        "You can request the original, non-compressed video material within the last 7 days.",
      playback_period: "Playback period",
      date: "Date",
      time: "Time",
      duration: "Duration",
      in_minutes: "In minutes",
      play: "Play",
    },
    gallery: {
      pick_a_date: "Pick a date",
      date: "Date",
      search: "Search",
      error_timeframe: "There is not data for this date",
      download: {
        menu_title: "Download images",
        title: "Download gallery images",
        date_picker_placeholder: "All Days",
        files: "File | Files",
        start_download: "Start Download",
        stop_download: "Stop Download",
        resume_download: "Resume Download",
        ongoing_download: "Ongoing download. Please stop download first before closing!",
        success: "Successfully downloaded zip archive!",
        partial_download: "Partially downloaded zip archive.",
        base_file_name: "gallery",
        imgs_per_day: "image per day | images per day",
        all_imgs: "All images",
      },
    },
    daily_timelapse: {
      pick_a_date: "Pick a date",
      date: "Date",
      search: "Search",
      error_timeframe: "There is not data for this date",
    },
    compare: {
      pick_a_date: "Pick date and time",
      date: "Date",
      time: "Time",
      before: "Before",
      after: "After",
      button: "Search",
      err_no_image:
        "There is no image for the date/time combination. We are showing the closest we found",
      err_future_date: "Please choose a day prior to today!",
      err_no_image_range_1: "We couldn't find any image in the range of",
      err_no_image_range_2: "Please enter another value!",
      err_after_date: "After date must be greater than before date.",
      err_after_time: "After time must be greater than before time for the same date.",
      tutorial:
        "Here you can compare images of two points in time. Use the slider in the image to see the site progress!",
      no_image: "No image for this date",
    },
    customizable_timelapse: {
      create_button: "Create timelapse",
      table: {
        name: "Name",
        camera: "Camera",
        start_date: "Start",
        end_date: "End",
        duration: "Duration",
        duration_min: " min",
        created: "Creation date",
        play: "Play",
        play_button: "Play",
        extended: "Extended",
        extended_remove: "Remove timelapse",
        extended_download: "Download",
      },
      create_modal: {
        title: "Customizable timelapse",
        timelapse_name: "Timelapse name",
        camera: "Camera",
        start: "Start",
        end: "End",
        duration: "Timelapse duration",
        option_1: "1 minute",
        option_2: "2 minutes",
        option_3: "3 minutes",
        option_4: "4 minutes",
        option_5: "5 minutes",
        create: "Create timelapse",
        cancel: "Cancel",
      },
      success_notification: {
        message:
          "Timelapse successfully created! The creation of the video can take up to 60 minutes.",
      },
      errors: {
        create_error: "Error while creating the video. Please check your input.",
        unique_name:
          "Please choose a unique time-lapse name, which has not been assigned by you before.",
        start_date_after_today: "Start date is after today's date.",
        end_date_after_today: "End date is after today's date",
        end_date_before_start_date: "End date is before the start date",
        missing_name: "Missing timelapse name.",
        missing_start_date: "Please choose a start date.",
        missing_end_date: "Please select an end date.",
      },
    },
    dashboard: {
      rename: "Rename camera",
    },
    update_error: "Unable to update camera setting",
  },
  analytics: {
    processes: {
      last_updated: "Last updated:",
      no_data: "There is no process data available yet. Tracking will start soon!",
      processes: "Processes",
      display_type_label: "Display type",
      sort_by: "Sort by",
      process_group: "Process group",
      building: "Building",
      section: "Section",
      level: "Level",
      tags: "Tags",
      component: "Component",
      component_view: "Component view",
      section_view: "Section view",
      level_view: "Level view",
      clear_filters: "Clear all filters",
      placeholder_hierarchy_tag: "Select an hierarchy tag",
      placeholder_process_type: "Select a process type",
      type: "Type",
      location: "Location",
      start: "First activity",
      end: "Last activity",
      process: "Process",
      date: "Date",
      duration: "Duration",
      item: "Item | Items",
      brutto_day_duration: "Total duration",
      brutto_hour_duration: "Total hours",
      net_day_duration: "Active duration",
      net_hour_duration: "Active hours",
      standstill_days: "Downtime days",
      utilization: "Utilization Rate",
      processes_view: "Process view",
      new_version: "New version",
      old_version: "Old version",
      amount: "Amount",
      summary: "Summary",
      aggregation_view: "By process groups",
      plain_view: "By individual processes",
      process_data_gantt: "Process data gantt",
      process_data_table: "Activity log",
      unit_values: "Unit values",
      brutto_day_duration_tooltip:
        "Total duration in working days between the first and the last process recorded in this category",
      net_day_duration_tooltip: "Number of days on which processes were recorded in this category",
      net_hour_duration_tooltip:
        "Sum of the durations of the individual processes in this category without temporal overlaps",
      standstill_days_tooltip:
        "Number of days between the first and the last recorded activity on which no work was recorded in this category",
      utilization_tooltip: "Active hours / Total hours",
      total_duration: "Total duration",
      net_duration: "Net duration",
      workers: "Workers",
      working_hours: "Working hours",
      time_range: "Time range",
      show_activity_calendar: "Show activity calendar",
      sidebar: {
        analysis: "Analysis",
        before_after: "Before & After",
        total: "Total",
        processes_selected: "Process selected | Processes selected",
        active_total_days: "Active/Total Days",
        productive_days: "Productive Time",
        outage_days: "Outage Days",
        working_hours: "Working Hours",
        select_row: "Select row",
        open_in_activity_log: "Open in Activity log",
      },
      shortcuts: {
        title: "Shortcuts",
        draw: "Draw",
        draw_area: "Draw area to select multiple events",
        select_multiple: "Select multiple events",
        event: "event",
        open_context_menu: "Open context menu",
        resource: "resource",
        right_click: "Right Click",
        click: "Click",
      },
    },
    reports: {
      my_reports: "My reports",
      reports: "Reports",
      name: "Name",
      processes_components: "Processes / Components",
      building: "Building",
      section: "Section",
      level: "Level",
      by_building: "By building",
      by_section: "By section",
      by_level: "By level",
      filters: "Filters",
      type: "Type",
      is: "is",
      custom: "Custom",
      plot_config: "Plot configuration",
      trade: "Trade",
      component: "Component",
      select_values: "Select values",
      utilization: "Utilization",
      working_hours: "Working hours",
      mean: "Mean: ",
      planned: "Planned",
      total: "Total",
      remove_report: "Delete report",
      remove_filter: "Remove filter",
      select_filters: "Select filters",
      no_available_filters_left: "No available filters left",
      all: "All",
      cycle_times_plot: "Cycle times",
      working_hour_curve_plot: "Working hours curve",
      unit_values_plot: "Unit values",
      unit_values: "Unit values",
      unit_value: "Unit value",
      unit_values_missing: "Unit values are missing",
      no_data: "No data",
      absolute_date: "Absolute date",
      show_mean_working_hours: "Show mean working hours",
      in_progress: "In progress",
      show_utilization: "Utilization",
      show_planned: "Planned",
      no_reports: "There are no reports yet.",
      preset_mode: "Components",
      process_mode: "Single processes",
      only: "Only",
      location: "Location",
      milestone: "Milestone",
      milestone_plot: "Milestone",
      target_period: "Target: ",
      actual_period: "Actual: ",
      present: "now",
      modes: "Sorting",
      actual_not_started: "Not started yet",
      reset_zoom: "Reset zoom",
      end_date_mode: "By end dates",
      sequence_mode: "By construction phase",
      create_first_report: "Create the first report",
      planned_title: "Planned working days are taken from the schedule.",
      planned_description:
        "Planned working days are calculated based on the working hours configured in the project settings and exclude holidays and company vacations.",
      active_title: "Active workdays are days on which at least one activity has taken place.",
      active_description:
        "Active workdays are calculated based on the working hours configured in the project settings and exclude holidays, company vacations, and disruptions.",
      inactive_title: "Inactive workdays are days on which no activity has taken place.",
      inactive_description:
        "Inactive workdays are calculated based on the working hours configured in the project settings and exclude holidays, company vacations, and disruptions.",
      utilization_title: "Utilization is based on the ratio of working hours to capacity.",
      utilization_description:
        "Capacity is calculated based on the maximum number of workers multiplied by the working hours configured in the project settings.",
      working_hours_title: "Working Hours.",
      working_hours_description:
        "Working hours are calculated based on the sum of the maximum number of workers multiplied by the time they spend on the construction site.",
      outages_title: "Outages.",
      outages_description:
        "Days with camera outages are working days on which cameras belonging to this component were not running more than 50% of the time.",
      unit_values_title: "Unit value",
      unit_values_description:
        "The unit value is calculated from working hours / quantity and indicates the required time effort per unit of quantity.",
      dont_save_report: "Don't save the report",
      update_report_error: "Unable to save report",
      delete_report_error: "Unable to delete report",
      delete_confirmation: {
        header: "Confirmation",
        message: 'Report "{name}" will be deleted.',
        confirm: "Delete report",
        cancel: "Cancel",
      },
      report_summary_load_error: "Unable to load report data",
      edit_report: "Edit report",
      dashboard_new_report: "new",
      query_value_plot: "Single Value",
      show_previous_period_value: "Compare with previous period",
      previous_period_label: "Previous period",
      metric: "Metric",
      query_value_metrics: {
        working_hours: "Working hours",
        unit_value: "Unit value",
        utilization: "Utilization",
        productive_days: "Productive Time",
        active_days: "Active days",
        inactive_days: "Inactive days",
        delta_planner: "Average delta planner",
        project_progress: "Project progress",
      },
      query_value_aggregations: {
        sum: "Sum",
        average: "Average",
        median: "Median",
        latest: "Latest",
      },
      query_value_units: {
        hours: "h",
        working_days: "WD",
        working_days_full: "Working days",
      },
      query_value_formatting_rules: {
        header: "Visual formatting rules",
        if_value_is: "If value is",
        show_with: "show with",
        actions: {
          green_background: "Green background",
          red_background: "Red background",
          yellow_background: "Yellow background",
        },
      },
      query_value_progress: {
        weight: "Weight",
        progress_for_date: "Progress at date",
        not_finished: "not finished",
        finished_working_hours_parent_tooltip: "Sum of the subordinate components",
        weight_tooltip:
          "Indicates the percentage influence (weight) of the component on the overall progress, based on the planned durations in the schedule.",
      },
      aggregation: "Aggregation",
      period: "Period",
      previous_period: "Previous period",
      unable_to_load: "Unable to load processes",
    },
    planner: {
      label_upload_planner: "Upload project plan",
      plan_upload_desc: "Drag and drop a MS Project XML file in the box",
      title: "Schedule",
      last_updated: "Last updated:",
      zoom: "Zoom",
      target_period: "Target: ",
      actual_period: "Actual: ",
      chart_progress: "Progress: ",
      chart_target_period: "Target: ",
      chart_actual_period: "Actual: ",
      chart_sub_processes: "Sub processes: ",
      chart_not_started: "not started",
      chart_no_end_date: "no end date",
      no_planner_import_possible:
        "Tracking has already started. Contact the oculai team to upload a new version of the plan.",
      wrong_file_format:
        "File format not supported. Please make sure to upload a MS Project XML file.",
      all: "All",
      present: "now",
      visible: "Visible",
      completeview: "All",
      currentview: "Visible",
      milestone: "Milestone",
      events: "Events",
      items: "Planner Items",
      critical_path: "Critical Path",
      show_complete_planner: "Show full schedule",
      not_started_event: "Not started yet",
      actual_not_started: "Not started yet",
      modes: {
        visitor: "Visitor",
        revision: "Revision",
      },
      planned_event_name: "planned",
      actual_event_name: "actual",
      start_date: "Start Date",
      start_time: "Start Time",
      end_date: "End Date",
      end_time: "End Time",
      event_validation: {
        start_date_required: "Start date is required",
        end_date_required: "End date is required",
        start_date_in_future: "Start date can't be in the future",
        end_date_in_future: "End date can't be in the future",
        start_bigger_than_end: "Start date must be smaller than end date",
      },
      comments: {
        add_comment_label: "Add your comment",
        commented: "commented",
        add_comment_menu_item: "Comment",
        unable_to_create_error: "Unable to create comment",
        unable_to_delete_error: "Unable to delete comment",
        delete_comment_menu_item: "Delete",
        delete_confirmation_title: "Delete confirmation",
        delete_confirmation_confirm: "Delete",
        delete_confirmation_cancel: "Cancel",
        loading_message: "Loading comments...",
      },
      edit_event_menu_item: "Edit",
      show_processes_menu_item: "Show processes",
      hide_processes_menu_item: "Hide processes",
      more_info_menu_item: "More info",
      add_actual_event: "Add actual",
      edit_actual_event: "Edit actual",
      actual_active: "In progress",
      actual_finished: "Completed",
      delete_actual_confirmation_title: "Delete event",
      delete_actual_confirmation_confirm: "Delete",
      delete_actual_confirmation_cancel: "Cancel",
      delete_actual_confirmation_message: "Do you really want to delete the event?",
      actual_not_filled: "Please fill in all fields",
      actual_period_error: "The end date is before the start date",
      actual_period_event_error: "An error occurred while saving.",
      nothing_to_undo_message: "Nothing to undo",
      nothing_to_redo_message: "Nothing to redo",
      excel_export_error_message: "Unable to retrieve export data",
      unable_to_load_plan_error_message: "Unable to load plan",
      unable_to_generate_pdf_error_message: "Unable to generate PDF",
      create_revision_error_message: "Something went wrong. Please, try again!",
      calendar_week_label: "CW",
      name_column_label: "Name",
      export_name: "Schedule",
      processes: {
        breaks: "Breaks",
        intervals: "Intervals",
      },
      working_days_label: "Working day | Working days",
      working_days_input_label: "Working days",
      outages: "Outages",
      no_search_results: "No records found",
      non_working_days: "Non-working days",
      unable_to_load_plan: "Unable to load plan",
      mark_as_done_modal: {
        mark_as_done_button: "Mark as done",
        date_label: "When was the procedure completed?",
        go_to_last_process: "go to last process",
        go_to_last_concreting: "go to last concreting",
      },
      unable_to_save_changes: "Unable to save changes",
      planned_progress: "Planned progress",
      actual_progress: "Actual progress",
      project_completed_message: "Project completed - 100%",
      to_planner_link: "To the planner",
    },
    site_activity: {
      title: "Site activity",
      tab_walking_paths: "Walking paths",
      pick_a_date: "Pick a date",
      date: "Date",
      search: "Search",
      error_timeframe: "There is not data for this date",
      no_data: "No construction activity recorded so date. Tracking will start soon!",
    },
  },
  calendar: {
    week_days: {
      "1": {
        long: "Monday",
        short: "Mon",
      },
      "2": {
        long: "Tuesday",
        short: "Tue",
      },
      "3": {
        long: "Wednesday",
        short: "Wed",
      },
      "4": {
        long: "Thursday",
        short: "Thu",
      },
      "5": {
        long: "Friday",
        short: "Fri",
      },
      "6": {
        long: "Saturday",
        short: "Sat",
      },
      "7": {
        long: "Sunday",
        short: "Sun",
      },
      "0": {
        long: "Sunday",
        short: "Sun",
      },
    },
    months: {
      "1": {
        long: "January",
        short: "Jan",
      },
      "2": {
        long: "February",
        short: "Feb",
      },
      "3": {
        long: "March",
        short: "Mar",
      },
      "4": {
        long: "April",
        short: "Apr",
      },
      "5": {
        long: "May",
        short: "May",
      },
      "6": {
        long: "June",
        short: "Jun",
      },
      "7": {
        long: "July",
        short: "Jul",
      },
      "8": {
        long: "August",
        short: "Aug",
      },
      "9": {
        long: "September",
        short: "Sept",
      },
      "10": {
        long: "October",
        short: "Oct",
      },
      "11": {
        long: "November",
        short: "Nov",
      },
      "12": {
        long: "December",
        short: "Dec",
      },
    },
    today: "Today",
  },
  report: {
    title: "Daily Report",
    planned_project_start: "Project start",
    planned_project_end: "Planned project end",
    delete_report: "Delete report",
    overview_link: "To report overview",
    config: "Settings",
    logo_upload: "Company Logo",
    logo_upload_desc: "Drop image here PNG or JPG",
    config_sections: "Report sections",
    bulk_download: "Bulk download",
    weather_threshold: "Weather thresholds",
    tracking_label_enabled: "Intelligent tracking by oculai",
    tracking_label_disabled: "Application of configured project working times",
    process_time_label: "Assign new process start and end time",
    process_time_desc:
      "By selecting this option you initialize processes start and end time with the working ours values",
    logo_tooltip:
      "Upload the company logo in PNG or JPEG format here to invidualize the construction report.",
    working_hours_tooltip:
      "Define here if oculai should determine the working time automatically or set fixed working time for each weekday.",
    weather_warnings_tooltip:
      "Define rules to display bad weather warnings in the monthly overview of the construction day report.",
    sections_tooltip:
      "Drag and drop report sections between the Active and Inactive boxes to show or hide sections in the report.\n\nMove a section within a box to invidualize the order.",
    site_duration_start: "Start",
    site_duration_end: "End",
    report_approved: "Report approved!",
    weather: {
      time_range: "Consider warnings in the time range of:",
      precipitation: "Precipitation",
      cold_temperature: "Cold weather",
      hot_temperature: "Hot weather",
      wind: "Wind",
      max_wind_speed: "Wind speed (max)",
      cold_temperature_type: "°C",
      hot_temperature_type: "°C",
      precipitation_type: "mm/h",
      wind_type: "km/h",
      min: "min",
      max: "max",
      min_value: "Minimum",
      max_value: "Maximum",
      "24/7-7": "24h 7-7",
      "7/7-14": "7h 7-14",
      WEATHER_TIME_RANGE: "Working hours",
      ENTIRE_TIME_PERIOD: "Entire period",
      precipitation_volume: "Precipitation amount",
      for: "for",
      within: "within",
      unit: "unit",
      and: "and",
      or: "or",
    },
    section_title: {
      working_hours: "working hours",
      workforce: "Workforce",
      processes: "Processes",
      special_events: "Special Events",
      notes: "Notes",
      old_data_label: "Copy previous date",
      gallery: "Gallery",
      materials: "Incoming Material",
      material: "Material",
      machinery: "Heavy Machinery",
      site_duration: "Site duration",
      weather: "Weather",
      disturbances: "Disturbances",
    },
    sections: {
      workforce: {
        workforce_type_entries: {
          project_manager: "Project Manager",
          construction_manager: "Site Manager",
          foreman: "Foreman",
          construction_worker: "Construction Worker",
          construction_skilled_worker: "Skilled Construction Worker",
          crane_operator: "Crane Operator",
          equipment_operator: "Equipment Operator",
        },
        workforce_type: "Role",
        company: "Company",
        amount: "Workers",
        note: "Note",
      },
      processes: {
        process_type: "Activity",
        location: "Location",
        start_time: "Start Time",
        end_time: "End time",
        duration: "Duration",
        note: "Notes",
      },
      special_events: {
        special_events_type_entries: {
          additional_service: "Additional services",
          order_contractor: "New contractor orders",
          plan_change: "Planning change",
          heavy_equipment_use: "Heavy equipment use",
          acceptance: "Acceptance",
          visit: "Visit",
          other: "Other",
          impediment: "Impediment",
          hourly_wage_work: "Hourly wage work",
          reinforcement_inspection: "Reinforcement inspection",
          condition_assessment: "Condition assessment",
        },
        special_events_type: "Event type",
        note: "Notes",
      },
      material: {
        material_type: "Name",
        amount: "Quantity",
        unit: "Unit",
        note: "Note",
        unit_entries: {
          kg: "kg",
          "m^2": "m^2",
          "m^3": "m^3",
          piece: "pc",
          t: "t",
        },
      },
      machinery: {
        machinery_type: "Type",
        amount: "Quantity",
        note: "Note",
      },
      gallery: {
        oai_images_title: "Select from camera gallery",
        custom_images_title: "OR upload your custom images",
        no_images: "No images found for: ",
        add_note: "Add note",
      },
      disturbances: {
        name: "Name",
        type: "Type",
        date_range: "Date range",
        description: "Description",
        confirmation_header: "Confirmation",
        cancel_action: "Cancel",
        delete_action: "Delete",
        confirmation_message:
          "Are you sure you want to delete this disturbance? This action affects the disturbances of all reports.",
      },
    },
    general: {
      custom_author: "Custom entry",
      empty_combobox: "Please select a value",
      empty_options: "Add a new value",
      last_update: "Last update",
      approved_at: "Approved at",
      approved_by: "Approved by",
      by: "by",
      before: "Before",
      after: "After",
      old_data_label: "Copy from",
      merge_processes_title: "Merge already filled entries with oculai data.",
      merge_processes_sub_title: "Identical entries are not merged.",
      merge_processes_button: "Add data",
      merge_notification: "Add oculai data",
      prev_day: "Get previous day",
      old_data_title: "Get old data from",
      no_images: "No images available yet!",
      section_empty: "No entries!",
      processing: "Processing",
      data: "data",
      create: "Create",
      type_value: "Type value you want to add",
      gallery_no_image: "No image available",
      notes_placeholder: "Add a note...",
      enable_time_visibility: "Show start and end times for process entries",
    },
    bulk_export: {
      title: "Bulk Export",
      main_label: "Choose a time range",
      calendar_label: "You can choose a range of date up to 31 days to download reports",
      file_type_label: "Document Type",
      status_type_label: "Report Status",
      date_range_placeholder: "Choose up to 31 days",
      approved_reports: "Approved reports",
      all_reports: "All reports",
      today: "Today",
      this_month: "This month",
      last_month: "Last month",
      last_30_days: "Last 30 days",
      this_week: "This week",
      last_week: "Last week",
      pdf: "Combined PDF",
      zip: "ZIP",
    },
  },
  buttons: {
    cancel: "Cancel",
    save: "Save",
    back_overview: "Back to overview",
    export_report: "Export report",
    export_plain: "Export",
    send: "Send",
    copy: "Copy",
    stay_page: "Continue editing",
    leave_page: "Leave anyway",
    export: "Export and discard changes",
    search: "Search",
    download: "Download",
    edit: "Edit",
    bulk_export: "Bulk export",
    save_and_approve: "Save and approve",
    approve: "Approve",
    revert_changes: "Revert changes",
    apply: "Apply",
    add_images: "Add images",
    download_image_range: "Download images for time range",
    count_selected: "{selected} out of {total} selected",
    add: "Add",
    delete: "Delete",
    comment: "Comment",
    time_range: "Time range",
    import: "Import",
    clear: "Clear",
    load_more: "Load more",
    create: "Create",
    submit: "Submit",
    next: "Next",
  },
  err: {
    no_reports_found: "No reports found for given time range",
    required: "This is required",
    negative_value: "Negative value",
    time: "End time should be greater than start time",
    empty_values: "Please fill all the highlighted inputs!",
    saved_values: "No data available for the selected day",
    invalid_date: "Invalid date. Please follow the pattern shown.",
    save_before_leave: "You have edit sessions open. Please save before leaving!",
    save_before_export: "You have unsaved edits. Do you want to export report and discard changes?",
    save_before_mode_switch: "Unsaved changes will be lost. Continue anyway?",
    save_before_leave_title: "Unsaved edits",
    server_error: "Something went wrong. Please try again later.",
    file_type_image: "Invalid file type. Please upload an image file.",
    err_future_date: "This date is in the future. Please choose another date!",
    no_live_found: "This camera is currently off.",
    no_data_yet: "There is no data yet. Come back later!",
    weather: {
      data_not_found: "No weather data found!",
      project_not_found: "Project not found” (invalid customer name or site id)",
    },
    internal_error: "Internal server error.",
    error_streams_not_found: "There are no images available right now!",
    no_images_found:
      "There are no images saved for the selected date. Please, choose another date!",
    no_data_found: "There is no data saved for the selected date. Please, choose another date!",
    no_weather_data: "There is no weather data available for this day.",
    no_streaming_found: "No stream replay found for the selected period",
    err_period_future_date: "Please select a day prior than today",
    no_timelapse_found: "There is no timelapse saved for the selected date",
    export: {
      no_date_selected: "No date selected",
      no_export_option: "No export option selected",
      no_status_option: "No status option selected",
      no_reports_found: "No reports found for given time range",
    },
    planner: {
      no_camera_per_item: "There is no camera images for this planner item",
    },
    inputs: {
      time_wrong_format: "Wrong time format",
    },
  },
  general: {
    new_label: "New",
    before: "Before",
    after: "After",
    active: "Active",
    inactive: "Inactive",
    label_upload_image: "Upload an image",
    loading_message: "This may take a few seconds, please don't close this page.",
    loading: "Loading...",
    search_placeholder: "Search",
    no_data_yet: "No data yet",
  },
  video_player: {
    unable_to_play_message: "Unable to play video",
    low_bandwidth_mode: "Low Bandwidth Mode",
  },
  time: {
    working_day: "Working Day | Working Days",
    day: "Day | Days",
    hour: "Hour | Hours",
    minute: "Minute | Minutes",
    last_day: "Last day",
    last_week: "Last week | Last {count} weeks",
    last_calendar_week: "Last calendar week",
    last_month: "Last month | Last {count} months",
    last_year: "Last year | Last {count} years",
    all_time: "All time",
    start_gantt: "START",
    end_gantt: "END",
  },
  working_day: {
    working_day: "Working Day | Working Days",
    calendar_day: "Day | Days",
    non_working_day: "Non working day",
    holiday: "Holiday/Vacation",
    holiday_days: "Holiday/Vacation Day | Holiday/Vacation Days",
    critical_disturbance: "Critical disturbance",
    critical_disturbance_days: "Critical disturbance day | Critical disturbance days",
    active: "Active",
    active_days: "Active Day | Active Days",
    inactive: "Inactive",
    inactive_days: "Inactive Day | Inactive Days",
    outage: "Outage",
    outage_days: "Camera outage day | Camera outage days",
  },
  unit_values: {
    header: "Unit Values",
    loading_error: "Unable to load unit values",
    saving_error: "Unable to save unit values",
    no_unit_values: "No unit values",
    building_label: "Building",
    level_label: "Level",
    section_label: "Section",
    duration_label: "Duration",
    duration_unit: "h",
    workforce_label: "Workforce",
    workforce_unit: "n",
    quantity_label: "Quantity",
    unit_value_label: "Unit Value",
    unit_value_unit: "wh",
    merge_rows: "Merge rows",
    split_rows: "Split rows",
    add_type: "Add unit value",
    export_to_excel: "Export to Excel",
    planned_start: "Start",
    planned_end: "End",
    detail_oculai_new_calculated: "oculai adjusted",
    detail_delta_label: "Markup",
    detail_custom_delta_label: "Adjustment",
    approved: "Approved",
    select_type: "Select unit value",
    header_included_processes: "Included processes",
    column_modal: {
      title_add: "New Unit Value",
      title_edit: "Edit Unit Value",
      type_field: "Type",
      validation_type_required: "Type is required",
      no_types_found: "No type found",
      invalid_number: "Invalid number",
      cleaned_unit_values_header: "Cleaned unit value",
      type_duration_factor: "Marginal hours factor",
      type_duration_factor_tooltip:
        "Fringe hours are working hours that are not assigned to the core activity. An example of this is the preparation of the workplace (cleaning, tidying, moving).",
      type_workforce_delta: "Labor markup",
      type_workforce_delta_tooltip:
        "Blind hours are working hours that cannot be recorded by oculai because they cannot be recorded by the camera. An example of this is stripping the ceiling.",
      type_value_delta: "Unit value static markup",
      type_value_delta_tooltip:
        "The effort value can be corrected directly using the blind hour flat rate: 0.5 (oculai unit value) + 0.3 (blind hour flat rate) = 0.8",
    },
    types: {
      uv_wall_default: { label: "In-situ concrete walls", unit: "m²" },
      uv_wall_default_formwork_m2: { label: "In-situ concrete walls formwork", unit: "m²" },
      uv_wall_default_rebar_t: { label: "In-situ concrete walls reinforcement", unit: "t" },
      uv_wall_default_concrete_m3: {
        label: "In-situ concrete walls concreting bucket/pump",
        unit: "m³",
      },
      uv_wall_default_concrete_bucket_m3: {
        label: "In-situ concrete walls concreting bucket",
        unit: "m³",
      },
      uv_wall_default_concrete_pump_m3: {
        label: "In-situ concrete walls concreting pump",
        unit: "m³",
      },
      uv_wall_default_element_m2: { label: "Precast walls / cavity walls", unit: "m²" },
      uv_wall_parapet_m2: { label: "Parapet", unit: "m²" },
      uv_wall_parapet_formwork_m2: { label: "Parapet formwork", unit: "m²" },
      uv_wall_parapet_rebar_t: { label: "Parapet reinforcement", unit: "t" },
      uv_wall_parapet_concrete_m3: { label: "Parapet concreting bucket/pump", unit: "m³" },
      uv_wall_parapet_concrete_bucket_m3: { label: "Parapet concreting bucket", unit: "m³" },
      uv_wall_parapet_concrete_pump_m3: { label: "Parapet concreting pump", unit: "m³" },
      uv_wall_column_m3: { label: "In-situ concrete columns", unit: "m³" },
      uv_wall_column_pc: { label: "In-situ concrete columns", unit: "pc" },
      uv_wall_column_m2: { label: "In-situ concrete columns", unit: "m²" },
      uv_wall_column_formwork_m2: { label: "In-situ concrete columns formwork", unit: "m²" },
      uv_wall_column_rebar_t: { label: "In-situ concrete columns reinforcement", unit: "t" },
      uv_wall_column_concrete_bucket_m3: {
        label: "In-situ concrete columns concreting bucket/pump",
        unit: "m³",
      },
      uv_wall_column_concrete_bucket: {
        label: "In-situ concrete columns concreting bucket",
        unit: "m³",
      },
      uv_wall_column_concrete_pump: {
        label: "In-situ concrete columns concreting pump",
        unit: "m³",
      },
      uv_wall_column_element_pc: { label: "Precast columns", unit: "pc" },
      uv_wall_brickwork_all_m2: { label: "Brickwork all", unit: "m²" },
      uv_wall_brick_outline_lm: { label: "Backfill", unit: "lm" },
      uv_wall_brickwork_m2: { label: "Brickwork", unit: "m²" },
      uv_ceiling_m2: { label: "Slab", unit: "m²" },
      uv_ceiling_default_formwork_m2: { label: "Slab formwork", unit: "m²" },
      uv_ceiling_element_semi_m2: { label: "Precast slabs", unit: "m²" },
      uv_ceiling_default_rebar: { label: "Ceiling reinforcement", unit: "t" },
      uv_ceiling_default_concrete_m3: { label: "Slab concreting bucket/pump", unit: "m³" },
      uv_ceiling_default_concrete_bucket_m3: { label: "Slab concreting bucket", unit: "m³" },
      uv_ceiling_default_concrete_pump_m3: { label: "Slab concreting pump", unit: "m³" },
      uv_ceiling_element_full_m2: { label: "Precast slabs", unit: "m²" },
      uv_ceiling_underbeams_m3: { label: "Beams", unit: "m³" },
      uv_ceiling_underbeams_formwork_m2: { label: "Beams formwork", unit: "m²" },
      uv_ceiling_underbeams_rebar_t: { label: "Beams reinforcement", unit: "t" },
      uv_ceiling_underbeams_concrete_m3: { label: "Concrete bucket/pump beam", unit: "m³" },
      uv_ceiling_underbeams_concrete_bucket_m3: { label: "Concrete bucket beam", unit: "m³" },
      uv_ceiling_underbeams_concrete_pump_m3: { label: "Concrete pump beam", unit: "m³" },
      uv_foundation_slab_m2: { label: "Floor slab", unit: "m²" },
      uv_foundation_slab_formwork_lm: { label: "Floor slab formwork", unit: "lm" },
      uv_foundation_slab_rebar_t: { label: "Floor slab reinforcement", unit: "t" },
      uv_foundation_slab_concrete_m3: {
        label: "Floor slab concreting bucket/pump",
        unit: "m³",
      },
      uv_foundation_slab_concrete_bucket_m3: {
        label: "Floor slab concreting bucket",
        unit: "m³",
      },
      uv_foundation_slab_concrete_pump_m3: {
        label: "Floor slab concreting pump",
        unit: "m³",
      },
      uv_foundation_point_m2: { label: "Individual foundations", unit: "m²" },
      uv_foundation_point_formwork_lm: {
        label: "Individual foundations formwork",
        unit: "lm",
      },
      uv_foundation_point_rebar_t: {
        label: "Individual foundations reinforcement",
        unit: "t",
      },
      uv_foundation_point_concrete_m3: {
        label: "Individual foundations concrete bucket/pump",
        unit: "m³",
      },
      uv_foundation_point_concrete_bucket_m3: {
        label: "Individual foundations concrete bucket",
        unit: "m³",
      },
      uv_foundation_point_concrete_pump_m3: {
        label: "Individual foundations concreting pump",
        unit: "m³",
      },
      uv_foundation_stripe_m2: { label: "Strip foundations", unit: "m²" },
      uv_foundation_stripe_formwork_lm: { label: "Strip foundations formwork", unit: "lm" },
      uv_foundation_stripe_rebar_t: { label: "Strip foundations reinforcement", unit: "t" },
      uv_foundation_stripe_concrete_m3: {
        label: "Strip foundations concreting bucket/pump",
        unit: "m³",
      },
      uv_foundation_stripe_concrete_bucket_m3: {
        label: "Strip foundations concrete bucket",
        unit: "m³",
      },
      uv_foundation_stripe_concrete_pump_m3: {
        label: "Strip foundations concreting pump",
        unit: "m³",
      },
    },
  },
  paywall: {
    working_hours: "Interested in hours worked per process? Contact the oculai team.",
  },
  disturbances: {
    disturbance: "Disturbance | Disturbances",
    type: {
      plans_not_available: "Plans not available",
      bad_weather: "Bad weather",
      holiday_season: "Holiday season",
      late_deliveries: "Late deliveries",
      staff_shortage: "Staff shortage",
      refused_acceptance: "Refused acceptance of statically critical components",
      missing_installation_parts: "Missing installation parts",
      unpaid_invoices: "Unpaid invoices",
      missing_scaffolding: "Missing scaffolding",
      missing_formwork: "Missing formwork",
      missing_material: "Missing material",
      relocation_of_construction_site_equipment: "Relocation of construction site equipment",
      missing_cranes: "Missing cranes",
      missing_pre_prepayment: "Missing pre-prepayment",
    },
  },
  process_classes: {
    "0": "Gravel Layer",
    "1": "Blinding Layer (Bucket)",
    "2": "Blinding Layer (Pump)",
    "3": "Formwork Foundation Slab",
    "4": "Formwork Stripe Foundation",
    "5": "Formwork Point Foundation",
    "6": "Formwork Foundation Elevator Shaft",
    "7": "Reinforcement Foundation Slab",
    "8": "Reinforcement Stripe Foundation",
    "9": "Reinforcement Point Foundation",
    "10": "Reinforcement Foundation Elevator Shaft",
    "11": "Concreting Foundation Slab (Bucket)",
    "12": "Concreting Foundation Slab (Pump)",
    "13": "Concreting Stripe Foundation (Bucket)",
    "14": "Concreting Stripe Foundation (Pump)",
    "15": "Concreting Point Foundation (Bucket)",
    "16": "Concreting Point Foundation (Pump)",
    "17": "Concreting Foundation Elevator Shaft (Bucket)",
    "18": "Concreting Foundation Elevator Shaft (Pump)",
    "19": "Insulation Foundation Slab",
    "20": "Insulation Stripe Foundation",
    "21": "Insulation Point Foundation",
    "22": "Insulation Foundation Elevator Shaft",
    "23": "Ceiling Elements",
    "24": "Precast Ceiling Element",
    "25": "Precast Beam",
    "26": "Precast Balcony",
    "27": "Precast Stairs",
    "28": "Formwork Ceiling",
    "29": "Edge Formwork Ceiling",
    "30": "Formwork Underbeam",
    "31": "Reinforcement Ceiling",
    "32": "Pipes Ceiling",
    "33": "Reinforcement Underbeam",
    "34": "Concreting Ceiling (Bucket)",
    "35": "Concreting Ceiling (Pump)",
    "36": "Concreting Underbeam (Bucket)",
    "37": "Concreting Underbeam (Pump)",
    "38": "Insulation Ceiling",
    "39": "Masonry Work",
    "40": "Masonry Outline",
    "41": "Precast Wall",
    "42": "Precast Column",
    "43": "Wet Cell",
    "44": "Formwork Wall",
    "45": "Formwork Column",
    "46": "Formwork Parapet",
    "47": "Reinforcement Wall",
    "48": "Reinforcement Column",
    "49": "Reinforcement Parapet",
    "50": "Concreting Wall (Bucket)",
    "51": "Concreting Wall (Pump)",
    "52": "Concreting Column (Bucket)",
    "53": "Concreting Column (Pump)",
    "54": "Concreting Parapet (Bucket)",
    "55": "Concreting Parapet (Pump)",
    "56": "Rafters Roof",
    "57": "Insulation Roof",
    "58": "Tiles Roof",
    "59": "Roof Metal",
    "60": "Reinforcement Precast Wall",
    "61": "Concreting Precast Wall (Bucket)",
    "62": "Concreting Precast Wall (Pump)",
    "63": "Masonry Work Parapet",
    "64": "Precast Parapet",
    "65": "Schalung Platform Stairs",
    "66": "Bewehrnug Platform Stairs",
    "67": "Concreting Platform Stairs (Bucket)",
    "68": "Concreting Platform Stairs (Pump)",
    "69": "Shoring",
    "70": "Void Formers Ceiling",
    "1000": "Panels Wall",
    "1001": "Longitudinal bars (bottom layer) Wall",
    "1002": "Shear Links Wall",
    "1003": "Welding Wall",
    "1004": "Shear Key Installation",
    "1005": "ESS (Embedded Steel Structure) Wall",
    "1006": "Loose Bars (around Shear Keys)",
    "1007": "Boxout Formwork Wall",
    "1008": "Safety Plastic Pads Wall",
    "1009": "Safety Handrails Wall",
    "1010": "Lifting Device Installation Wall",
    "1011": "Tilting + Move Wall",
    "1012": "Wall Installation Top Slab",
    "1013": "Lower Haunches Top Slab",
    "1014": "Longitudinal Bars Slab",
    "1015": "Concrete Spacers Top Slab",
    "1016": "Panels Slab",
    "1017": "Welding Slab",
    "1018": "Transversal ESS (Embedded Steel Structure) Slab",
    "1019": "Rebar ESS Fasteners Top Slab",
    "1020": "Ducts - pre Tensioning",
    "1021": "Longitudinal ESS (Embedded Steel Structure) Slab",
    "1022": "U-shaped Loose Bars Top Slab",
    "1023": "Panel External Haunches Slab",
    "1024": "Waterstop Cages",
    "1025": "Shear Links Slab",
    "1026": "Ducts (for electrical equipment) Top Slab",
    "1027": "Remove Lifting Device Top Slab",
    "1028": "Safety Handrails Slab",
    "1029": "Safety Plastic Pads Slab",
    "1030": "Starter Toes Base Slab",
    "1031": "Starter Bars Base Slab",
    "1032": "Male Shear Key Base Slab",
    "1033": "Skidding Process Base Slab",
    "1034": "Skidding (Shoes Base Slab)",
    "1035": "Lower Haunches Base Slab",
    "1036": "Housekeeping Floor Buffer",
    "1037": "Welding Buffer",
    "1038": "Skidding (Shoes Buffer)",
    "1039": "Skidding (Element Buffer)",
    "1040": "Skidding (Pushers Buffer)",
    "1041": "Lifting Corbals Buffer",
    "1042": "Steel End Frame Buffer",
    "1043": "Curtain Closing",
    "1044": "Curtain Opening",
    "1045": "Scaffolding Breakdown Central Gallery",
    "1046": "Scaffolding Assembly",
    "1047": "Formwork Stop End Buffer",
    "1048": "Waterstop Rubber Buffer",
    "1049": "Skidding (Shoes Buffer) Removal ",
    "1050": "Removal Bridges",
    "1051": "Formwork Set In Internal",
    "1052": "Prepouring Pipes",
    "1053": "Foil for Pipes",
    "1054": "Pouring Concrete Truck",
    "1055": "Pouring Pump",
    "1056": "Concrete Upstand Cleaning",
    "1057": "Jet Wash Cleaning",
    "1058": "Fresh Concrete Membrane",
    "1059": "Formwork Removal External Haunch Top Slab",
    "1060": "Strike Walls Stop End Formwork ",
    "1061": "Carpet External Haunch",
    "1062": "Bitumen Application",
    "1063": "Scaffolding Towers Removal",
    "1064": "Pushing back Launching Beam MT1",
    "1065": "Pushing back Launching Beam Central Gallery",
    "1066": "Pushing back Lunching Beam MT2",
    "1067": "Pushing back Launching Beam RT1",
    "1068": "Pushing Launching Beam back RT2",
    "1069": "Pushers Installation",
    "1070": "Pushers Removal",
    "1071": "Supporting jacks SN+1",
    "1072": "Oiling Formwork",
    "1073": "Concrete Spacers",
    "1074": "Pushing back Launching Beam Center Position",
    "1075": "Bridges Installation S+1 ",
    "1076": "Safety Handrails Top",
    "1077": "Cleaning concrete pipes/devices",
    concrete: "Concrete",
    prefab: "Prefab",
    reinforce: "Rebar",
    support: "Formwork",
    brickwork: "Brickwork",
    sheath: "Cover",
    safety: "Safety",
    transport: "Transport",
    ground: "Ground",
    foundation: "Foundation",
    ceiling: "Ceiling",
    wall: "Wall",
    roof: "Roof",
  },
  issue_report: {
    report_process_issue: "Report Process Issue",
    issue_type: "Issue Type",
    select_issue: "Select Issue",
    new_process_class: "New Process Class",
    process_mappable: "Mappable",
    non_mappable_process_warning:
      "Are you sure? Process class cannot be assigned to an activity in the schedule.",
    new_level: "New Level",
    start: "Start",
    end: "End",
    intervals_error: "Intervals should not overlap.",
    issue_description: "Issue Description",
    issue_process_class: "Process Class",
    issue_time_interval: "Time Intervals",
    issue_level: "Level",
    issue_people_count: "People Count",
    issue_location: "Location / Section",
    issue_process_not_exist: "Process should not exist",
    resolve: "Resolve",
    report_to_oculai: "Cannot resolve this issue? Report to oculai team",
    process_updated: "Process successfully updated!",
    issue_failed_to_resolve: "Issue could not be fixed",
    issue_reported: "Request successfully reported to oculai!",
    issue_failed_to_report: "Failed to report issue",
    level_to_mask_mapping_not_applicable:
      "Due to the change of the floor, the process could not be assigned to an operation in the schedule - the oculai team will check the change!",
    update_process_class: "Update Process Class",
    update_level: "Update Level",
    update_time_interval: "Update Time Intervals",
  },
  form: {
    select_option: "Select Option",
    update: "Update",
  },
};

export type TranslationSchema = typeof translations;

export default translations;
