<template>
  <NavigationComponent :activeTag="$t('app_features.project_settings')">
    <div class="h-screen overflow-hidden flex flex-col">
      <PageHeader>{{ $t("app_features.project_settings") }}</PageHeader>

      <div
        class="flex-1 flex border-t overflow-auto xl:overflow-hidden flex-col xl:flex-row pt-4 xl:pt-0"
      >
        <div class="xl:hidden md:px-8 px-4">
          <label for="tabs" class="sr-only">Select a tab</label>
          <select
            id="tabs"
            name="tabs"
            class="w-auto oai-inputs"
            @change="handleItemClick(($event?.target as HTMLInputElement)?.value as string)"
          >
            <option
              v-for="tab in tabs"
              :key="tab.name"
              :value="tab.name"
              :selected="currentTab.name === tab.name"
            >
              {{ tab.label }}
            </option>
          </select>
        </div>
        <div
          class="border-r w-[250px] min-h-screen bg-white hidden xl:block text-xs text-gray-600 font-medium"
        >
          <div
            :class="[
              'px-5 md:px-8 py-2 lg:p-5 border-l-4 cursor-pointer hover:text-oaiGray-900 border-b text-gray-600 font-medium text-xs flex items-center justify-between break-words',
              currentTab === tab ? 'border-l-yellow text-oaiGray-900 bg-gray-50' : '',
            ]"
            v-for="tab in tabs"
            :key="tab.name"
            @click="handleItemClick(tab)"
          >
            {{ tab.label }}
          </div>
          <a
            class="px-8 py-2 text-xs lg:p-5 cursor-pointer border-b border-l-4 font-medium flex items-center gap-1"
            v-if="hasPermission('pct_admin')"
            :href="`https://pct.oculai.de/project-console?customer_name=${currentCustomerName}&site_id=${currentSiteId}`"
          >
            <ArrowTopRightOnSquareIcon class="w-4 h-4" />
            Project Console
          </a>
        </div>

        <div class="flex-1 md:p-8 p-4 xl:overflow-auto">
          <component :is="currentTab.content" />
        </div>
      </div>
    </div>
  </NavigationComponent>
</template>

<script lang="ts" setup>
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline";
import type { Component } from "vue";
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import NavigationComponent from "@/components/layout/NavigationComponent.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import { useLoadCurrentProject } from "@/composables/project";
import { useTrackEvent } from "@/composables/tracking";
import TabDurations from "@/views/settings_project/components/TabDurations.vue";
import TabGeneral from "@/views/settings_project/components/TabGeneral.vue";
import TabParticipants from "@/views/settings_project/components/TabParticipants.vue";
import TabDailyReport from "./components/TabDailyReport.vue";

const { t } = useI18n();

type Tab = {
  name: string;
  label: string;
  content: Component;
};

const { project } = useLoadCurrentProject();
const trackEvent = useTrackEvent();

const tabs = computed(
  () =>
    [
      {
        name: "general",
        label: t("admin.project_settings.tab_general"),
        content: TabGeneral,
      },
      project.value?.features.includes("daily_report") && {
        name: "daily_report",
        label: t("admin.project_settings.tab_dcr"),
        content: TabDailyReport,
      },
      {
        name: "project_participants",
        label: t("admin.project_settings.tab_project_participants"),
        content: TabParticipants,
      },
      {
        name: "durations",
        label: t("admin.project_settings.tab_durations"),
        content: TabDurations,
      },
    ].filter((item) => item) as Tab[],
);

const router = useRouter();
const route = useRoute();

const currentTab = computed(() => {
  return tabs.value.find((tab) => tab.name === route.params.tab) || tabs.value[0];
});

const handleItemClick = (tab: string | Tab) => {
  // If tab is a string, use it directly; otherwise, use tab.name
  const tabName = typeof tab === "string" ? tab : tab.name;
  router.push({ params: { ...route.params, tab: tabName } });
};

onMounted(() => {
  trackEvent("settings_view");
});
</script>

<style scoped>
.settingsCard {
  @apply border border-gray-200 bg-white p-6 shadow sm:rounded-lg;
}
</style>
