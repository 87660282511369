import axios, { AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { Stream } from "oai-services";
import { apiClient } from "@/repositories/clients";

const mapStream = (stream: Stream<string>): Stream => ({
  ...stream,
  status_updated_at: stream.status_updated_at ? parseISO(stream.status_updated_at) : null,
});

const loadStreams = (customerName: string, siteId: string): Promise<Stream[]> =>
  apiClient
    .get<Stream<string>[]>(`/stream/${customerName}/${siteId}`)
    .then((response) => response.data.map((stream) => mapStream(stream)));

const loadLiveUrl = (
  customerName: string,
  siteId: string,
  cameraId: string,
): Promise<string | null> =>
  apiClient
    .get<{ url: string }>(`/stream/${customerName}/${siteId}/${cameraId}/live-url`)
    .then((response) => response.data.url)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const loadPublicLiveUrl = (
  customerName: string,
  siteId: string,
  cameraId: string,
  token: string,
): Promise<string | null> =>
  apiClient
    .get<{ url: string }>(`/stream/${token}/${customerName}/${siteId}/${cameraId}/live-url`)
    .then((response) => response.data.url)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const loadPlaybackUrl = (
  customerName: string,
  siteId: string,
  cameraId: string,
  startDate: Date,
  duration: number,
): Promise<string | null> =>
  apiClient
    .post<{ url: string }>(`/stream/${customerName}/${siteId}/${cameraId}/playback-url`, {
      start_date: startDate.toISOString().replace("Z", "+00:00"),
      duration,
    })
    .then((response) => response.data.url)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

export type StreamsToUpdateAsProjectAdmin = Pick<Stream, "_id" | "name">[];

const updateStreamsForProjectAdmin = (
  customerName: string,
  siteId: string,
  streamsToUpdate: StreamsToUpdateAsProjectAdmin,
): Promise<Stream[]> =>
  apiClient
    .patch<void, AxiosResponse<Stream<string>[]>>(
      `/stream/${customerName}/${siteId}/project-admin`,
      { streams: streamsToUpdate },
    )
    .then((response) => response.data.map((stream) => mapStream(stream)));

const loadLiveImage = (
  customerName: string,
  siteId: string,
  cameraId: string,
  abortSignal: AbortSignal,
): Promise<ArrayBuffer | null> =>
  apiClient
    .get<ArrayBuffer>(`/stream/${customerName}/${siteId}/${cameraId}/live-image`, {
      responseType: "arraybuffer",
      signal: abortSignal,
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const loadPublicLiveImage = (
  customerName: string,
  siteId: string,
  cameraId: string,
  token: string,
  abortSignal: AbortSignal,
): Promise<ArrayBuffer | null> =>
  apiClient
    .get<ArrayBuffer>(`/stream/${token}/${customerName}/${siteId}/${cameraId}/live-image`, {
      responseType: "arraybuffer",
      signal: abortSignal,
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const loadThumbnail = (thumbnailUrl: string) =>
  axios
    .get(thumbnailUrl, { responseType: "arraybuffer" })
    .then((response) => window.URL.createObjectURL(new Blob([response.data])));

export default {
  loadStreams,
  loadLiveUrl,
  loadPlaybackUrl,
  updateStreamsForProjectAdmin,
  loadPublicLiveUrl,
  loadLiveImage,
  loadPublicLiveImage,
  loadThumbnail,
};
