import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useCurrentCustomerName, useCurrentSiteId, useHasPermission } from "oai-services";
import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useHasProjectFeature } from "@/composables/project";
import { useCustomToast } from "@/composables/toast";
import ReportRepository from "@/repositories/ReportRepository";
import logger from "@/services/logger";
import {
  Report,
  ReportAggregation,
  ReportConfig,
  ReportFilters,
  ReportPlotType,
  ReportSummary,
  ReportToCreateOrUpdate,
} from "@/types/Report";
import { defaultFilters as queryValueDefaultFilters } from "@/views/reports/plots/query_value/queryValue";

export const useIsPlotTypeEnabled = () => {
  const workingHoursFeatureEnabled = useHasProjectFeature("working_hours");
  const workingHoursFeaturePlotTypes: ReportPlotType[] = ["working_hour_curve", "unit_values"];

  const hasPlannerPermission = useHasPermission("planner_user");
  const plannerPlotTypes: ReportPlotType[] = ["milestone"];

  return (plotType: ReportPlotType) => {
    if (workingHoursFeaturePlotTypes.includes(plotType) && !workingHoursFeatureEnabled) {
      return false;
    }
    if (plannerPlotTypes.includes(plotType) && !hasPlannerPermission) {
      return false;
    }
    return true;
  };
};

export const useReports = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const hasPermission = useHasPermission("processes_user");
  const isPlotTypeEnabled = useIsPlotTypeEnabled();

  const { data, isLoading, error } = useQuery<Report[]>({
    queryKey: ["reports", currentCustomerName, currentSiteId],
    queryFn: () =>
      hasPermission
        ? ReportRepository.loadReports(currentCustomerName, currentSiteId)
        : ([] as Report[]),
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const reports = computed(() =>
    (data.value || []).filter((report) => isPlotTypeEnabled(report.type)),
  );

  return { reports, isLoading, error };
};

export const useCreateReport = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: createReport, isLoading } = useMutation<
    Report,
    Error,
    ReportToCreateOrUpdate
  >({
    mutationFn: (payload) =>
      ReportRepository.createReport(currentCustomerName, currentSiteId, payload),
    onSuccess: (createdReport) => {
      queryClient.setQueryData(
        ["reports", currentCustomerName, currentSiteId],
        (reports: Report[] | undefined) => [...(reports || []), createdReport],
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.reports.update_report_error"));
      return false;
    },
  });

  return { createReport, isLoading };
};

export const defaultReportId = "default";

const defaultPlotConfigs: Record<
  ReportPlotType,
  (workingHoursFeatureEnabled: boolean) => ReportConfig
> = {
  cycle_times: (workingHoursFeatureEnabled) => ({
    show_planned: true,
    show_utilization: workingHoursFeatureEnabled,
    show_outages: true,
    show_active_days: true,
    show_inactive_days: true,
  }),
  working_hour_curve: () => ({
    end_date: undefined,
    show_mean: true,
  }),
  unit_values: () => ({}),
  milestone: () => ({
    mode: "sequence",
  }),
  query_value: () => ({
    metric: "working_hours",
    show_previous_period: true,
    formatting_rules: [],
  }),
};

export const defaultPlotFilters: Record<ReportPlotType, () => ReportFilters> = {
  cycle_times: () => ({
    location: {
      building: [],
      level: [],
      section: [],
    },
    processes: {
      mode: "component",
      components: [],
      single_processes: [],
    },
  }),
  working_hour_curve: () => ({
    location: {
      building: [],
      level: [],
      section: [],
    },
    processes: {
      mode: "component",
      components: [],
      single_processes: [],
    },
    daterange: {
      type: "last_month",
      start_date: null,
      end_date: null,
    },
  }),
  unit_values: () => ({
    location: {
      building: [],
      level: [],
      section: [],
    },
    unit_value_type: null,
  }),
  milestone: () => ({
    location: {
      building: [],
      level: [],
      section: [],
    },
  }),
  query_value: () => structuredClone(queryValueDefaultFilters),
};

const defaultPlotAggregations: Record<ReportPlotType, () => ReportAggregation> = {
  cycle_times: () => "section",
  working_hour_curve: () => null,
  unit_values: () => "section",
  milestone: () => null,
  query_value: () => "sum",
};

export const useCreateDefaultReport = () => {
  const { t } = useI18n();
  const { reports, isLoading } = useReports();
  const workingHoursFeatureEnabled = useHasProjectFeature("working_hours");
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const getReportName = (plotType: ReportPlotType) => {
    const reportName = t(`analytics.reports.${plotType}_plot`);

    const lastNumber = reports.value
      .filter((group) => group.name.startsWith(reportName))
      .map((group) => {
        const number = group.name.replace(reportName, "").trim();
        return number.length > 0 ? parseInt(number, 10) : 0;
      })
      .sort((a, b) => b - a)[0];

    if (lastNumber || lastNumber === 0) {
      return `${reportName} ${lastNumber + 1}`;
    }
    return reportName;
  };

  const createDefaultReport = (plotType: ReportPlotType) => {
    const report: Report = {
      _id: defaultReportId,
      customer_name: currentCustomerName,
      site_id: currentSiteId,
      name: getReportName(plotType),
      filters: defaultPlotFilters[plotType](),
      type: plotType,
      config: defaultPlotConfigs[plotType](workingHoursFeatureEnabled),
      aggregation: defaultPlotAggregations[plotType](),
    };
    return report;
  };

  return { createDefaultReport, isLoading };
};

export const useUpdateReport = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: updateReport, isLoading } = useMutation<
    Report,
    Error,
    ReportToCreateOrUpdate
  >({
    mutationFn: (payload) =>
      ReportRepository.updateReport(currentCustomerName, currentSiteId, payload),
    onSuccess: (updatedReport) => {
      queryClient.setQueryData(
        ["reports", currentCustomerName, currentSiteId],
        (reports: Report[] | undefined) =>
          reports?.map((report) => (report._id === updatedReport._id ? updatedReport : report)),
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.reports.update_report_error"));
      return false;
    },
  });

  return { updateReport, isLoading };
};

export const useDeleteReport = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: deleteReport, isLoading } = useMutation<void, Error, string>({
    mutationFn: (reportId) =>
      ReportRepository.deleteReport(currentCustomerName, currentSiteId, reportId),
    onSuccess: (result, reportId) => {
      queryClient.setQueryData(
        ["reports", currentCustomerName, currentSiteId],
        (reports: Report[] | undefined) => reports?.filter((report) => report._id !== reportId),
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.reports.delete_report_error"));
      return false;
    },
  });

  return { deleteReport, isLoading };
};

export const useReportSummary = (report: Ref<Report>) => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const { t } = useI18n();

  const reportWithoutName = computed(() => {
    const { name, ...rest } = report.value;
    return rest;
  });

  const plotType = computed(() => report.value.type);

  const { data, isLoading, isFetching } = useQuery<ReportSummary>({
    queryKey: ["report-summary", currentCustomerName, currentSiteId, plotType, reportWithoutName],
    queryFn: () =>
      ReportRepository.loadReportSummary(currentCustomerName, currentSiteId, report.value, {
        fresh: true,
      }),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.reports.report_summary_load_error"));
      return false;
    },
  });

  return { reportSummary: data, isLoading, isFetching };
};
