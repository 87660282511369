import { AxiosResponse } from "axios";
import { AxiosInstance } from "axios";
import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { OutagesByRange } from "../types/Camera";

export default (client: AxiosInstance) => {
  const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");
  const loadOutagesByRange = (
    customerName: string,
    siteId: string,
    startDate: Date,
    endDate: Date,
  ): Promise<OutagesByRange> =>
    client
      .get<void, AxiosResponse<OutagesByRange<string>>>(
        `/camera/outages-range/${customerName}/${siteId}/${format(
          startDate,
          "yyyy-MM-dd",
        )}/${format(endDate, "yyyy-MM-dd")}`,
      )
      .then((response) =>
        Object.entries(response.data).reduce((acc, [key, value]) => {
          acc[key] = value.map((item) => ({
            ...item,
            start_time: parseUtcDate(item.start_time),
            end_time: parseUtcDate(item.end_time),
            tml_start: parseUtcDate(item.tml_start),
            tml_end: parseUtcDate(item.tml_end),
          }));
          return acc;
        }, {} as OutagesByRange),
      );
  return {
    loadOutagesByRange,
  };
};
