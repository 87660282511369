import { User, UserEveryGroup, UserGroup, UserProjectPermissionGroup } from "oai-services";
import { defineComponent } from "vue";
import { Project } from "@/types/Project";

export default defineComponent({
  computed: {
    currentCustomerName(): string {
      return this.$route.params.customer_name as string;
    },
    currentSiteId(): string {
      return this.$route.params.site_id as string;
    },
    currentProjectV2(): Project | undefined {
      const customer_name = this.$route.params.customer_name as string;
      const site_id = this.$route.params.site_id as string;

      if (!customer_name || !site_id) {
        return undefined;
      }

      return (this.$store.state.projects as Project[] | undefined)?.find(
        (project) => project.customer_name === customer_name && project.site_id === site_id,
      );
    },
  },
  methods: {
    hasPermissionForUser(
      user: User,
      groups: UserEveryGroup | UserEveryGroup[] | (UserEveryGroup[] | UserEveryGroup)[],
      customerName?: string,
      siteId?: string,
    ) {
      const requiredGroups: UserEveryGroup[] | (UserEveryGroup[] | UserEveryGroup)[] =
        Array.isArray(groups) ? groups : [groups];

      if (
        requiredGroups.some((requiredGroup) =>
          Array.isArray(requiredGroup)
            ? requiredGroup.every((userGroup) => user.groups.includes(userGroup as UserGroup))
            : user.groups.includes(requiredGroup as UserGroup),
        )
      ) {
        return true;
      }

      customerName = customerName || this.currentCustomerName;
      siteId = siteId || this.currentSiteId;

      if (customerName && siteId) {
        const project = user.projects.find(
          (project) => project.customer_name === customerName && project.site_id === siteId,
        );

        if (
          requiredGroups.some((requiredGroup) =>
            Array.isArray(requiredGroup)
              ? requiredGroup.every((UserProjectPermissionGroup) =>
                  project?.groups.includes(
                    UserProjectPermissionGroup as UserProjectPermissionGroup,
                  ),
                )
              : project?.groups.includes(requiredGroup as UserProjectPermissionGroup),
          )
        ) {
          return true;
        }
      }

      return false;
    },
    hasPermission(
      groups: UserEveryGroup | UserEveryGroup[] | (UserEveryGroup[] | UserEveryGroup)[],
      customerName?: string,
      siteId?: string,
    ) {
      const user = this.$store.state.user as User | undefined;
      if (!user) {
        return false;
      }

      return this.hasPermissionForUser(user, groups, customerName, siteId);
    },
  },
});
