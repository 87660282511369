<template>
  <div class="px-0.5 py-1 truncate text-md text-center border-r font-semibold cursor-default">
    <OaiTooltip simple position="top">
      <p>
        {{ formattedSums.duration }}
      </p>
      <template #tooltip>
        <p class="text-xs">
          {{ `${$t("analytics.reports.query_value_aggregations.sum")}: ${formattedSums.duration}` }}
        </p>
      </template>
    </OaiTooltip>
  </div>
  <div class="px-0.5 py-1 truncate text-md text-center border-r font-semibold cursor-default">
    <OaiTooltip simple position="top">
      <div>
        {{ formattedSums.workforce }}
      </div>
      <template #tooltip>
        <div class="text-xs">
          {{
            `${$t("analytics.reports.query_value_aggregations.average")}: ${
              formattedSums.workforce
            }`
          }}
        </div>
      </template>
    </OaiTooltip>
  </div>
  <div
    class="px-0.5 py-1 truncate text-md text-center border-r font-semibold cursor-default"
    :title="`${$t('analytics.reports.query_value_aggregations.sum')}: ${formattedSums.quantity}`"
  >
    <OaiTooltip position="top">
      {{ formattedSums.quantity }}
      <template #tooltip>
        <div class="text-xs">
          {{ `${$t("analytics.reports.query_value_aggregations.sum")}: ${formattedSums.quantity}` }}
        </div>
      </template>
    </OaiTooltip>
  </div>
  <div
    class="px-0.5 py-1 truncate text-md text-center bg-gray-100 font-semibold cursor-default"
    :class="{
      'border-r border-r-gray-600': showLastRightBorder,
    }"
  >
    <OaiTooltip position="top">
      {{ formattedSums.value }}
      <template #tooltip>
        <div class="text-xs">
          {{ `${$t("analytics.reports.query_value_aggregations.median")}: ${formattedSums.value}` }}
        </div>
      </template>
    </OaiTooltip>
  </div>
</template>

<script setup lang="ts">
import { OaiTooltip } from "oai-components";
import { computed, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { UnitValue, UnitValueType } from "@/types/UnitValue";
import {
  calculateDurationSum,
  calculateQuantitySum,
  calculateUnitValueSum,
  calculateWorkforceSum,
  createFormatNumber,
} from "@/views/unit_values/services/unitValues";

const formatNumber = createFormatNumber(useI18n().locale.value);

const props = defineProps({
  unitValues: {
    type: Array as PropType<UnitValue[]>,
    required: true,
  },
  type: {
    type: String as PropType<UnitValueType>,
    required: true,
  },
  showLastRightBorder: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
});

const formattedSums = computed(() => ({
  duration: formatNumber(calculateDurationSum(props.unitValues, props.type)) ?? "",
  workforce: formatNumber(calculateWorkforceSum(props.unitValues, props.type)) ?? "",
  quantity: formatNumber(calculateQuantitySum(props.unitValues, props.type)) ?? "",
  value: formatNumber(calculateUnitValueSum(props.unitValues, props.type)) ?? "",
}));
</script>
