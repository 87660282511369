import { EventModel, SchedulerPro } from "@bryntum/schedulerpro";
import { AxiosInstance } from "axios";
import { addDays, differenceInHours, eachDayOfInterval, format, parse } from "date-fns";
import { computed, Ref, ref } from "vue";
import CameraRepository from "../repositories/CameraRepository";
import PlannerRepository from "../repositories/PlannerRepository";
import { ProjectDurationSettings } from "../types/ProjectDurationSettings";
import { Stream } from "../types/Stream";
import {
  useIsWorkingDay,
  useNonWorkingDaysByDaySet,
  useTodayMinusOneWorkingDay,
} from "./durations";
import { useCurrentCustomerName, useCurrentSiteId } from "./project";
import { useProjectDurationSettings } from "./projectDurationSettings";

export const useHolidaysInScheduler = (client: AxiosInstance) => {
  const { projectDurationSettings } = useProjectDurationSettings(client);

  return (schedulerPro: SchedulerPro) => {
    const { timeRangeStore } = schedulerPro.project;

    timeRangeStore.remove(
      timeRangeStore.allRecords.filter(
        (item) => typeof item.id === "string" && item.id.startsWith("holiday_"),
      ),
    );

    if (projectDurationSettings.value) {
      const items = projectDurationSettings.value.non_working_days.map((non_working_day) => {
        const id = `holiday_${
          non_working_day.name
        }_${non_working_day.start_date.toISOString()}_${non_working_day.end_date.toISOString()}`;

        let className = `planner-holiday-timerange planner-holiday-timerange-type-${non_working_day.type}`;

        if (non_working_day.is_critical) {
          className += " planner-holiday-timerange-critical";
        }

        return {
          id: id,
          name: non_working_day.name,
          startDate: non_working_day.start_date,
          endDate: addDays(non_working_day.end_date, 1),
          cls: className,
        };
      });
      timeRangeStore.add(items);
    }
  };
};

export const useNonWorkingDaysInScheduler = (client: AxiosInstance) => {
  const { projectDurationSettings } = useProjectDurationSettings(client);

  return (schedulerPro: SchedulerPro) => {
    const { timeRangeStore } = schedulerPro.project;

    timeRangeStore.remove(
      timeRangeStore.allRecords.filter(
        (item) => typeof item.id === "string" && item.id.startsWith("non_working_day_"),
      ),
    );

    if (projectDurationSettings.value) {
      const dayMap: Record<keyof ProjectDurationSettings["working_hours"], string> = {
        Mon: "MO",
        Tue: "TU",
        Wed: "WE",
        Thu: "TH",
        Fri: "FR",
        Sat: "SA",
        Sun: "SU",
      };
      const items = Object.entries(projectDurationSettings.value.working_hours)
        .filter(([, value]) => !value.start_time && !value.end_time)
        .map(([key]) => ({
          id: `non_working_day_${key}`,
          recurrenceRule: `FREQ=WEEKLY;BYDAY=${
            dayMap[key as keyof ProjectDurationSettings["working_hours"]]
          };`,
          startDate: "1970-01-04 00:00",
          endDate: "1970-01-05 00:00",
        }));
      timeRangeStore.add(items);
    }
  };
};

const getEarliestStartAndLatestEnd = (events: EventModel[]) => {
  let start: Date | null = null;
  let end: Date | null = null;
  for (const event of events) {
    const eventStart = event.startDate as Date;
    const eventEnd = event.endDate as Date;
    if (!start || eventStart < start) {
      start = eventStart;
    }
    if (!end || eventEnd > end) {
      end = eventEnd;
    }
  }
  return [start, end] as const;
};

export const useOutages = (
  client: AxiosInstance,
  streams: Ref<Stream[]>,
  isProjectCompleted: boolean,
) => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const nonWorkingDaysByDaySet = useNonWorkingDaysByDaySet(client);
  const isWorkingDay = useIsWorkingDay(client);
  const todayMinusOneWorkingDay = useTodayMinusOneWorkingDay(client);

  const streamsByCameraId: Ref<Record<string, Stream>> = computed(() =>
    streams.value.reduce((acc: Record<string, Stream>, stream: Stream) => {
      acc[stream.camera_id.toString()] = stream;
      return acc;
    }, {}),
  );

  const openedEventIds = ref(new Set<string>());

  return (schedulerPro: SchedulerPro, event: EventModel) => {
    const { resourceTimeRangeStore, eventStore } = schedulerPro.project;

    const startDate = event.startDate as Date;
    const endDate = event.endDate as Date;
    const formattedEndDate = format(endDate, "yyyy-MM-dd");
    const resourceId = event.resourceId as string;
    const fullEventId = `${schedulerPro.id}_${event.id}`;

    if (openedEventIds.value.has(fullEventId)) {
      const currentOutageTimeRanges = resourceTimeRangeStore.allRecords.filter(
        (item) =>
          typeof item.id === "string" &&
          item.id.startsWith("outage_") &&
          item.getData("resourceId") === resourceId,
      );
      resourceTimeRangeStore.remove(currentOutageTimeRanges);
      event.resource.set("getOutagesFn", null);
      openedEventIds.value.delete(fullEventId);
      return;
    }

    openedEventIds.value.add(fullEventId);

    const loadOutagesEndDate = isProjectCompleted ? endDate : todayMinusOneWorkingDay.value;
    Promise.all([
      CameraRepository(client).loadOutagesByRange(
        currentCustomerName,
        currentSiteId,
        startDate,
        loadOutagesEndDate,
      ),
      PlannerRepository(client).loadResourceCameraIds(
        currentCustomerName,
        currentSiteId,
        event.resource.getData("sourceId"),
      ),
    ])
      .then(([outages, cameraIds]) => {
        if (!openedEventIds.value.has(fullEventId)) {
          return;
        }
        event.resource.set("getOutagesFn", (date: Date) => {
          if (!isWorkingDay.value(date)) {
            return [];
          }
          const key = format(date, "yyyy-MM-dd");
          const result = (outages[key] || [])
            .filter((item) => cameraIds.includes(item.camera_id))
            .map((item) => {
              const stream = streamsByCameraId.value[item.camera_id];
              return {
                ...item,
                camera_name: stream?.name || item.camera_id,
                aws_stream_id: stream?.aws_stream_id,
              };
            })
            .filter(
              (item) => (key > formattedEndDate && item.aws_stream_id) || key <= formattedEndDate,
            );
          result.sort(
            (a, b) =>
              a.camera_name.localeCompare(b.camera_name) ||
              a.start_time.getTime() - b.start_time.getTime(),
          );
          return result;
        });
        const processEventsByDay = (eventStore.allRecords as EventModel[])
          .filter((record) => record.getData("type")?.startsWith("process_"))
          .reduce((acc, event) => {
            eachDayOfInterval({
              start: event.startDate as Date,
              end: event.endDate as Date,
            }).forEach((day) => {
              const key = format(day, "yyyy-MM-dd");
              if (!(key in acc)) {
                acc[key] = [];
              }
              acc[key].push(event);
            });
            return acc;
          }, {} as Record<string, EventModel[]>);
        const items = Object.entries(outages)
          .map(([key, value]) => [key, value, parse(key, "yyyy-MM-dd", new Date())] as const)
          .filter(([key, value, date]) => {
            if (nonWorkingDaysByDaySet.value?.has(key) || !isWorkingDay.value(date)) {
              return false;
            }
            return value.some(
              (entry: {
                end_time: number | Date;
                start_time: number | Date;
                tml_end: number | Date;
                tml_start: number | Date;
                camera_id: string;
              }) => {
                const outageHours = differenceInHours(entry.end_time, entry.start_time);
                const tmlHours = differenceInHours(entry.tml_end, entry.tml_start);
                const factor = 0.5;
                const stream = streamsByCameraId.value[entry.camera_id];
                return (
                  cameraIds.includes(entry.camera_id) &&
                  outageHours > tmlHours * factor &&
                  ((key > formattedEndDate && stream?.aws_stream_id) || key <= formattedEndDate)
                );
              },
            );
          })
          .flatMap(([key, value, date]) => {
            const processEvents = processEventsByDay[key] || [];
            if (processEvents.length === 0) {
              return [
                {
                  id: `outage_${resourceId}_${key}`,
                  startDate: date,
                  endDate: addDays(date, 1),
                  resourceId,
                  cls: "planner-outage-timerange",
                },
              ];
            }
            const [startDate, endDate] = getEarliestStartAndLatestEnd(processEvents);
            if (!startDate || !endDate) {
              return [];
            }
            const hasBeforeStart = value
              .filter((entry: { camera_id: string }) => cameraIds.includes(entry.camera_id))
              .some((entry: { start_time: Date }) => entry.start_time < startDate);
            const hasAfterEnd = value
              .filter((entry: { camera_id: string }) => cameraIds.includes(entry.camera_id))
              .some((entry: { end_time: Date }) => entry.end_time > endDate);
            return [
              hasBeforeStart && {
                id: `outage_${resourceId}_${key}_start`,
                startDate: date,
                endDate: startDate,
                resourceId,
                cls: "planner-outage-timerange",
              },
              hasAfterEnd && {
                id: `outage_${resourceId}_${key}_end`,
                startDate: endDate,
                endDate: addDays(date, 1),
                resourceId,
                cls: "planner-outage-timerange",
              },
            ].filter((item) => item);
          });
        resourceTimeRangeStore.add(items);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };
};
