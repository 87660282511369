<template>
  <ModalTW :open="true" @close="emit('close')" customCls="w-full m-2 lg:w-1/3">
    <template #title>
      <div class="truncate flex gap-1 text-sm items-center justify-center font-semibold">
        <div
          :key="tag._id"
          v-for="tag in tags"
          :class="[
            'px-2 py-0.5 rounded-md truncate',
            HIERARCHY_TAGS_META[tag.type].bgLight,
            HIERARCHY_TAGS_META[tag.type].text,
          ]"
          :title="tag.name"
        >
          {{ tag.name }}
        </div>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col gap-4">
        <div class="flex gap-4 text-xs mx-9 text-gray-600 self-end -mb-3">
          <div
            class="hover:underline cursor-pointer"
            @click="currentProcessIndex = processes.length - 1"
          >
            {{ t("analytics.planner.mark_as_done_modal.go_to_last_process") }}
          </div>
          <div
            class="hover:underline cursor-pointer"
            v-if="lastConcretingProcessIndex !== -1"
            @click="goToLastConcretingProcess"
          >
            {{ t("analytics.planner.mark_as_done_modal.go_to_last_concreting") }}
          </div>
        </div>
        <div class="flex-1 flex gap-2 items-center" v-if="currentProcess">
          <div class="w-7 h-7 shrink-0">
            <ChevronLeftIcon
              class="w-full h-full cursor-pointer"
              @click="currentProcessIndex -= 1"
              v-if="currentProcessIndex > 0"
            />
          </div>
          <div class="flex-1 flex flex-col gap-1">
            <OculaiVideoPlayer :loading="isProcessVideoUrlLoading" :src="processVideoUrl" />
            <div class="text-xs flex gap-1 truncate">
              <div class="truncate flex-1">
                {{ currentProcessIndex + 1 }}/{{ processes.length }}:
                {{ t(`process_classes.${currentProcess.encoded_label}`) }}
              </div>
              <div class="flex gap-2">
                {{ formatProcessDate(currentProcess.date) }}
                {{ formatIsoHourMinute(currentProcess.start_time) }}-{{
                  formatIsoHourMinute(currentProcess.end_time)
                }}
              </div>
            </div>
          </div>
          <div class="w-7 h-7 shrink-0">
            <ChevronRightIcon
              class="w-full h-full cursor-pointer"
              @click="currentProcessIndex += 1"
              v-if="currentProcessIndex < processes.length - 1"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 mt-2">
          <div class="mx-9 text-sm font-semibold">
            {{ t("analytics.planner.mark_as_done_modal.date_label") }}
          </div>
          <VueDatePicker
            class="w-[150px] mx-9"
            input-class-name="text-sm"
            :locale="locale"
            v-model="selectedDate"
            :enable-time-picker="false"
            format="dd.MM.yyyy"
            auto-apply
            reverse-years
            :clearable="false"
          />
        </div>
        <button
          type="button"
          :disabled="isLoading"
          @click="setActualEventDate"
          class="mx-9 whitespace-nowrap self-end w-min inline-flex justify-center items-center gap-2 rounded-md border border-transparent bg-yellow-500 px-3 py-1 text-sm text-white focus:outline-none hover:bg-yellow-600 disabled:bg-gray-300"
        >
          {{ t("analytics.planner.mark_as_done_modal.mark_as_done_button") }}
          <LoadingSpinner class="w-4 h-4" v-if="isLoading" />
        </button>
      </div>
    </template>
  </ModalTW>
</template>
<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import VueDatePicker from "@vuepic/vue-datepicker";
import { format, parse, set } from "date-fns";
import {
  ActualEventChanges,
  EncodedLabel,
  useCurrentCustomerName,
  useCurrentSiteId,
} from "oai-services";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import OculaiVideoPlayer from "@/components/other/OculaiVideoPlayer.vue";
import { useCreateSelfResolvedIssueReport } from "@/composables/issueReport";
import { useUpdateActualEvents } from "@/composables/planner";
import { useProcessClasses, useProcessVideoUrl } from "@/composables/process";
import { useTrackEvent } from "@/composables/tracking";
import { HIERARCHY_TAGS_META } from "@/constants/hierarchyTags";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { NewIssueReport } from "@/types/IssueReport";
import { ShortenedProcess } from "@/types/Process";

const props = defineProps<{
  actualEventId: string;
  start: Date;
  processes: ShortenedProcess[];
  tags: HierarchyTagStore[];
}>();
const emit = defineEmits<{ (eventName: "close"): void }>();

const { t, locale } = useI18n();
const trackEvent = useTrackEvent();

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();

const processClasses = useProcessClasses();

const lastConcretingProcessIndex = computed(() => {
  const concretingEncodedLabels = new Set(
    processClasses.value
      .filter((processClass) => processClass.processElement === "concrete")
      .map((processClass) => processClass.encodedLabel),
  );
  const index = props.processes
    .slice()
    .reverse()
    .findIndex((process) => concretingEncodedLabels.has(process.encoded_label as EncodedLabel));
  if (index === -1) {
    return -1;
  }
  return props.processes.length - 1 - index;
});

const getInitialProcessIndex = () =>
  lastConcretingProcessIndex.value !== -1
    ? lastConcretingProcessIndex.value
    : props.processes.length - 1;

const currentProcessIndex = ref<number>(getInitialProcessIndex());

const currentProcess = computed(() => props.processes[currentProcessIndex.value]);

const currentProcessId = computed(() => currentProcess.value?._id);

const getInitialSelectedDate = () =>
  currentProcess.value ? currentProcess.value.start_time : new Date();

const selectedDate = ref(getInitialSelectedDate());

const { updateActualEvents, isLoading: isUpdateActualEventsLoading } = useUpdateActualEvents();

const { createSelfResolvedIssueReport, isLoading: isCreateSelfResolvedIssueReportLoading } =
  useCreateSelfResolvedIssueReport();

const isLoading = computed(
  () => isUpdateActualEventsLoading.value || isCreateSelfResolvedIssueReportLoading.value,
);

const { processVideoUrl, isLoading: isProcessVideoUrlLoading } =
  useProcessVideoUrl(currentProcessId);

const formatProcessDate = (dateText: string) =>
  format(parse(dateText, "yyyy-MM-dd", new Date()), "dd.MM.yyyy");

const formatIsoHourMinute = (date: Date) => format(date, "HH:mm");

const setActualEventDate = () => {
  const changes: ActualEventChanges = {
    added: [],
    modified: [
      {
        _id: props.actualEventId,
        start: props.start,
        end: set(selectedDate.value, { hours: 17, minutes: 0, milliseconds: 0 }),
      },
    ],
    removed: [],
  };
  const issue: NewIssueReport = {
    type: "planner",
    entry_id: props.actualEventId,
    report_message: `Actual event ${props.tags
      .map((tag) => tag.name)
      .join("/")} marked as ended for ${currentCustomerName}/${currentSiteId}`,
    resolve_message: "",
  };
  updateActualEvents(changes)
    .then(() => createSelfResolvedIssueReport(issue))
    .then(() => {
      emit("close");
    })
    .catch(() => undefined)
    .finally(() => {
      trackEvent("progress_mark-completed_apply");
    });
};

const goToLastConcretingProcess = () => {
  if (lastConcretingProcessIndex.value === -1) {
    return;
  }
  currentProcessIndex.value = lastConcretingProcessIndex.value;
};

watch([() => props.processes, currentProcess], () => {
  selectedDate.value = getInitialSelectedDate();
});

watch(
  () => props.processes,
  () => {
    currentProcessIndex.value = props.processes.length - 1;
  },
);
</script>
