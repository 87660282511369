import { ProcessElement } from "oai-services";

export const processElementColor: Record<ProcessElement | "total", string> = {
  concrete: "#c32f00",
  reinforce: "#f28123",
  support: "#f7f052",
  prefab: "#0295cd",
  sheath: "#0a210f",
  brickwork: "#564787",
  safety: "#84B082",
  transport: "#76818E",
  total: "",
};
