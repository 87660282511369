<template>
  <NavigationComponent :activeTag="$t('app_features.period')">
    <CameraItemLayout>
      <template #contentLeft>
        <div class="mt-4">
          <OculaiVideoPlayer
            :loading="loading"
            :src="videoUrl"
            :noSrcMessage="error ? $t('err.no_streaming_found') : $t('camera.period.info_text')"
            :hls="true"
          />
        </div>
      </template>

      <template #contentRight>
        <Form
          @submit="queryStreamingUrl"
          class="space-y-3 sm:space-y-5 divide-y divide-gray-200"
          :validationSchema="schema"
        >
          <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div class="space-y-4 sm:space-y-3">
              <div class="flex items-center">
                <h3 class="text-md 2xl:text-lg leading-6 font-medium text-gray-900 mr-2">
                  {{ $t("camera.period.playback_period") }}
                </h3>
                <OaiTooltip simple position="top">
                  <InformationCircleIcon class="h-5 text-green-500" />
                  <template #tooltip>
                    <div class="text-xs">
                      {{ $t("camera.period.info_text") }}
                    </div>
                  </template>
                </OaiTooltip>
              </div>
              <div class="divide-y space-y-3 sm:space-y-5">
                <div class="pt-5">
                  <DateField
                    name="startTimestampDate"
                    :label="$t('camera.period.date')"
                    :minDate="minDate"
                    :maxDate="maxDate"
                    :inlineField="true"
                  />
                </div>
                <div class="pt-5">
                  <TimeField
                    name="startTimestampTime"
                    :label="$t('camera.period.time')"
                    :inlineField="true"
                  />
                </div>
                <div
                  class="sm:grid sm:grid-cols-3 sm:gap-4 items-center sm:border-t sm:border-gray-200 pt-5"
                >
                  <label for="duration" class="flex flex-col text-sm font-medium text-gray-700">
                    {{ $t("camera.period.duration") }}
                    <span class="max-w-2xl text-xs text-gray-400">
                      {{ $t("camera.period.in_minutes") }}
                    </span>
                  </label>
                  <div class="sm:col-span-2 mt-2 sm:mt-0">
                    <select
                      id="duration"
                      v-model="duration"
                      :disabled="loading"
                      class="oai-inputs max-w-lg sm:max-w-xs"
                    >
                      <option>15</option>
                      <option>30</option>
                      <option>45</option>
                      <option>60</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex">
              <MainButton
                :label="$t('camera.period.play')"
                type="submit"
                color="yellow"
                class="mx-0 sm:mr-0 mt-0"
              >
                <LoadingSpinner v-if="loading" size="w-5 h-5" color="white" class="mr-2" />
              </MainButton>
            </div>
          </div>
        </Form>
      </template>
    </CameraItemLayout>
  </NavigationComponent>
</template>

<script setup lang="ts">
import { InformationCircleIcon } from "@heroicons/vue/24/solid";
import { format, parse, subDays } from "date-fns";
import { OaiTooltip } from "oai-components";
import { useCurrentCustomerName, useCurrentSiteId } from "oai-services";
import { Form } from "vee-validate";
import { ref, computed, onMounted, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import * as yup from "yup";
import DateField from "@/components/forms/DateField.vue";
import TimeField from "@/components/forms/TimeField.vue";
import NavigationComponent from "@/components/layout/NavigationComponent.vue";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import MainButton from "@/components/other/MainButton.vue";
import OculaiVideoPlayer from "@/components/other/OculaiVideoPlayer.vue";
import { useCustomToast } from "@/composables/toast";
import { useTrackEvent } from "@/composables/tracking";
import StreamRepository from "@/repositories/StreamRepository";
import logger from "@/services/logger";
import CameraItemLayout from "@/views/camera/components/CameraItemLayout.vue";

const trackEvent = useTrackEvent();

onMounted(() => {
  trackEvent("camera_period_view");
});

const duration = ref("15") as Ref<string>;
const loading = ref(false) as Ref<boolean>;
const error = ref(false) as Ref<boolean>;
const videoUrl = ref(null) as Ref<string | null>;
const toast = useCustomToast();
const { t } = useI18n();
const route = useRoute();
const customer = useCurrentCustomerName();
const site = useCurrentSiteId();

const schema = computed(() =>
  yup.object({
    startTimestampDate: yup
      .date()
      .nullable()
      .required(t("err.required"))
      .max(
        maxDate.value,
        t("err.err_future_date", { maxDate: format(maxDate.value, "yyyy-MM-dd") }),
      ),
    startTimestampTime: yup
      .string()
      .test("is-valid-time", t("err.inputs.time_wrong_format"), (value: string | undefined) => {
        if (typeof value === "undefined") {
          return false;
        }

        const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        return timeRegex.test(value);
      })
      .required(t("err.required")),
  }),
);

const minDate = computed(() => {
  return subDays(new Date(), 7);
});

const maxDate = computed(() => {
  return new Date();
});

const queryStreamingUrl = async (values: Record<string, number | Date>) => {
  loading.value = true;
  const startDate = format(values.startTimestampDate, "yyyy-MM-dd");
  const startTime = values.startTimestampTime;
  const startDateTime = parse(`${startDate} ${startTime}`, "yyyy-MM-dd HH:mm", new Date());
  StreamRepository.loadPlaybackUrl(
    customer,
    site,
    route.params.camera_id as string,
    startDateTime,
    parseInt(duration.value),
  )
    .then((url) => {
      videoUrl.value = url;
    })
    .catch((err) => {
      error.value = true;
      logger.error(err);
    })
    .finally(() => {
      loading.value = false;
      if (!videoUrl.value) {
        error.value = true;
        toast.warning(t("err.no_streaming_found"));
      }
    });
};
</script>
