<template>
  <div
    ref="containerRef"
    class="relative flex flex-col"
    :style="{
      height: `${height}px`,
      padding: `${spacingY}px ${spacingX}px`,
      backgroundColor: plotColor?.backgroundColor,
    }"
  >
    <div
      class="text-center pl-6 pr-8 truncate shrink-0"
      :class="{ 'mb-1': !isOnTheDashboard }"
      :style="{ color: plotColor?.textColor || '#111827' }"
    >
      <RouterLink
        class="text-lg font-semibold"
        :to="{
          name: 'Reports',
          query: {
            report: reportId,
          },
        }"
        @click.prevent="emit('widgetRouteClicked')"
      >
        {{ title }}
      </RouterLink>
    </div>
    <ReportFilterBadges
      type="query_value"
      :filters="filters"
      :aggregationLabel="
        config.metric !== 'project_progress'
          ? t(`analytics.reports.query_value_aggregations.${aggregation}`)
          : undefined
      "
      :reportSummary="data"
      :class="{ oaiQueryValuePlotReportFilterBadgesOnTheDashboard: isOnTheDashboard }"
      :style="{ zIndex: 1 }"
      :hideDateFilter="!filterKeysByMetric[config.metric].includes('daterange')"
      :hideNonEmptyFilters="config.metric === 'unit_value'"
      :showPreviousPeriodBadge="config.show_previous_period"
    >
      <ArrowTopRightOnSquareIcon
        v-if="config.metric in linkCreators"
        class="w-5 h-5 cursor-pointer text-gray-600 shrink-0 mt-[1px]"
        @click="handleOpenInNewTabClick"
      />
      <CalendarDaysIcon
        v-if="!(config.metric in linkCreators) && processes && processes.length > 0"
        class="w-5 h-5 cursor-pointer text-gray-600 shrink-0"
        :style="{ color: plotColor?.textColor }"
        @click="
          isWorkingDaysCalendarModalOpen = true;
          trackEvent('activity-calendar_view', { origin: 'query_value_plot' });
        "
      />
      <CalendarDaysIcon
        v-if="!(config.metric in linkCreators) && planConfig"
        class="w-5 h-5 cursor-pointer text-gray-600 shrink-0"
        :style="{ color: plotColor?.textColor }"
        @click="
          isProjectProgressModalOpen = true;
          trackEvent('progress_detail_click', { origin: 'query_value_progress' });
        "
      />
    </ReportFilterBadges>
    <QueryValuePlotValue
      :key="valueRefKey"
      v-if="data && singleValueData"
      :data="singleValueData"
      :filters="filters"
      :config="config"
      :textColor="plotColor?.textColor"
      :showFullUnit="!width || width > 450"
      class="flex-1"
    />
    <ModalTW
      v-if="isWorkingDaysCalendarModalOpen"
      :open="true"
      @close="isWorkingDaysCalendarModalOpen = false"
      custom-cls="overflow-hidden"
    >
      <template #content>
        <WorkingDaysCalendar v-if="processes" :processes="processes" :interval="dateInterval" />
      </template>
    </ModalTW>
    <ProjectProgressModal
      v-if="isProjectProgressModalOpen && planConfig && planProcesses"
      :planConfig="planConfig"
      :processes="planProcesses"
      @close="isProjectProgressModalOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ArrowTopRightOnSquareIcon, CalendarDaysIcon } from "@heroicons/vue/24/solid";
import { parse } from "date-fns";
import { useProjectDurationSettings } from "oai-services";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import ModalTW from "@/components/modals/ModalTW.vue";
import WorkingDaysCalendar from "@/components/working_days/WorkingDaysCalendar.vue";
import { useHierarchyTags } from "@/composables/hierarchyTags";
import { useOutagesByRange } from "@/composables/outages";
import { useProcessClasses } from "@/composables/process";
import { useHasProjectFeature } from "@/composables/project";
import { useDebouncedBoundingClientRect } from "@/composables/screen";
import { useTrackEvent } from "@/composables/tracking";
import { apiClient } from "@/repositories/clients";
import { DashboardGridSize } from "@/types/Dashboard";
import {
  isQueryValueReportSummaryPlanAndProcessesValue,
  isQueryValueReportSummaryProcessesValue,
  isQueryValueReportSummarySingleValue,
  QueryValueAggregation,
  QueryValueFormattingRuleAction,
  QueryValueMetric,
  QueryValueReportConfig,
  QueryValueReportFilters,
  QueryValueReportSummary,
} from "@/types/reports/PlotQueryValue";
import ReportFilterBadges from "@/views/reports/filters/ReportFilterBadges.vue";
import ProjectProgressModal from "@/views/reports/plots/query_value/ProjectProgressModal.vue";
import QueryValuePlotValue from "@/views/reports/plots/query_value/QueryValuePlotValue.vue";
import {
  calculatePlanValueMetric,
  calculateProcessesValueMetric,
  createProcessTableLinkQuery,
  filterKeysByMetric,
  findMatchingFormattingRule,
} from "@/views/reports/plots/query_value/queryValue";

const defaultFullHeight = 500;

const props = withDefaults(
  defineProps<{
    data?: QueryValueReportSummary;
    reportId: string;
    filters: QueryValueReportFilters;
    config: QueryValueReportConfig;
    aggregation: QueryValueAggregation;
    title: string;
    hidePopover?: boolean;
    width?: number;
    height?: number;
    hideLegend?: boolean;
    spacingX?: number;
    spacingY?: number;
    dashboardGridSize?: DashboardGridSize;
    isOnTheDashboard?: boolean;
  }>(),
  { height: defaultFullHeight, spacingX: 0, spacingY: 0 },
);

const emit = defineEmits(["updateUseWhiteIcons", "widgetRouteClicked"]);

const { t, locale } = useI18n();

const containerRef = ref<HTMLDivElement | null>(null);
const isWorkingDaysCalendarModalOpen = ref(false);
const isProjectProgressModalOpen = ref(false);

const rect = useDebouncedBoundingClientRect(containerRef, 16);
const trackEvent = useTrackEvent();
const { projectDurationSettings } = useProjectDurationSettings(apiClient);

const { hierarchyTags } = useHierarchyTags();

const router = useRouter();

const outagesByRangeInterval = computed(() => {
  if (props.data && isQueryValueReportSummaryProcessesValue(props.data)) {
    const start = props.data.context.previous_period_start_date || props.data.context.start_date;
    if (start && props.data.context.end_date) {
      return {
        start,
        end: props.data.context.end_date,
      };
    }
    const date = [...new Set(props.data.processes.map((process) => process.date))].sort()[0];
    if (date) {
      return {
        start: parse(date, "yyyy-MM-dd", new Date()),
        end: new Date(),
      };
    }
  }
  return null;
});

const { outagesByRange, isFetching: isOutagesByRangeFetching } =
  useOutagesByRange(outagesByRangeInterval);

const processClasses = useProcessClasses();

const hasWorkingHoursFeature = useHasProjectFeature("working_hours");

const singleValueData = computed(() => {
  if (!props.data) {
    return undefined;
  }

  const type = props.data.type;

  if (isQueryValueReportSummarySingleValue(props.data)) {
    return props.data;
  }

  if (isQueryValueReportSummaryProcessesValue(props.data)) {
    return calculateProcessesValueMetric(
      locale.value,
      props.config.metric,
      props.data,
      projectDurationSettings.value,
      isOutagesByRangeFetching.value ? undefined : outagesByRange.value,
      processClasses.value,
      { hasWorkingHoursFeature },
    );
  }

  if (isQueryValueReportSummaryPlanAndProcessesValue(props.data)) {
    return calculatePlanValueMetric(
      props.config.metric,
      props.data,
      projectDurationSettings.value,
      hierarchyTags.value,
    );
  }

  throw new Error(`Unsupported query value report summary type "${type}"`);
});

const processes = computed(() => {
  if (!props.data || !isQueryValueReportSummaryProcessesValue(props.data)) {
    return undefined;
  }
  return props.data.processes;
});

const planProcesses = computed(() => {
  if (!props.data || !isQueryValueReportSummaryPlanAndProcessesValue(props.data)) {
    return undefined;
  }
  return props.data.processes;
});

const planConfig = computed(() => {
  if (!props.data || !isQueryValueReportSummaryPlanAndProcessesValue(props.data)) {
    return undefined;
  }
  return props.data.plan;
});

const dateInterval: Ref<[Date, Date] | undefined> = computed(() => {
  if (!props.data || !props.data.context.start_date || !props.data.context.end_date) {
    return undefined;
  }
  return [props.data.context.start_date, props.data.context.end_date];
});

const valueRefKey = computed(() =>
  JSON.stringify({
    rect: rect.value,
    data: singleValueData.value,
    config: props.config,
  }),
);

const formattingRuleActions: Record<
  QueryValueFormattingRuleAction,
  { backgroundColor: string; textColor: string }
> = {
  red_background: { backgroundColor: "#eb374c", textColor: "#fff" },
  yellow_background: { backgroundColor: "#f99d01", textColor: "#fff" },
  green_background: { backgroundColor: "#41c564", textColor: "#fff" },
};

const plotColor = computed(() => {
  if (!singleValueData.value) {
    return undefined;
  }
  const matchingRule = findMatchingFormattingRule(
    props.config.formatting_rules,
    singleValueData.value,
  );
  if (!matchingRule) {
    return undefined;
  }
  return formattingRuleActions[matchingRule.action];
});

const updateUseWhiteIcons = () => {
  emit("updateUseWhiteIcons", !!plotColor.value);
};

const linkCreators: Partial<Record<QueryValueMetric, () => string | null>> = {
  working_hours: () =>
    router.resolve({
      name: "ProcessesTable",
      query: createProcessTableLinkQuery(props.filters, singleValueData.value, hierarchyTags.value),
    }).href,
  unit_value: () => router.resolve({ name: "UnitValuesEditor" }).href,
};

const handleOpenInNewTabClick = () => {
  const linkCreator = linkCreators[props.config.metric];
  const link = linkCreator && linkCreator();
  if (link) {
    window.open(link, "_blank");
  }
};

watch(plotColor, () => {
  updateUseWhiteIcons();
});

onMounted(() => {
  updateUseWhiteIcons();
});
</script>

<style>
.oaiQueryValuePlotReportFilterBadgesOnTheDashboard {
  display: none !important;
  position: absolute;
  top: 45px;
  left: 18px;
  right: 18px;
}

.vgl-item--resizable:hover .oaiQueryValuePlotReportFilterBadgesOnTheDashboard {
  display: flex !important;
}
</style>
