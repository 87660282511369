<template>
  <div
    :class="isFakeFullScreen ? 'fixed inset-0 bg-oaiGray-900' : ''"
    @click="isFakeFullScreen = false"
    :style="isFakeFullScreen ? 'z-index: 2147483004' : ''"
  >
    <div
      class="bg-oaiGray-900 video-js vjs-live vjs-4-3"
      :class="{
        'vjs-waiting': loading,
        'vjs-user-active': isUserActive,
        'vjs-user-inactive': !isUserActive,
        'vjs-has-started': imageUrl,
        'vjs-playing': isPlaying,
        'vjs-paused': !isPlaying,
        'vjs-fullscreen': isFullScreen || isFakeFullScreen,
        'vjs-controls-enabled': showControls,
      }"
      @mouseenter="
        stopUserActiveInitialTimer();
        isUserActive = true;
      "
      @mouseleave="isUserActive = false"
      @mousemove="isUserActive = true"
      ref="containerRef"
    >
      <div class="image-content select-none" :style="full4to3RatioDimensionStyle">
        <img
          v-if="imageUrl"
          :src="imageUrl"
          alt="live image"
          class="h-full"
          @click="handleImageClick"
          @dblclick="handleFullScreenClick"
          @click.stop
        />
      </div>
      <div
        v-if="hasError"
        class="error-content"
        :style="`${full4to3RatioDimensionStyle}; height: auto;`"
      >
        <div
          class="rounded-md bg-yellow-400/80 p-4 text-center text-xs sm:text-sm text-gray-50 flex-1 m-5"
        >
          {{ noSrcMessage || $t("video_player.unable_to_play_message") }}
        </div>
      </div>
      <div class="vjs-loading-spinner" v-if="loading" />
      <div
        class="vjs-control-bar"
        :class="{ 'pr-5': addRightSpaceToControlBar }"
        v-if="showControls"
        @click.stop
      >
        <button
          class="vjs-play-control vjs-control vjs-button"
          :class="{
            'vjs-playing': isPlaying,
            'vjs-paused': !isPlaying,
          }"
          type="button"
          :title="isPlaying ? 'Pause' : 'Play'"
          aria-disabled="false"
          @click="handlePlayClick"
        >
          <span class="vjs-icon-placeholder" aria-hidden="true"></span
          ><span class="vjs-control-text" aria-live="polite">Play</span>
        </button>
        <div class="vjs-live-control vjs-control">
          <div class="vjs-live-display" aria-live="off">
            <span class="vjs-control-text">Stream Type&nbsp;</span>LIVE
          </div>
        </div>
        <div class="flex items-center cursor-pointer" @click="loadLiveImage()">
          <ArrowPathIcon class="w-4 h-4 text-white" />
        </div>
        <button
          class="vjs-fullscreen-control vjs-control vjs-button"
          type="button"
          :title="isFullScreen ? 'Non-Fullscreen' : 'Fullscreen'"
          aria-disabled="false"
          @click="handleFullScreenClick"
        >
          <span class="vjs-icon-placeholder" aria-hidden="true"></span
          ><span class="vjs-control-text" aria-live="polite">Fullscreen</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowPathIcon } from "@heroicons/vue/24/outline";
import mobile from "is-mobile";
import { useCurrentCustomerName, useCurrentSiteId } from "oai-services";
import screenfull from "screenfull";
import { computed, onMounted, onUnmounted, PropType, ref } from "vue";
import { useRoute } from "vue-router";
import {
  useFullScreen4to3RatioDimensionStyle,
  useFullWindow4to3RatioDimensionStyle,
} from "@/composables/screen";
import StreamRepository from "@/repositories/StreamRepository";
import logger from "@/services/logger";

const imageUrl = ref<string | null>(null);
const loading = ref(false);
const hasError = ref(false);
const isUserActive = ref(true);
const isPlaying = ref(false);
const isFullScreen = ref(false);
const isFakeFullScreen = ref(false);
const containerRef = ref<HTMLDivElement | null>(null);
const userActiveInitialTimer = ref<ReturnType<typeof setTimeout> | null>(null);
const refreshTimer = ref<ReturnType<typeof setInterval> | null>(null);
const abortController = ref<AbortController | null>(null);

const isMobileDevice = mobile();
const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();
const fullScreen4to3RatioDimensionStyle = useFullScreen4to3RatioDimensionStyle();
const fullWindow4to3RatioDimensionStyle = useFullWindow4to3RatioDimensionStyle();
const route = useRoute();

const props = defineProps({
  cameraId: {
    type: String,
    required: true,
  },
  showControls: {
    type: Boolean,
    required: false,
    default: true,
  },
  isPublic: {
    type: Boolean,
    required: false,
  },
  noSrcMessage: {
    type: String as PropType<string | null>,
    required: false,
  },
  addRightSpaceToControlBar: {
    type: Boolean,
    required: false,
  },
});

const full4to3RatioDimensionStyle = computed(() => {
  if (isFakeFullScreen.value) {
    return fullWindow4to3RatioDimensionStyle.value;
  }
  if (isFullScreen.value) {
    return fullScreen4to3RatioDimensionStyle.value;
  }
  return "";
});

const loadLiveImage = () => {
  if (loading.value) {
    return;
  }
  loading.value = true;
  hasError.value = false;

  abortController.value = new AbortController();

  const token = route.params.token as string;

  const promise = props.isPublic
    ? StreamRepository.loadPublicLiveImage(
        currentCustomerName,
        currentSiteId,
        props.cameraId,
        token,
        abortController.value.signal,
      )
    : StreamRepository.loadLiveImage(
        currentCustomerName,
        currentSiteId,
        props.cameraId,
        abortController.value.signal,
      );

  promise
    .then((imageArrayBuffer) => {
      if (imageArrayBuffer) {
        if (imageUrl.value) {
          window.URL.revokeObjectURL(imageUrl.value);
        }
        const blob = new Blob([imageArrayBuffer], { type: "image/jpeg" });
        imageUrl.value = window.URL.createObjectURL(blob);
        startUserActiveInitialTimer();
      } else {
        hasError.value = true;
      }
    })
    .catch((error) => {
      hasError.value = true;
      logger.error(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const handleImageClick = () => {
  if (!props.showControls) {
    return;
  }
  if (isPlaying.value) {
    stopPlay();
  } else {
    startPlay({ dontReload: true });
  }
};

const handlePlayClick = () => {
  if (isPlaying.value) {
    stopPlay();
  } else {
    startPlay();
  }
};

const handleFullScreenClick = () => {
  if (!screenfull.isEnabled) {
    isFakeFullScreen.value = !isFakeFullScreen.value;
    return;
  }
  if (screenfull.isFullscreen) {
    screenfull.exit();
  } else if (containerRef.value) {
    screenfull.request(containerRef.value, { navigationUI: "hide" });
  }
};

const handleFullscreenChange = () => {
  isFullScreen.value = screenfull.isFullscreen;
};

const startUserActiveInitialTimer = () => {
  if (userActiveInitialTimer.value === null && isPlaying.value) {
    userActiveInitialTimer.value = setTimeout(() => {
      isUserActive.value = false;
    }, 3000);
  }
};

const stopUserActiveInitialTimer = () => {
  if (userActiveInitialTimer.value !== null) {
    clearTimeout(userActiveInitialTimer.value);
  }
};

const startPlay = (options?: { dontReload: boolean }) => {
  isPlaying.value = true;
  if (!options?.dontReload) {
    loadLiveImage();
  }
  refreshTimer.value = setInterval(() => {
    loadLiveImage();
  }, 60 * 1000);
};

const stopPlay = () => {
  isPlaying.value = false;
  if (refreshTimer.value !== null) {
    clearInterval(refreshTimer.value);
  }
};

onMounted(() => {
  if (screenfull.isEnabled) {
    screenfull.on("change", handleFullscreenChange);
  }
  if (isMobileDevice) {
    loadLiveImage();
  } else {
    startPlay();
  }
});

onUnmounted(() => {
  if (screenfull.isEnabled) {
    screenfull.off("change", handleFullscreenChange);
  }
  stopUserActiveInitialTimer();
  stopPlay();
  if (abortController.value && loading.value) {
    abortController.value.abort();
  }
});
</script>

<style scoped>
.image-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /*
    This is not a perfect solution, as it won't work for different aspect ratios (e.g. kauricab/002).
    But the good thing is, that the image never overflows the container; in the worse case, it might be a bit zoomed in.
  */
  aspect-ratio: 4/3;
}

.error-content {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  width: 100%;
}
</style>
