<template>
  <div class="border text-gray-900 bg-white shadow flex flex-col relative" style="width: 420px">
    <div class="flex-1 flex flex-col overflow-auto px-4 pt-6 pb-3">
      <div class="mb-4">
        <p class="text-md font-medium mb-2">
          {{ processes.length }}
          {{ t("analytics.processes.sidebar.processes_selected", processes.length) }}
        </p>

        <p
          v-if="processes.length"
          class="text-right plannedBg rounded text-gray-600 w-min px-1.5 py-0.5 text-sm whitespace-nowrap"
        >
          {{ startDate }} - {{ endDate }}
        </p>
      </div>
      <div>
        <div
          :class="[
            !isAnalysisAccordionOpen && 'border-b pb-3',
            'pt-3 flex justify-between cursor-pointer',
          ]"
          @click="toggleAnalysisAccordion"
        >
          <span class="indent-0.5">{{ t("analytics.processes.sidebar.analysis") }}</span>

          <ChevronLeftIcon
            :class="[
              'h-5 w-5 transition-[transform] duration-200',
              isAnalysisAccordionOpen ? 'transform -rotate-90' : '',
            ]"
          />
        </div>
        <div
          ref="analysisAccordionContent"
          class="overflow-hidden transition-[height] duration-200"
        >
          <div class="pt-4 overflow-x-auto">
            <table v-if="processes.length">
              <thead>
                <tr
                  class="pr-4 bg-gray-50 text-xs font-medium text-gray-500 border-b border-gray-200 text-left"
                >
                  <th class="p-3"></th>
                  <th class="p-3">{{ t("analytics.processes.sidebar.active_total_days") }}</th>
                  <th class="p-3">{{ t("analytics.processes.sidebar.productive_days") }}</th>
                  <th class="p-3" v-if="postAnalyzedData.at(-1)?.outageDays">
                    {{ t("analytics.processes.sidebar.outage_days") }}
                  </th>
                  <th class="p-3">{{ t("analytics.processes.sidebar.working_hours") }}</th>
                </tr>
              </thead>

              <tbody class="text-xs">
                <tr
                  v-for="group in postAnalyzedData"
                  :key="group.name"
                  class="border-b border-gray-200"
                >
                  <td class="relative flex">
                    <div
                      class="w-1"
                      :style="`background: ${processElementColor[group.name]}`"
                    ></div>

                    <div class="px-3 py-2 whitespace-nowrap">
                      {{
                        group.name === ("total" as string)
                          ? t("analytics.processes.sidebar.total")
                          : t(`process_classes.${group.name}`)
                      }}
                    </div>
                  </td>
                  <td class="px-3 py-2 whitespace-nowrap">
                    {{ group.activeDays }}/{{ group.totalDays }} {{ t("time.day", 2) }}
                  </td>
                  <td class="px-3 py-2 whitespace-nowrap">
                    {{ group.productiveDays }} {{ t("time.day", group.productiveDays) }}
                  </td>
                  <td
                    class="px-3 py-2 whitespace-nowrap"
                    v-if="postAnalyzedData.at(-1)?.outageDays"
                  >
                    {{
                      (group.name as string) === "total"
                        ? ""
                        : `${group.outageDays} ${t("time.day", group.outageDays)}`
                    }}
                  </td>

                  <td
                    :class="['px-3 py-2 whitespace-nowrap', !hasWorkingHoursFeature && 'blur-sm']"
                  >
                    {{ group.workingHours }} {{ t("time.hour", group.workingHours) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div :class="{ 'border-b pb-3': isMediaAccordionOpen }">
        <div
          :class="[
            !isMediaAccordionOpen && 'border-b pb-3',
            'pt-3 flex justify-between cursor-pointer',
          ]"
          @click="toggleMediaAccordion"
        >
          <span class="indent-0.5">{{ t("analytics.processes.sidebar.before_after") }}</span>

          <ChevronLeftIcon
            :class="[
              'h-5 w-5 transition-[transform] duration-200',
              isMediaAccordionOpen ? 'transform -rotate-90' : '',
            ]"
          />
        </div>
        <div
          v-if="isMediaAccordionOpen"
          ref="mediaAccordionContent"
          class="overflow-hidden transition-[height] duration-200 h-0'"
        >
          <div class="pt-4">
            <div v-for="media in beforeAfterMedia" :key="media.cameraId" class="mb-4">
              <p class="text-sm indent-0.5">{{ media.cameraName }}</p>
              <ImagesComparison
                :initialBeforeDate="media.start"
                :initialAfterDate="media.end"
                :camera="media.cameraId"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        class="flex items-center gap-1 mt-4 hover:underline"
        @click="
          openWorkingDaysCalendar = true;
          trackEvent('activity-calendar_view', { origin: 'process_selector' });
        "
        v-if="processes.length"
      >
        <CalendarDaysIcon class="w-4 text-yellow-600" />
        <span class="text-sm">{{ $t("analytics.processes.show_activity_calendar") }}</span>
      </button>
    </div>

    <XMarkIcon
      class="h-5 w-5 absolute cursor-pointer top-3 right-4 text-gray-600"
      @click="emit('close')"
    />
  </div>
  <ModalTW
    :open="openWorkingDaysCalendar"
    @close="openWorkingDaysCalendar = false"
    custom-cls="overflow-hidden"
    v-if="openWorkingDaysCalendar"
    ><template #title>
      <div class="text-left border-b pb-3 text-ellipsis">
        {{ processes.length }}
        {{ t("analytics.processes.sidebar.processes_selected", processes.length) }}
      </div></template
    >
    <template #content v-if="processes">
      <WorkingDaysCalendar :processes="processes" />
    </template>
  </ModalTW>
</template>

<script setup lang="ts">
import { XMarkIcon, ChevronLeftIcon } from "@heroicons/vue/24/outline";
import { CalendarDaysIcon } from "@heroicons/vue/24/solid";
import { format } from "date-fns";
import {
  durationService,
  useProjectDurationSettings,
  useIsWorkingDay,
  useNonWorkingDaysByDaySet,
  ProcessElement,
  DurationSettings,
} from "oai-services";
import { PropType, computed, ref, watch } from "vue";
import { nextTick } from "vue";
import { useI18n } from "vue-i18n";
import ModalTW from "@/components/modals/ModalTW.vue";
import ImagesComparison from "@/components/other/ImagesComparison.vue";
import WorkingDaysCalendar from "@/components/working_days/WorkingDaysCalendar.vue";
import { useOutagesByRange } from "@/composables/outages";
import { useProcessClasses } from "@/composables/process";
import { useHasProjectFeature } from "@/composables/project";
import { useStreams } from "@/composables/stream";
import { useTrackEvent } from "@/composables/tracking";
import { processElementColor } from "@/constants/processClasses";
import { apiClient } from "@/repositories/clients";
import processDurationService from "@/services/processDurationService";
import { ShortenedProcess } from "@/types/Process";

type AnalyzedGroup = {
  name: ProcessElement | "total";
  activeDays: number;
  totalDays: number;
  productiveDays: number;
  outageDays: number;
  workingHours: number;
};

type BeforeAfterMedia = {
  cameraId: string;
  cameraName: string;
  start: string;
  end: string;
};

const emit = defineEmits(["close"]);

const props = defineProps({
  processes: {
    type: Array as PropType<ShortenedProcess[]>,
    required: true,
  },
  start: {
    type: Date,
    required: true,
  },
  end: {
    type: Date,
    required: true,
  },
});

const { streams } = useStreams();
const { projectDurationSettings } = useProjectDurationSettings(apiClient);
const nonWorkingDaysByDaySet = useNonWorkingDaysByDaySet(apiClient);
const isWorkingDay = useIsWorkingDay(apiClient);
const { outagesByRange } = useOutagesByRange(props.start, props.end);
const hasWorkingHoursFeature = useHasProjectFeature("working_hours");
const { t } = useI18n();
const processClasses = useProcessClasses();
const trackEvent = useTrackEvent();

const openWorkingDaysCalendar = ref(false);

const durationSettings = computed<DurationSettings | null>(() =>
  projectDurationSettings.value
    ? durationService.calculateSettings(
        projectDurationSettings.value.working_hours,
        projectDurationSettings.value.non_working_days,
      )
    : null,
);

const outages = computed(() => {
  if (!nonWorkingDaysByDaySet.value) {
    return outagesByRange.value;
  }

  return processDurationService.useCorrectOutages(
    outagesByRange.value,
    streams.value,
    nonWorkingDaysByDaySet.value,
    isWorkingDay.value,
  );
});

const startDate = ref("");
const endDate = ref("");
const postAnalyzedData = ref<AnalyzedGroup[]>([]);
const beforeAfterMedia = ref<BeforeAfterMedia[]>([]);
const isAnalysisAccordionOpen = ref(true);
const isMediaAccordionOpen = ref(false);
const analysisAccordionContent = ref<HTMLDivElement | null>(null);
const mediaAccordionContent = ref<HTMLDivElement | null>(null);

const toggleAnalysisAccordion = () => {
  isAnalysisAccordionOpen.value = !isAnalysisAccordionOpen.value;

  if (isAnalysisAccordionOpen.value) {
    const height = analysisAccordionContent.value?.children?.[0]?.scrollHeight;
    analysisAccordionContent.value?.style.setProperty("height", `${height}px`);
  } else {
    analysisAccordionContent.value?.style.setProperty("height", "0px");
  }
};

const toggleMediaAccordion = () => {
  isMediaAccordionOpen.value = !isMediaAccordionOpen.value;

  if (isMediaAccordionOpen.value) {
    const height = mediaAccordionContent.value?.children?.[0]?.scrollHeight;
    mediaAccordionContent.value?.style.setProperty("height", `${height}px`);
  } else {
    mediaAccordionContent.value?.style.setProperty("height", "0px");
  }
};

const setAccordionHeight = () => {
  if (isAnalysisAccordionOpen.value) {
    const height = analysisAccordionContent.value?.children?.[0]?.scrollHeight;
    analysisAccordionContent.value?.style.setProperty("height", `${height}px`);
  }

  if (isMediaAccordionOpen.value) {
    const height = mediaAccordionContent.value?.children?.[0]?.scrollHeight;
    mediaAccordionContent.value?.style.setProperty("height", `${height}px`);
  }
};

const calculateProcessesAnalysis = (processes: ShortenedProcess[]) => {
  nextTick(setAccordionHeight);

  if (!processes.length || !durationSettings.value) {
    return;
  }

  postAnalyzedData.value = processDurationService.calculateProcessesAnalysis(
    processes,
    processClasses.value,
    projectDurationSettings.value,
    outages.value,
    { hasWorkingHoursFeature },
  );

  const sortedProcesses = processes.slice().sort((a, b) => {
    if (a.start_time.getTime() === b.start_time.getTime()) {
      return a.end_time.getTime() - b.end_time.getTime();
    } else {
      return a.start_time.getTime() - b.start_time.getTime();
    }
  });

  const start = sortedProcesses[0].start_time;
  const end = sortedProcesses.at(-1)?.end_time as Date;

  startDate.value = format(start, "dd.MM.yyyy");
  endDate.value = format(end, "dd.MM.yyyy");

  const firstAndLastProcessHaveSameCamera = processes[0].camera_id === processes.at(-1)?.camera_id;

  const firstCameraId = processes[0].camera_id;
  const firstCameraName = streams.value.find((stream) => stream.camera_id === firstCameraId)?.name;
  if (firstAndLastProcessHaveSameCamera) {
    beforeAfterMedia.value = [
      {
        cameraId: firstCameraId,
        cameraName: firstCameraName || "",
        start: format(start, "yyyy-MM-dd"),
        end: format(end, "yyyy-MM-dd"),
      },
    ];
  } else {
    const lastCameraId = processes.at(-1)?.camera_id;
    const lastCameraName = streams.value.find((stream) => stream.camera_id === lastCameraId)?.name;
    const lastProcessWithFirstCamera = processes
      .slice()
      .reverse()
      .find((process) => process.camera_id === firstCameraId);
    const firstProcessWithLastCamera = processes.find(
      (process) => process.camera_id === lastCameraId,
    );

    beforeAfterMedia.value = [
      {
        cameraId: firstCameraId,
        cameraName: firstCameraName || "",
        start: format(start, "yyyy-MM-dd"),
        end: format(lastProcessWithFirstCamera?.end_time || start, "yyyy-MM-dd"),
      },
      {
        cameraId: lastCameraId as string,
        cameraName: lastCameraName || "",
        start: format(firstProcessWithLastCamera?.start_time || end, "yyyy-MM-dd"),
        end: format(end, "yyyy-MM-dd"),
      },
    ];
  }
};

watch(
  () => props.processes,
  (processes) => calculateProcessesAnalysis(processes),
  { immediate: true },
);
</script>
