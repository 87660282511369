import { ProcessClass } from "oai-services";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { UnitValue, UnitValueTag, UnitValueType } from "@/types/UnitValue";
import { getProcessTypeByUnitValueType } from "@/views/unit_values/services/unitValueTypes";

const getTagsNames = (tags: UnitValueTag[], hierarchyTags: HierarchyTagStore[]) => {
  const hierarchyTagsById = hierarchyTags.reduce((acc, tag) => {
    acc[tag._id] = tag;
    return acc;
  }, {} as Record<string, HierarchyTagStore>);

  const getName = (id: string | null | undefined) => (id ? hierarchyTagsById[id]?.name : undefined);

  return {
    building: tags.map((tag) => getName(tag?.building_id)).filter((name) => name),
    level: tags.map((tag) => getName(tag?.level_id)).filter((name) => name),
    section: tags.map((tag) => getName(tag?.section_id)).filter((name) => name),
  };
};

export const getProcesses = (type: UnitValueType, processClasses: ProcessClass[]) => {
  const processClassByDecodedLabel = processClasses.reduce((acc, item) => {
    acc[item.decodedLabel] = item;
    return acc;
  }, {} as Record<string, ProcessClass>);

  const processTypes = getProcessTypeByUnitValueType(type);

  const processes = processTypes.map(
    (processType) => processClassByDecodedLabel[processType].encodedLabel,
  );

  const processElements = processTypes.map(
    (processType) => processClassByDecodedLabel[processType].processElement,
  );

  return { processes, process_types: [...new Set(processElements)] };
};

export const createLink = (
  unitValue: UnitValue,
  hierarchyTags: HierarchyTagStore[],
  processClasses: ProcessClass[],
) => {
  const location = getTagsNames(unitValue.tags, hierarchyTags);
  const processesFull = getProcesses(unitValue.type, processClasses);

  const joinToString = <T>(items: T[]) =>
    [...new Set(items.map((item) => item))].join(",") || undefined;

  return {
    name: "ProcessesTable",
    query: {
      processes: joinToString(processesFull.processes),
      processTypes: joinToString(processesFull.process_types),
      building: joinToString(location.building),
      level: joinToString(location.level),
      section: joinToString(location.section),
    },
  };
};
