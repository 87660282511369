<template>
  <div class="h-full relative">
    <slot name="header" />
    <div
      class="shadow flex flex-col max-h-full overflow-x-scroll h-full rounded-lg"
      @scroll="detectHorizontalScroll"
    >
      <div class="pointer-events-none z-10 sticky w-max top-0" :style="{ minWidth: width + 'px' }">
        <ul class="flex pointer-events-auto bg-gray-50">
          <li
            v-for="(column, index) in columns"
            :ref="'h' + column.field"
            :key="index"
            :class="[
              `p-3 text-left text-xs font-medium  w-max text-gray-500 border-b border-gray-200`,
              lastSorted === column && 'border-yellow-500',
              column.class,
            ]"
            :style="{
                width: colWidth[column.field as ColNames] + 'px',
                flex: '0 0 ' + colWidth[column.field as ColNames] + 'px',
              }"
          >
            <div class="flex justify-between group gap-1relative">
              <OaiTooltip position="right" v-if="hideWorkingHours(column)">
                <LockClosedIcon class="h-4 w-4" />
                <template #tooltip>
                  <div class="text-sm">
                    {{ t("paywall.working_hours") }}
                  </div>
                </template>
              </OaiTooltip>
              <OaiTooltip simple>
                <div class="overflow-hidden min-w-2 text-ellipsis">
                  <span class="uppercase whitespace-nowrap">{{ column.label }}</span>
                </div>
                <template #tooltip>
                  <div class="text-xs">
                    {{ `${column.label}\n${column?.tooltip || ""}` }}
                  </div>
                </template></OaiTooltip
              >
              <div
                :class="[
                  'flex items-center justify-end gap-2 relative',
                  { 'h-4': !column.filterDisabled && column.field !== 'column.field' },
                ]"
              >
                <div
                  v-if="!column.filterDisabled && column.field !== 'process_ids'"
                  :id="`filterContainer_${column.field}`"
                >
                  <button
                    @click="toggleFilter(column)"
                    type="button"
                    :tabindex="column.field"
                    class="relative"
                    :id="`filterButton_${column.field}`"
                  >
                    <span class="text-gray-400 hover:text-yellow-500">
                      <FunnelIcon
                        class="m-0 block h-4 w-4"
                        v-if="
                          !filters[column.field] ||
                          (Array.isArray(filters[column.field]) &&
                            !filters[column.field]?.length) ||
                          (checkEmptyLocationFilter && column.field === 'location') ||
                          (checkEmptyProcessFilter && column.field === 'process')
                        "
                      />
                      <span v-else class="inline-flex gap-1">
                        <span
                          @click.stop="removeFilter(column)"
                          class="absolute top-0 right-full text-gray-500 text-xs hover:underline bg-gray-50 px-1.5"
                        >
                          {{ $t("buttons.clear") }}
                        </span>
                        <FunnelIconSolid class="m-0 h-4 w-4 text-yellow-400" />
                      </span>
                    </span>
                  </button>
                  <OnClickOutside
                    :options="{
                      ignore: [`#filterButton_${column.field}`],
                    }"
                    @trigger="toggleFilter(column, false)"
                    v-if="column.showFilter"
                  >
                    <div
                      @mouseover.stop
                      class="absolute top-[100%] z-20 mt-2 min-w-[14rem] rounded-md bg-white shadow-lg"
                    >
                      <div v-if="!column.filters?.length">
                        <input
                          v-model="column.filterValue"
                          type="text"
                          class="block rounded-md border-gray-300 shadow-sm focus:ring-0 p-3 py-2 text-sm"
                          placeholder="Filter"
                          @input="
                            handleDebouncedFilter(($event.target as HTMLInputElement).value, column)
                          "
                          @click="$event.stopPropagation()"
                        />
                      </div>
                      <template v-else v-for="(filter, i) in column.filters" :key="i">
                        <div v-if="filter.type === 'text'">
                          <input
                            v-model="filter.filterValue"
                            type="text"
                            class="block rounded-md border-gray-300 shadow-sm focus:ring-0 px-3 py-2 text-sm"
                            :placeholder="filter.placeholder"
                            @input="
                              handleDebouncedCustomFilter(
                                ($event.target as HTMLInputElement).value,
                                column,
                                filter.filterFn,
                              )
                            "
                          />
                        </div>
                        <div v-if="filter.type === 'date'" class="fixed" :style="filterStyles">
                          <VueDatePicker
                            v-model="filter.filterValue as [Date, Date]"
                            :columns="6"
                            :enable-time-picker="false"
                            :placeholder="`${t('report.bulk_export.date_range_placeholder')}`"
                            :locale="locale"
                            format="dd.MM.yyyy"
                            auto-apply
                            range
                            @update:model-value="
                              handleDebouncedCustomFilter($event, column, filter.filterFn)
                            "
                            static
                            class="PopoverPanelContent"
                          />
                        </div>
                        <div
                          v-if="filter.type === 'process'"
                          class="fixed px-4"
                          :style="filterStyles"
                          @mouseover.stop
                        >
                          <ProcessType
                            multiple
                            @update:selected="
                              handleProcessFilter(
                                $event,
                                filter.filterValue as string[],
                                column as Column,
                              )
                            "
                            :selected="filter.filterValue as (typeof ProcessType)['selected']"
                            :groups="[
                              {
                                name: t('analytics.processes.process_group'),
                                field: 'processTypes',
                                options: processTypes
                                  .filter((type) => type)
                                  .map((type) => ({
                                    value: type,
                                    name: t(`process_classes.${type}`),
                                  })),
                              },
                              {
                                name: t('analytics.processes.processes'),
                                field: 'processes',
                                options: filterSelectValues[column.field].sort((a, b) =>
                                  a.name.localeCompare(b.name),
                                ),
                              },
                            ]"
                            :dependencies="{
                                        processTypes: {
                                          to: 'processes',
                                          defineDependencies: (options: string[], value: string) => {
                                            return options.filter((item) => {
                                              return processIdMap[item]?.processElement === value;
                                            });
                                          },
                                        },
                                  }"
                            placeholder="analytics.processes.placeholder_process_type"
                            defaultOpen
                          />
                        </div>

                        <div
                          v-if="filter.type === 'location'"
                          class="fixed px-4"
                          :style="filterStyles"
                        >
                          <LocationFilter
                            class="mt-2 lg:mt-0 text-left"
                            @update:selected="
                              handleDebouncedCustomFilter($event, column, filter.filterFn)
                            "
                            :selected="filter.filterValue as Record<string, string[]>"
                            :groups="tagGroups"
                            placeholder="analytics.processes.placeholder_hierarchy_tag"
                            defaultOpen
                          />
                        </div>
                      </template>
                    </div>
                  </OnClickOutside>
                </div>
                <button
                  v-if="!column.sortingDisabled && !hideWorkingHours(column)"
                  type="button"
                  :class="[
                    'text-gray-400 hover:text-yellow-500 leading-none self-center',
                    lastSorted === column && 'text-yellow-500',
                  ]"
                  @click="
                    handleSort(column);
                    trackEvent('activity-log_sort_apply', { column: column?.field });
                  "
                >
                  <ChevronDownIcon
                    :class="[
                      'm-0 block w-4 transition ease-in-out duration-150 transform rotate-0',
                      column.sortDirection === 'desc' && 'rotate-180',
                    ]"
                  />
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div
        class="bg-white h-full overflow-y-auto relative overflow-x-hidden flex-1 w-max mt-[-45px]"
        @scroll="detectTableScroll"
        ref="tableContent"
      >
        <div class="h-[45px]"></div>
        <div
          v-for="(row, rowIndex) in displayedData"
          :key="rowIndex"
          :style="{ minWidth: width + 'px' }"
        >
          <ul :class="['flex items-stretch', row.expanded && 'bg-gray-100']">
            <li
              :class="[
                `px-3 py-2 whitespace-nowrap border-t relative w-max border-solid border-t-gray-200 group-hover:bg-yellow-200`,
                (onRowClick || onRowDbClick) && 'cursor-pointer',
                row.class,
                column.field === 'process_ids' && 'hidden',
              ]"
              :style="{
                width: colWidth[column.field as ColNames] + 'px',
                flex: '0 0 ' + colWidth[column.field as ColNames] + 'px',
                minHeight: '45px'
              }"
              @click="onRowClick?.(row)"
              @dblclick="onRowDbClick?.(row)"
              v-for="(column, columnIndex) in columns"
              :key="columnIndex"
              :id="row?._id ? `${row._id}` : undefined"
            >
              <div
                :class="[
                  'absolute bg-orange-600  -ml-3 py-0.5 px-2.5 text-white rounded-full text-xs',
                  { 'z-10': rowIndex === 0 && !tableIsScrolling && !anyFilterOpen },
                ]"
                v-if="column.field === 'process' && hasChangeOfKw(row['date'], rowIndex)"
                style="top: -11px"
              >
                <small
                  >{{ t("analytics.planner.calendar_week_label") }}
                  {{ getWeek(row["date"] as Date) }}</small
                >
              </div>
              <div class="flex h-full items-center">
                <div
                  class="text-xs font-medium text-gray-900 flex items-center justify-between w-full overflow-hidden truncate"
                >
                  <component
                    v-if="column.renderComponent"
                    :is="column.renderComponent"
                    :data="row[column.field]"
                    :field="column.field"
                  />
                  <span
                    v-else-if="column.render"
                    :style="{
                      filter: hideWorkingHours(column) ? 'blur(3px)' : '',
                      'user-select': hideWorkingHours(column) ? 'none' : 'auto',
                    }"
                    >{{ getFromRenderCache(column.render, row[column.field]) }}
                  </span>
                  <span class="overflow-hidden text-ellipsis" v-else>{{ row[column.field] }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div
          class="px-3 py-2 border-t w-full border-solid border-t-gray-200 group-hover:bg-yellow-200 font-semibold"
          v-if="countPages * 100 < data.length"
        >
          <button
            @click="countPages++"
            class="flex text-xs items-center hover:text-white hover:bg-orange bg-gray-100 px-3 py-1 rounded-md leading-none border"
          >
            <ArrowDownIcon class="h-3 pr-1" />{{ $t("buttons.load_more") }}
          </button>
        </div>
        <template v-if="!anyOpenFilterComponent">
          <TableResizeSlider
            v-if="isThisSliderDraggingOrNone('process')"
            :width="sliderWidth"
            :containerRef="getContainerRef"
            :currentX="colWidth.process"
            @moved="handleProcessSliderMoved"
            @started="draggingSliderName = 'process'"
            @ended="draggingSliderName = null"
            :tableContentHeight="tableContent?.scrollHeight"
          />
          <TableResizeSlider
            v-if="isThisSliderDraggingOrNone('location')"
            :width="sliderWidth"
            :containerRef="getContainerRef"
            :currentX="colWidth.process + colWidth.location"
            @moved="handleLocationSliderMoved"
            @started="draggingSliderName = 'location'"
            @ended="draggingSliderName = null"
            :tableContentHeight="tableContent?.scrollHeight"
          />
          <TableResizeSlider
            v-if="isThisSliderDraggingOrNone('date')"
            :width="sliderWidth"
            :containerRef="getContainerRef"
            :currentX="colWidth.process + colWidth.location + colWidth.date"
            @moved="handleDateSliderMoved"
            @started="draggingSliderName = 'date'"
            @ended="draggingSliderName = null"
            :tableContentHeight="tableContent?.scrollHeight"
          />
          <TableResizeSlider
            v-if="isThisSliderDraggingOrNone('duration')"
            :width="sliderWidth"
            :containerRef="getContainerRef"
            :currentX="colWidth.process + colWidth.location + colWidth.date + colWidth.duration"
            @moved="handleDurationSliderMoved"
            @started="draggingSliderName = 'duration'"
            @ended="draggingSliderName = null"
            :tableContentHeight="tableContent?.scrollHeight"
          />
        </template>
      </div>
      <ul
        class="border-t-2 flex justify-between w-min text-sm h-6 bg-gray-50"
        v-if="summary"
        :style="{ minWidth: width + 'px' }"
      >
        <li
          v-for="(column, index) in columns"
          :key="index"
          :class="[
            'py-1 px-3 text-left text-xs font-medium justify-self-start text-gray-500 overflow-hidden truncate',
            { hidden: column.field === 'process_ids' },
          ]"
          :style="{
            width: colWidth[column.field as ColNames] + 'px',
            flex: '0 0 ' + colWidth[column.field as ColNames] + 'px',
          }"
        >
          <span v-if="summary[column.field as ColNames]">
            <span
              :style="{
                filter: hideWorkingHours(column) ? 'blur(3px)' : '',
                'user-select': hideWorkingHours(column) ? 'none' : 'auto',
              }"
            >
              {{
                !hideWorkingHours(column)
                  ? summary[column.field as ColNames]
                  : `Σ 000.00 ${t("time.hour", { count: 2 })}`
              }}
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ArrowDownIcon,
  ChevronDownIcon,
  FunnelIcon,
  LockClosedIcon,
} from "@heroicons/vue/24/outline";
import { FunnelIcon as FunnelIconSolid } from "@heroicons/vue/24/solid";
import VueDatePicker from "@vuepic/vue-datepicker";
import { OnClickOutside } from "@vueuse/components";
import { useElementSize } from "@vueuse/core";
import { format, getWeek, isDate } from "date-fns";
import { OaiTooltip } from "oai-components";
import { ProcessClass } from "oai-services";
import { computed, nextTick, onMounted, onUnmounted, PropType, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import TableResizeSlider from "@/components/other/TableResizeSlider.vue";
import LocationFilter from "@/components/process_filters/LocationFilter.vue";
import ProcessType from "@/components/process_filters/ProcessTypesFilter.vue";
import { getProcessTypes, useProcessClasses } from "@/composables/process";
import { useHasProjectFeature } from "@/composables/project";
import { useTrackEvent } from "@/composables/tracking";
import { debounce } from "@/services/input";
import { ResourceGroup } from "@/types/Process";
import { TableResizeSliderEvent } from "@/types/Tables";
import { Column, Data } from "@/views/process_table/types";

const props = defineProps({
  columns: {
    type: Array as PropType<Column[]>,
    required: true,
  },
  data: {
    type: Array as PropType<Data[]>,
    required: true,
  },
  onDateFilter: {
    type: Function as PropType<(value: [Date, Date], data: Data[]) => Data[]>,
    required: false,
  },
  onRowClick: {
    type: Function as PropType<(row: Data) => void>,
    required: false,
  },
  onRowDbClick: {
    type: Function as PropType<(row: Data) => void>,
    required: false,
  },
  exportToExcel: {
    type: Function as PropType<() => void>,
    required: false,
  },
  sortedBy: {
    type: String,
    required: false,
  },
  summary: {
    type: Object as PropType<Record<string, string>>,
    required: false,
  },
  tagGroups: {
    type: Array as PropType<ResourceGroup[]>,
    required: true,
  },
  width: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(["update:data"]);
const { locale, t } = useI18n();

const processClasses = useProcessClasses();

const data = ref(props.data);
const columns = ref(props.columns);
const filterSelectValues = ref({} as Record<string, { name: string; value: string }[]>);
const dateRange = ref(null);
const tableContent = ref(null as HTMLDivElement | null);
const router = useRouter();
const route = useRoute();

const lastSorted = ref(null as Column | null);
const tableIsScrolling = ref<boolean>(false);
const sliderWidth = 20;
type SliderName = "process" | "location" | "date" | "duration" | "working_hours" | "full";
const minColumnWidth = 130;
const getContainerRef = () => tableContent;
const draggingSliderName = ref<SliderName | null>(null);
const isThisSliderDraggingOrNone = (sliderName: SliderName) =>
  draggingSliderName.value === null || draggingSliderName.value === sliderName;
// Usage
const { width: tableWidth } = useElementSize(
  tableContent,
  { width: 0, height: 0 },
  { box: "border-box" },
);

type ColNames = "process" | "location" | "date" | "duration" | "working_hours";
const colWidth = ref({
  process_ids: 0,
  process: 0,
  location: 0,
  date: 0,
  duration: 0,
  working_hours: 0,
} as Record<ColNames, number>);

const displayedData = computed(() => {
  return data.value?.slice(0, countPages.value * 100);
});

const detectTableScroll = (event: Event) => {
  const target = event.target as HTMLElement;
  const scrollTop = target.scrollTop || 0;
  tableIsScrolling.value = scrollTop !== 0;
};

const renderCache: Map<CallableFunction, Map<unknown, unknown>> = new Map();
const processTypes = ref([]) as Ref<string[]>;
const tableInitialWidth = ref();

const extractFilters = (columns: Column[]) => {
  return columns.reduce((acc, column) => {
    if (!column.filterDisabled) {
      column.filters?.forEach((filter) => {
        acc[filter.type] =
          filter.filterValue && Object.keys(filter.filterValue).length > 0
            ? filter.filterValue
            : null;
      });
    }
    return acc;
  }, {} as Record<string, string | string[] | Record<string, string[]> | null | Date[]>);
};

const filters = ref(extractFilters(props.columns));

watch(
  () => props.columns,
  (value) => {
    columns.value = value;
  },
);

watch(
  () => props.data,
  (value) => {
    filters.value = extractFilters(columns.value);

    filterSelectValues.value = extractFilterSelectValues(value);

    processTypes.value = getProcessTypes(value, processClasses.value);
    data.value = value;

    if (props.sortedBy) {
      const column = columns.value.find((column) => column.field === props.sortedBy);
      if (column) {
        handleSort(column);
      }
    }
  },
);

watch(
  () => props.width,
  () => {
    colWidth.value = calculateColumnWidth(props.width);
  },
);
const initialLastCols = ref();

onMounted(() => {
  nextTick(() => {
    tableInitialWidth.value = props.width;
    colWidth.value = calculateColumnWidth(props.width);
    initialLastCols.value = colWidth.value.working_hours;
  });
  window.addEventListener("resize", handleWindowResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleWindowResize);
});

const handleWindowResize = () => {
  const column: Column | undefined = columns.value.find((col) => col.showFilter);

  if (column) {
    resizeFilterPopover(column);
  }
};

const resizeFilterPopover = (column: Column) => {
  const filterPopover = document.querySelector(
    `#filterContainer_${column.field} .PopoverPanelContent`,
  );
  const filterButton = document.getElementById(`filterButton_${column.field}`);
  if (!filterPopover || !filterButton) {
    return;
  }

  const popoverRect = filterPopover.getBoundingClientRect();
  const buttonRect = filterButton.getBoundingClientRect();
  const filterButtonCenter = buttonRect.left + buttonRect.width / 2;
  const windowWidth = window.innerWidth;

  // Center the popover by default
  let leftPosition = filterButtonCenter - popoverRect.width / 2;

  // Adjust if the popover goes off the right edge of the screen
  if (leftPosition + popoverRect.width >= windowWidth) {
    leftPosition = buttonRect.left - popoverRect.width;
  }
  // Adjust if the popover goes off the left edge of the screen
  if (leftPosition < 0) {
    leftPosition = 20;
  }

  filterStyles.value = {
    left: `${leftPosition}px`,
    opacity: "1",
  };
};
const countPages = ref<number>(1);

const detectHorizontalScroll = () => {
  columns.value.forEach((column) => {
    column.showFilter = false;
  });
};
const calculateColumnWidth = (width: number) => {
  return {
    process: width * 0.25,
    location: width * 0.4,
    date: width * 0.15,
    duration: width * 0.1,
    working_hours: width * 0.1,
  };
};

const anyOpenFilterComponent = computed(() => {
  return props.columns.find((column) => {
    return column.showFilter; // Make sure to return the result of column.showFilter
  });
});

const getFromRenderCache = (func: CallableFunction, value: unknown) => {
  const cached = renderCache.get(func)?.get(value);
  if (cached) {
    return cached;
  }
  const result = func(value);
  if (!renderCache.has(func)) {
    renderCache.set(func, new Map());
  }

  renderCache.get(func)?.set(value, result);
  return result;
};

const handleSort = (column: Column) => {
  lastSorted.value = column;
  column.sortActive = !column.sortActive;
  column.sortDirection = column.sortDirection === "desc" ? "asc" : "desc";
  const multiplier = column.sortDirection === "asc" ? 1 : -1;
  const sortFunction = column.toggleSort;
  if (sortFunction) {
    data.value.sort((a, b) => sortFunction(a[column.field], b[column.field]) * multiplier);
    return;
  }

  data.value.sort((a, b) => {
    const aValue = a[column.field];
    const bValue = b[column.field];

    if (typeof aValue === "string" && typeof bValue === "string") {
      return aValue.localeCompare(bValue) * multiplier;
    }

    if (typeof aValue === "number" && typeof bValue === "number") {
      return (aValue - bValue) * multiplier;
    }

    if (isDate(aValue) && isDate(bValue)) {
      return ((aValue as Date).getTime() - (bValue as Date).getTime()) * multiplier;
    }
    return 0;
  });
};

const extractFilterSelectValues = (filterData: Data[]) => {
  const filterColumns = new Set(
    columns.value
      .filter((column) => column.filters?.some((f) => f.type === "process" || f.type === "select"))
      .map((column) => column.field),
  );
  const values = filterData.reduce((acc, item) => {
    Object.entries(item).forEach(([key, value]) => {
      if (filterColumns.has(key)) {
        if (key === "process") {
          acc[key] = acc[key] || new Set();
          const encodedLabel = item?.encoded_label?.toString();
          if (encodedLabel !== null) {
            acc[key].add(encodedLabel);
          }
        } else {
          acc[key] = acc[key] || new Set();
          acc[key].add(value);
        }
      }
    });
    return acc;
  }, {} as Record<string, Set<unknown>>);

  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => [
      key,
      (Array.from(value) as string[])
        .sort((a, b) => a.localeCompare(b))
        .filter((v) => v)
        .map((v) => ({
          name: t(`process_classes.${v}`),
          value: v,
        })),
    ]),
  );
};

const filterStyles = ref<Record<string, string> | null>(null);

const toggleFilter = (column: Column, mode?: boolean) => {
  if (column.filterDisabled) {
    return;
  }

  column.showFilter = mode !== undefined ? mode : !column.showFilter;

  if (column.showFilter) {
    filterStyles.value = { opacity: "0" };
    setTimeout(() => {
      resizeFilterPopover(column);
    }, 100);
  }
};

const resetAllFilters = () => {
  filters.value = props.columns.reduce((acc, column) => {
    acc[column.field] = null;
    return acc;
  }, {} as Record<string, null>);

  columns.value.forEach((column) => {
    if (column.filterValue) {
      column.filterValue = undefined;
    }

    column.filters?.forEach((filter) => {
      if (column.field === "location") {
        filter.filterValue = {
          building: [] as string[],
          level: [] as string[],
          section: [] as string[],
          component: [] as string[],
        };
      } else if (column.field === "process") {
        filter.filterValue = { processes: [], processTypes: [] } as Record<
          "processes" | "processTypes",
          string[]
        >;
      } else if (column.field === "process_ids") {
        if (filter.filterValue) {
          (filter.filterValue as unknown) = [];
        }
      } else {
        if (filter.filterValue) {
          (filter.filterValue as unknown) = undefined;
        }
      }
    });
    column.showFilter = false;
  });

  dateRange.value = null;
  data.value = props.data;
  setTimeout(() => {
    router.replace({
      query: {
        ...route.query,
        processes: undefined,
        processTypes: undefined,
        building: undefined,
        level: undefined,
        section: undefined,
        component: undefined,
        process_ids: undefined,
        processId: undefined,
      },
    });
  }, 100);
  emits("update:data", data.value);
};

const filterAllColumns = () => {
  let dataToFilter = props.data;

  if (dateRange.value) {
    dataToFilter = props.onDateFilter?.(dateRange.value, dataToFilter) || dataToFilter;
  }

  Object.entries(filters.value).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    dataToFilter = dataToFilter.filter((item) => {
      const column = columns.value.find((column) => column.field === key);

      if (!column) {
        return true;
      }

      if (column.filters?.length) {
        return column.filters?.every((filter) => {
          if (filter.filterValue === undefined) {
            return true;
          }
          return filter.filterFn(filter.filterValue, item, column);
        });
      }
      return stringFilterFunction(item, column);
    });
  });

  data.value = dataToFilter;
  emits("update:data", data.value);
};

const checkEmptyLocationFilter = computed(() => {
  const location = filters?.value.location as null | Record<string, Set<string> | string[]>;
  if (location) {
    return Object.entries(location).every(([, value]) => {
      if (value instanceof Set) {
        return value.size === 0;
      } else if (Array.isArray(value)) {
        return value.length === 0;
      }
      return true;
    });
  }
  return true;
});

const checkEmptyProcessFilter = computed(() => {
  const processes = filters?.value.process as null | Record<"processTypes" | "processes", string[]>;
  if (processes) {
    return processes.processes.length === 0;
  }

  return true; // Default to true if processes is null or undefined
});

const trackEvent = useTrackEvent();

const handleCustomFilter = (
  value: string | string[] | Record<"processTypes" | "processes", string[]>,
  column: Column,
) => {
  const filter = column.filters?.find(Boolean);
  if (filter) {
    filter.filterValue = value;
  }

  filters.value = {
    ...filters.value,
    [column.field]: value,
  };
  trackEvent("activity-log_filter_apply", { type: column.field });
  filterAllColumns();
};

watch(
  () => filters.value,
  () => {
    const processColFilters = filters.value.process as Record<string, string[]>;
    const locationColFilters = filters.value.location as Record<string, string[]>;
    const dateColFilters = filters.value.date as unknown as Date[];

    if (processColFilters || locationColFilters || dateColFilters) {
      router.replace({
        query: {
          ...route.query,
          processes: processColFilters?.processes.length
            ? processColFilters?.processes.join(",")
            : undefined,
          processTypes: processColFilters?.processTypes.length
            ? processColFilters?.processTypes.join(",")
            : undefined,
          building: locationColFilters?.building.length
            ? locationColFilters.building.join(",")
            : undefined,
          level: locationColFilters?.level.length ? locationColFilters.level.join(",") : undefined,
          section: locationColFilters?.section.length
            ? locationColFilters?.section.join(",")
            : undefined,
          component: locationColFilters?.component.length
            ? locationColFilters?.component.join(",")
            : undefined,
          date: dateColFilters?.length
            ? dateColFilters.map((item: Date) => format(item, "yyyy-MM-dd")).join(",")
            : undefined,
        },
      });
    }
    filterAllColumns();
  },
  { deep: true },
);

const stringFilterFunction = (item: Data, column: Column) => {
  if (!filters.value[column.field]) {
    return true;
  }
  const value = String(item[column.field]).toLowerCase();
  const filterValue = String(filters.value[column.field]).toLowerCase();
  return value.includes(filterValue);
};

const handleFilter = (value: string, column: Column) => {
  filters.value = {
    ...filters.value,
    [column.field]: value,
  };
  filterAllColumns();
};

const hasWorkingHoursFeature = useHasProjectFeature("working_hours");

const hideWorkingHours = (column: Column) => {
  return column.field === "working_hours" && !hasWorkingHoursFeature;
};

const processIdMap = processClasses.value.reduce((acc, item) => {
  if (item.encodedLabel !== undefined && item.processElement !== undefined) {
    acc[item.encodedLabel] = item;
  }
  return acc;
}, {} as Record<string, ProcessClass>);

const handleProcessFilter = (
  currentValue: Record<"processTypes" | "processes", string[]>,
  initialValue: string | string[] | Record<string, string[] | Date[]>,
  column: Column,
) => {
  const prevValue = initialValue as Record<"processes" | "processTypes", string[]>;
  if (currentValue.processes.length !== prevValue.processes.length) {
    const prevProcessesSet = new Set(prevValue.processes);
    const currentProcessesSet = new Set(currentValue.processes);
    // add filter
    if (currentValue.processes.length > prevValue.processes.length) {
      const diff = currentValue.processes.find((item) => !prevProcessesSet.has(item));
      if (diff) {
        const processElement = processIdMap[diff].processElement;
        if (!currentValue.processTypes.includes(processElement)) {
          currentValue.processTypes.push(processElement);
        }
      }
    } else {
      const diff = prevValue.processes.find((item) => !currentProcessesSet.has(item));
      if (diff !== undefined) {
        const processElement = processIdMap[diff].processElement;
        const allItemsUnselected = processClasses.value
          .filter(
            (item) =>
              item.processElement === processElement &&
              prevProcessesSet.has(item.encodedLabel.toString()),
          )
          .some((item) => item.encodedLabel.toString() !== diff);

        if (!allItemsUnselected) {
          (currentValue.processTypes as string[]).splice(
            (currentValue.processTypes as string[]).indexOf(processElement),
            1,
          );
        }
      }
    }
  } else if (currentValue.processTypes.length !== prevValue.processTypes.length) {
    // add filter
    if (currentValue.processTypes.length > prevValue.processTypes.length) {
      const diff = currentValue.processTypes.find((item) => !prevValue.processTypes.includes(item));
      const processList: string[] = [];
      props.data?.forEach((item) => {
        if (!processList.includes(item?.encoded_label as string))
          processList.push(item?.encoded_label as string);
      });
      processClasses.value.forEach((item) => {
        if (item.processElement === diff) {
          if (processList.includes(item?.encodedLabel as unknown as string)) {
            (currentValue.processes as string[]).push(item.encodedLabel.toString());
          }
        }
      });
      // remove filter
    } else {
      const mappedProcessTypes = new Set(currentValue.processTypes);
      const diff = prevValue.processTypes.find((item) => !mappedProcessTypes.has(item));
      currentValue.processes = (currentValue.processes as string[]).filter(
        (item) => processIdMap[item].processElement !== diff,
      );
    }
  }
  handleCustomFilter(currentValue, column);
};
const handleDebouncedFilter = debounce(handleFilter, 300);
const handleDebouncedCustomFilter = debounce(handleCustomFilter, 300);

const activeFilters = computed(() => {
  return (
    !checkEmptyLocationFilter.value ||
    dateRange.value || // Assuming dateRange is a ref
    Object.entries(filters.value).some(
      ([key, value]) =>
        !["location", "process"].includes(key) && Array.isArray(value) && value.length > 0,
    ) ||
    !checkEmptyProcessFilter.value
  );
});

const anyFilterOpen = computed(() => {
  return columns.value.some((item) => item.showFilter);
});

const removeFilter = (column: Column) => {
  column.filters?.forEach((filter) => {
    if (column.field === "location") {
      filter.filterValue = {
        building: [] as string[],
        level: [] as string[],
        section: [] as string[],
        component: [] as string[],
      };
    } else if (column.field === "process") {
      filter.filterValue = { processes: [], processTypes: [] } as Record<
        "processes" | "processTypes",
        string[]
      >;
    } else {
      if (filter.filterValue) {
        (filter.filterValue as unknown) = undefined;
      }
    }
    handleDebouncedCustomFilter(filter.filterValue, column, filter.filterFn);
  });
  column.showFilter = false;

  emits("update:data", data.value);
};

const columnTotal = () => Object.values(colWidth.value).reduce((a, b) => a + b, 0);

const handleProcessSliderMoved = (value: TableResizeSliderEvent) => {
  if (value.x > minColumnWidth) {
    const newProcessWidth = value.x;
    if (newProcessWidth > minColumnWidth && newProcessWidth < props.width) {
      colWidth.value.process = newProcessWidth;
    }
    resetLastColumn();
  }
};

const handleLocationSliderMoved = (value: TableResizeSliderEvent) => {
  const newLocationWidth = value.x - colWidth.value.process;

  if (newLocationWidth > minColumnWidth && newLocationWidth < props.width) {
    colWidth.value.location = newLocationWidth;
  }
  resetLastColumn();
};

const handleDateSliderMoved = (value: TableResizeSliderEvent) => {
  const newDateWidth = value.x - colWidth.value.location - colWidth.value.process;

  if (newDateWidth > minColumnWidth && newDateWidth < props.width) {
    colWidth.value.date = newDateWidth;
  }
  resetLastColumn();
};

const handleDurationSliderMoved = (value: TableResizeSliderEvent) => {
  const newDurationWidth =
    value.x - colWidth.value.date - colWidth.value.location - colWidth.value.process;

  if (newDurationWidth > minColumnWidth && newDurationWidth < props.width) {
    colWidth.value.duration = newDurationWidth;
  }

  resetLastColumn();
};

const resetLastColumn = () => {
  const total = columnTotal();
  if (total < props.width) {
    colWidth.value.working_hours = props.width - (total - colWidth.value.working_hours);
  }
  if (total > props.width && colWidth.value.working_hours > minColumnWidth) {
    colWidth.value.working_hours = total - colWidth.value.working_hours - props.width;
  }
};

const hasChangeOfKw = (value: unknown, index: number) => {
  const date = value as Date;
  return (
    (index === 0 && lastSorted.value?.field === "date") ||
    (lastSorted.value?.field === "date" &&
      data.value[index - 1] &&
      value &&
      getWeek(date) !== getWeek(data.value[index - 1].date as Date))
  );
};

defineExpose({
  resetAllFilters: resetAllFilters,
  activeFilters: activeFilters,
  tableWidth: tableWidth.value,
});
</script>
