import { AxiosResponse } from "axios";
import { AxiosInstance } from "axios";
import { parseISO } from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import {
  NonWorkingDay,
  ProjectDurationSettings,
  ProjectDurationSettingsToUpdate,
  PublicHolidaysResponse,
} from "../types/ProjectDurationSettings";

export default (client: AxiosInstance) => {
  const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

  const formatUtcDate = (date: Date): string =>
    fromZonedTime(date, "UTC").toISOString().replace("Z", "+00:00");

  const mapNonWorkingDays = (nonWorkingDays: NonWorkingDay<string>[]) =>
    nonWorkingDays.map((nonWorkingDay) => ({
      ...nonWorkingDay,
      start_date: parseUtcDate(nonWorkingDay.start_date),
      end_date: parseUtcDate(nonWorkingDay.end_date),
    }));

  const mapProjectDurationSettings = (
    projectDurationSettings: ProjectDurationSettings<string>,
  ): ProjectDurationSettings => ({
    ...projectDurationSettings,
    non_working_days: mapNonWorkingDays(projectDurationSettings.non_working_days),
  });

  const loadProjectDurationSettings = (
    customerName: string,
    siteId: string,
  ): Promise<ProjectDurationSettings> =>
    client
      .get<void, AxiosResponse<ProjectDurationSettings<string>>>(
        `/durations/${customerName}/${siteId}/settings`,
      )
      .then((response) => mapProjectDurationSettings(response.data));

  const formatProjectDurationSettingsToUpdate = (
    projectDurationSettingsToUpdate: ProjectDurationSettingsToUpdate,
  ) => ({
    ...projectDurationSettingsToUpdate,
    non_working_days: projectDurationSettingsToUpdate.non_working_days?.map((non_working_day) => ({
      ...non_working_day,
      name: non_working_day.name,
      start_date: formatUtcDate(non_working_day.start_date),
      end_date: formatUtcDate(non_working_day.end_date),
    })),
  });

  const updateProjectDurationSettings = (
    customerName: string,
    siteId: string,
    projectDurationSettingsToUpdate: ProjectDurationSettingsToUpdate,
  ): Promise<ProjectDurationSettings> =>
    client
      .patch<void, AxiosResponse<ProjectDurationSettings<string>>>(
        `/durations/${customerName}/${siteId}/settings`,
        formatProjectDurationSettingsToUpdate(projectDurationSettingsToUpdate),
      )
      .then((response) => mapProjectDurationSettings(response.data));

  const loadPublicHolidays = (
    countryCode: string,
    zipCode: string,
    year: number,
  ): Promise<PublicHolidaysResponse | null> =>
    client
      .get<void, AxiosResponse<PublicHolidaysResponse<string>>>(
        `/durations/public-holidays/${countryCode}/${zipCode}/${year}`,
      )
      .then((response) =>
        response.data
          ? {
              ...response.data,
              holidays: mapNonWorkingDays(response.data.holidays),
            }
          : null,
      );

  return {
    loadProjectDurationSettings,
    updateProjectDurationSettings,
    loadPublicHolidays,
  };
};
