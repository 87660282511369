<template>
  <div @keyup.esc="handleEsc">
    <div class="fixed inset-0 bg-gray-500/75" style="z-index: 9999" />
    <div
      class="fixed inset-0 flex items-start lg:items-center justify-center"
      style="z-index: 10000"
      @click="emit('closed')"
    >
      <div
        v-if="!showPageSelector"
        class="p-3 flex gap-1 flex-col text-gray-700 projectContainer"
        @click.stop
      >
        <input
          ref="searchInput"
          autofocus
          autocomplete="off"
          class="px-3 py-2 w-full rounded border border-gray-300 focus:border-gray-300 outline-none placeholder-gray-300"
          placeholder="search for project, customer name or site id..."
          v-model="searchValue"
          @keydown="handleInputKeyDown"
        />
        <div class="bg-white border border-gray-300 overflow-auto py-1" style="height: 300px">
          <div
            :id="`quick-search-project-${project._id}`"
            v-for="project in projects"
            :key="project._id"
            class="flex gap-1 cursor-pointer px-3 py-1.5 text-sm hover:bg-gray-200"
            :class="selectedProject?._id === project._id ? ['bg-gray-100'] : []"
            @click="handleProjectClick($event, project)"
          >
            <div class="flex gap-1 items-center flex-1 min-w-0">
              <div class="truncate">{{ project.name }}</div>
              <div class="text-gray-400">{{ project.customer_name }}</div>
              <div class="text-gray-400">{{ project.site_id }}</div>
              <Bars4Icon
                class="text-gray-400 w-4 h-4 ml-2 cursor-context-menu"
                @click="handleOpenMenuClick($event, project)"
              />
            </div>
            <div class="gap-1 items-center hidden lg:flex">
              <div class="text-gray-300 font-mono text-xs" style="width: 200px">
                {{ format(project.planned_start, "dd.MM.yyyy") }}
                {{ project.planned_end ? `- ${format(project.planned_end, "dd.MM.yyyy")}` : "" }}
              </div>
              <div
                class="text-right"
                :class="project.status === 'active' ? ['text-green-400'] : ['text-blue-400']"
                style="width: 80px"
              >
                {{ project.status }}
              </div>
            </div>
          </div>
          <div v-if="projects.length === 0" class="px-3 py-1.5 text-sm">No projects found</div>
        </div>
      </div>
      <div
        v-if="showPageSelector && selectedProject"
        ref="pageContainerRef"
        class="p-3 flex gap-1 flex-col text-gray-700 pageContainer select-none focus:outline-none"
        @click.stop
        @keydown="handlePageKeyDown"
        :tabindex="-1"
      >
        <div class="bg-white overflow-auto">
          <div class="text-sm px-3 py-1.5 font-semibold bg-gray-600 text-white truncate">
            {{ selectedProject.customer_name }}/{{ selectedProject.site_id }}
          </div>
          <div
            v-for="(page, index) in pages"
            :key="page.path"
            class="flex gap-1 cursor-pointer px-3 py-1.5 text-sm hover:bg-gray-200"
            :class="{
              'bg-gray-100': selectedPage?.path === page.path,
              'border-t': index === pages.length - 1,
            }"
            @click="goToSelectedProject(page)"
          >
            <div style="width: 40px">[{{ page.key }}]</div>
            <div class="truncate">{{ page.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Bars4Icon } from "@heroicons/vue/24/solid";
import { format } from "date-fns";
import FuzzySearch from "fuzzy-search";
import { useHasPermission } from "oai-services";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import textService from "@/services/textService";
import { Project } from "@/types/Project";

type Page = {
  label: string;
  path: string;
  key: string;
};

const backPage: Page = {
  label: "back",
  path: "back",
  key: "esc",
};

const searchValue = ref("");
const selectedProject = ref<Project | null>(null);
const searchInput = ref<HTMLInputElement | null>(null);
const pageContainerRef = ref<HTMLDivElement | null>(null);
const showPageSelector = ref(false);
const selectedPage = ref<Page | null>(null);

const store = useStore();
const router = useRouter();

const emit = defineEmits(["closed"]);

const normalizedProjects = computed(
  () =>
    store.state.projects.map((project: Project) => ({
      ...project,
      normalized_name: textService.normalize(project.name),
      normalized_customer_name: textService.normalize(project.customer_name),
      normalized_site_id: textService.normalize(project.site_id),
    })) as Project[],
);

const projects = computed(() => {
  if (!searchValue.value) {
    return normalizedProjects.value;
  }

  const searchItems = textService
    .normalize(searchValue.value)
    .toLowerCase()
    .split(" ")
    .map((item) => item.trim())
    .filter((item) => item);

  return searchItems.reduce((acc, search) => {
    const searcher = new FuzzySearch(
      acc,
      ["normalized_name", "normalized_customer_name", "normalized_site_id"],
      {
        caseSensitive: false,
        sort: false,
      },
    );
    return searcher.search(search);
  }, normalizedProjects.value);
});

const pages = computed(() => {
  if (!selectedProject.value) {
    return [];
  }
  const { customer_name, site_id } = selectedProject.value;
  return [
    {
      label: "Dashboard",
      path: "/dashboard",
      key: "b",
    },
    useHasPermission("daily_report_user", customer_name, site_id) && {
      label: "Daily Report",
      path: "/daily_report/overview",
      key: "d",
    },
    useHasPermission("planner_user", customer_name, site_id) && {
      label: "Planner",
      path: "/planner",
      key: "t",
    },
    useHasPermission("processes_user", customer_name, site_id) && {
      label: "Process Gantt",
      path: "/processes",
      key: "p",
    },
    useHasPermission("processes_user", customer_name, site_id) && {
      label: "Process Table",
      path: "/processes/table",
      key: "a",
    },
    useHasPermission("processes_user", customer_name, site_id) &&
      selectedProject.value.features.includes("working_hours") &&
      selectedProject.value.process_groups.includes("default") && {
        label: "Unit Values",
        path: "/unit_values_editor",
        key: "u",
      },
    useHasPermission("processes_user", customer_name, site_id) && {
      label: "Reports",
      path: "/reports",
      key: "r",
    },
    useHasPermission("site_activity_user", customer_name, site_id) && {
      label: "Site activity",
      path: "/labour/site_activity",
      key: "h",
    },
    useHasPermission("project_admin", customer_name, site_id) && {
      label: "Settings",
      path: "/settings",
      key: "s",
    },
    backPage,
  ].filter((item) => item) as Page[];
});

watch(
  () => projects.value,
  () => {
    selectProject(projects.value[0]);
  },
);

onMounted(() => {
  searchValue.value = "";
  setTimeout(() => {
    searchInput.value?.focus();
    selectProject(projects.value[0]);
  }, 50);
});

const selectProject = (project: Project) => {
  selectedProject.value = project;
  if (project) {
    document.getElementById(`quick-search-project-${project._id}`)?.scrollIntoView({
      block: "center",
    });
  }
};

const setShowPageSelector = (show: boolean) => {
  showPageSelector.value = show;
  if (show) {
    nextTick(() => {
      // this has to be in nextTick, otherwise useHasPermission doesn't work in the pages computed property
      selectedPage.value = pages.value[0];
      pageContainerRef.value?.focus();
    });
  } else {
    selectedPage.value = null;
    nextTick(() => {
      searchInput.value?.focus();
      // reselect project, so it's scrolled into view
      if (selectedProject.value) {
        selectProject(selectedProject.value);
      }
    });
  }
};

const handleProjectClick = (event: MouseEvent, project: Project) => {
  selectedProject.value = project;
  if (event.shiftKey) {
    setShowPageSelector(true);
  } else {
    goToSelectedProject(null);
  }
};

const goToProject = (project: Project, page: Page | null) => {
  const pagePath = page?.path || "/dashboard";
  router.push(`/${project.customer_name}/${project.site_id}${pagePath}`);
  emit("closed");
};

const moveProjectUp = () => {
  const project =
    (selectedProject.value &&
      projects.value[projects.value.findIndex((p) => p._id === selectedProject.value?._id) - 1]) ||
    projects.value[projects.value.length - 1];
  selectProject(project);
};

const moveProjectDown = () => {
  const project =
    (selectedProject.value &&
      projects.value[projects.value.findIndex((p) => p._id === selectedProject.value?._id) + 1]) ||
    projects.value[0];
  selectProject(project);
};

const goToSelectedProject = (page: Page | null) => {
  if (page?.path === backPage.path) {
    setShowPageSelector(false);
    return;
  }
  if (selectedProject.value) {
    goToProject(selectedProject.value, page);
  }
};

const movePageUp = () => {
  selectedPage.value =
    (selectedPage.value &&
      pages.value[pages.value.findIndex((p) => p.path === selectedPage.value?.path) - 1]) ||
    pages.value[pages.value.length - 1];
};

const movePageDown = () => {
  selectedPage.value =
    (selectedPage.value &&
      pages.value[pages.value.findIndex((p) => p.path === selectedPage.value?.path) + 1]) ||
    pages.value[0];
};

const handleInputKeyDown = (event: KeyboardEvent) => {
  const handledKeys = ["ArrowDown", "ArrowUp", "Enter"];
  if (handledKeys.includes(event.key)) {
    event.stopPropagation();
    event.preventDefault();

    if (event.key === "ArrowUp") {
      moveProjectUp();
    }
    if (event.key === "ArrowDown") {
      moveProjectDown();
    }
    if (event.key === "Enter") {
      if (event.shiftKey) {
        setShowPageSelector(true);
      } else {
        goToSelectedProject(null);
      }
    }

    return false;
  }
};

const handlePageKeyDown = (event: KeyboardEvent) => {
  const handledKeys = ["ArrowDown", "ArrowUp", "Enter"];
  if (handledKeys.includes(event.key)) {
    event.stopPropagation();
    event.preventDefault();

    if (event.key === "ArrowUp") {
      movePageUp();
    }
    if (event.key === "ArrowDown") {
      movePageDown();
    }
    if (event.key === "Enter") {
      goToSelectedProject(selectedPage.value);
    }
  } else {
    const shortcutPage = pages.value.find((page) => page.key === event.key);
    if (shortcutPage) {
      event.stopPropagation();
      event.preventDefault();

      goToSelectedProject(shortcutPage);
    }
  }
};

const handleEsc = () => {
  if (showPageSelector.value) {
    setShowPageSelector(false);
  } else {
    emit("closed");
  }
};

const handleOpenMenuClick = (event: Event, project: Project) => {
  event.stopPropagation();
  selectProject(project);
  setShowPageSelector(true);
};
</script>

<style scoped>
.projectContainer {
  width: 100%;
  max-width: none;
}

.pageContainer {
  width: 100%;
  max-width: none;
}

@media (min-width: 1024px) {
  .projectContainer {
    width: 75%;
  }

  .pageContainer {
    width: min-content;
    min-width: 200px;
  }
}
</style>
