import { AxiosResponse } from "axios";
import { PlannerComment, PlannerRepository } from "oai-services";
import { apiClient } from "@/repositories/clients";
import { HierarchyTagStore } from "@/types/HierarchyTag";

const loadPlannerComments = (
  customerName: string,
  siteId: string,
  sourceId: string,
): Promise<PlannerComment[]> =>
  apiClient
    .get<void, AxiosResponse<PlannerComment[]>>(
      `/planner-v2/comments/${customerName}/${siteId}/${encodeURIComponent(sourceId)}`,
    )
    .then((response) => response.data);

const createComment = (
  customerName: string,
  siteId: string,
  comment: string,
  sourceId: string,
): Promise<PlannerComment> =>
  apiClient
    .post<void, AxiosResponse<PlannerComment>>(
      `/planner-v2/comments/${customerName}/${siteId}/${encodeURIComponent(sourceId)}`,
      {
        comment,
      },
    )
    .then((response) => response.data);

const deleteComment = (
  customerName: string,
  siteId: string,
  commentId: string,
): Promise<PlannerComment> =>
  apiClient
    .delete<void, AxiosResponse<PlannerComment>>(
      `/planner-v2/comments/${customerName}/${siteId}/${encodeURIComponent(commentId)}`,
    )
    .then((response) => response.data);

const loadHierarchyTags = (customerName: string, siteId: string) =>
  apiClient
    .get<void, AxiosResponse<HierarchyTagStore[]>>(
      `/planner-v2/hierarchy/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

export default {
  ...PlannerRepository(apiClient),
  loadPlannerComments,
  createComment,
  deleteComment,
  loadHierarchyTags,
};
