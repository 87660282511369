<template>
  <div class="relative" :style="sizeStyles">
    <div class="text-center pl-6 pr-8 truncate" :style="{ height: `${headerHeight}px` }">
      <RouterLink
        class="text-lg font-semibold text-gray-900 hover:text-yellow-800"
        :to="{
          name: 'Reports',
          query: {
            report: reportId,
          },
        }"
        @click.prevent="emit('widgetRouteClicked')"
      >
        {{ title }}
      </RouterLink>
    </div>
    <ReportFilterBadges
      type="unit_values"
      :filters="filters"
      :reportSummary="data"
      :aggregationLabel="t(`analytics.reports.by_${aggregation}`)"
      :height="filterBadgesHeight"
      class="ml-2"
    />
    <UnitValuesPopover
      class="absolute top-1 right-4"
      v-if="!hidePopover"
      @reportInfoClick="emit('reportInfoClick')"
    />
    <RouterLink
      :to="{
        name: 'UnitValuesEditor',
        params: {
          customer_name: customerName,
          site_id: siteId,
        },
      }"
      v-if="areAllUnitValuesMissing && !hidePopover"
      class="absolute top-2 right-14 text-yellow-700 underline cursor-pointer text-xs flex items-center gap-1"
      target="_blank"
    >
      <span>{{ t("analytics.reports.unit_values_missing") }}</span>
      <ArrowTopRightOnSquareIcon class="w-3 h-3" />
    </RouterLink>
    <div :style="{ height: `${chartHeight}px` }" class="overflow-hidden">
      <Chart :options="chartOptions" class="font-light text-b" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline";
import { Chart } from "highcharts-vue";
import { useCurrentCustomerName, useCurrentSiteId } from "oai-services";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { DashboardGridSize } from "@/types/Dashboard";
import { HierarchyType } from "@/types/HierarchyTag";
import {
  UnitValuesReportSummary,
  UnitValuesReportConfig,
  UnitValuesReportFilters,
  UnitValuesAggregation,
} from "@/types/reports/PlotUnitValues";
import { useChartSize } from "@/views/reports/composables";
import ReportFilterBadges from "@/views/reports/filters/ReportFilterBadges.vue";
import UnitValuesPopover from "@/views/reports/plots/unit_values/UnitValuesPopover.vue";

type PlotMetaData = {
  y: number | null;
  workforce: number;
  duration: number;
  type: string;
  isActive: boolean;
};

const defaultFullHeight = 600;

const { t } = useI18n();
const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();

const props = withDefaults(
  defineProps<{
    data?: UnitValuesReportSummary;
    reportId: string;
    filters: UnitValuesReportFilters;
    config: UnitValuesReportConfig;
    aggregation: UnitValuesAggregation;
    title: string;
    hidePopover?: boolean;
    width?: number;
    height?: number;
    hideLegend?: boolean;
    spacingX?: number;
    spacingY?: number;
    dashboardGridSize?: DashboardGridSize;
  }>(),
  { height: defaultFullHeight, spacingX: 0, spacingY: 0 },
);

const emit = defineEmits(["widgetRouteClicked", "reportInfoClick"]);

const areAllUnitValuesMissing = computed(
  () => !!(props.data?.unit_values.length && props.data.unit_values.every((item) => !item.value)),
);

const { headerHeight, chartHeight, sizeStyles, filterBadgesHeight } = useChartSize(
  computed(() => ({ ...props })),
  {
    minGridColumns: 2,
    minGridRows: 1,
  },
);

const defaultChartOptions = computed(() => ({
  chart: {
    zoomType: "x",
    height: chartHeight.value,
  },
  title: {
    text: undefined,
  },
  yAxis: [
    {
      title: {
        text: t("analytics.reports.working_hours"),
      },
      min: 0,
    },
    {
      title: {
        text: t("analytics.reports.unit_values"),
      },
      opposite: true,
      min: 0,
    },
  ],
  legend: { enabled: !props.hideLegend },
}));

const chartOptions = computed(() => {
  if (!props.data) {
    return defaultChartOptions.value;
  }

  const unitValueType = props.data.unit_values[0]?.type;

  const aggregatedData = makeDataAggregation(props.data.unit_values, props.aggregation);
  return getChartOptions(aggregatedData, unitValueType);
});

const calculateMean = (data: number[]) => {
  const sum = data
    .filter((value) => typeof value === "number")
    .reduce((acc, value) => acc + value, 0);

  const rounded = Math.round((sum / data.length) * 100) / 100;

  return rounded;
};

const calculateSum = (data: number[]) => {
  const sum = data
    .filter((value) => typeof value === "number")
    .reduce((acc, value) => acc + value, 0);

  const rounded = Math.round(sum * 100) / 100;

  return rounded;
};

const makeDataAggregation = (
  data: UnitValuesReportSummary["unit_values"],
  aggregationLevel: HierarchyType,
) => {
  const aggregations = {} as Record<string, UnitValuesReportSummary["unit_values"]>;

  const sortedData = data.slice();
  sortedData.sort((a, b) => {
    const aLocation = a.hierarchy_tags;
    const bLocation = b.hierarchy_tags;

    if (aLocation.building?.number !== bLocation.building?.number) {
      return aLocation.building?.number - bLocation.building?.number;
    }

    if (aLocation.level?.number !== bLocation.level?.number) {
      return aLocation.level?.number - bLocation.level?.number;
    }

    return aLocation.section?.number - bLocation.section?.number;
  });

  sortedData.forEach((item) => {
    const keys = [];
    const building = item.hierarchy_tags.building?.name;
    const level = item.hierarchy_tags.level?.name;
    const section = item.hierarchy_tags.section?.name;

    if (aggregationLevel === "building") {
      keys.push(building);
    } else if (aggregationLevel === "level") {
      keys.push(building, level);
    } else if (aggregationLevel === "section") {
      keys.push(building, level, section);
    }

    const key = keys.filter(Boolean).join(" - ");

    if (!aggregations[key]) {
      aggregations[key] = [];
    }

    aggregations[key].push(item);
  });

  const prettifiedAggregation = Object.entries(aggregations).reduce(
    (acc, [key, value]) => {
      const unitValue = calculateMean(value.map((item) => item.value as number));
      const duration = calculateSum(
        value.map((item) => (item.duration as number) * (item.workforce as number)),
      );
      const workforceSum = calculateSum(value.map((item) => item.workforce as number));
      const durationSum = calculateSum(value.map((item) => item.duration as number));
      const quantitySum = calculateSum(value.map((item) => item.quantity as number));
      const isActive = value.some((item) => item.state === "in_progress");

      const meta = {
        workforce: workforceSum,
        duration: durationSum,
        quantity: quantitySum,
        type: value[0]?.type,
        isActive: isActive,
      };

      const entries = {
        name: key,
        unitValue: {
          y: unitValue || null,
          ...meta,
        },
        workingHours: {
          y: duration,
          ...meta,
        },
      };

      acc.push(entries);

      return acc;
    },
    [] as {
      name: string;
      unitValue: PlotMetaData;
      workingHours: PlotMetaData;
    }[],
  );

  return prettifiedAggregation;
};

const getChartOptions = (
  data: {
    name: string;
    unitValue: PlotMetaData;
    workingHours: PlotMetaData;
  }[],
  unitValueType: string,
) => {
  const categories = data.map((item) => item.name);
  const activeCategories = new Set(data.map((item, i) => item.unitValue.isActive && i));

  const series = [
    {
      name: t("analytics.reports.working_hours"),
      data: data.map((item) => item.workingHours),
      type: "column",
      stacking: undefined,
      yAxis: 0,
      color: "#438F7F",
    },
    {
      name: t("analytics.reports.unit_values"),
      data: data.map((item) => item.unitValue),
      type: "spline",
      yAxis: 1,
      color: "#E7BC66",
      lineWidth: 2,
    },
  ];

  return {
    ...defaultChartOptions.value,
    xAxis: {
      categories,
      labels: {
        useHTML: true,
        style: {
          whiteSpace: "no-wrap",
        },
        autoRotationLimit: 1000,
        formatter: function () {
          const position = (this as unknown as { pos: number }).pos;
          const value = (this as unknown as { value: string }).value;

          if (activeCategories.has(position)) {
            return `<span><span class="text-green">●</span> ${value}</span>`;
          }

          return value;
        },
        crosshair: true,
      },
    },
    series: series,
    tooltip: {
      shared: true,
      useHTML: true,
      outside: true,
      formatter: function () {
        const { points, x } =
          (this as unknown as {
            points: Record<string, Record<string, unknown>>[];
            x: string;
          }) || {};

        let title = `
        <p class="text-sm mb-2 pr-1 border-b border-gray-200">
          ${x}
        </p>`;

        if (points[0]?.point?.type) {
          title += `
            <p class="mb-2">
              ${t(`unit_values.types.${points[0].point.type}.label`)} 
              [${t(`unit_values.types.${points[0].point.type}.unit`)}]
            </p>`;
        }

        const tooltipContent = points.reduce(function (s, point, i) {
          if (i === 0) {
            if (point.point.isActive) {
              s += `
              <p class="inline-block font-light text-xs rounded-md px-2 py-0 bg-green text-white mb-2">
                ${t("analytics.reports.in_progress")}
              </p>`;
            }

            if (point.point.workforce) {
              s += `  
              <p>
                ${t("unit_values.workforce_label")}: 
                <span class="font-bold">
                  ${point.point.workforce} ${t("unit_values.workforce_unit")}
                </span>
              </p>`;
            }
            if (point.point.duration) {
              s += `        
              <p>
                ${t("unit_values.duration_label")}: 
                <span class="font-bold">${point.point.duration} ${t(
                "unit_values.duration_unit",
              )}</span>
              </p>`;
            }

            if (point.point.quantity) {
              s += `        
              <p>
                ${t("unit_values.quantity_label")}: 
                <span class="font-bold">
                  ${point.point.quantity}
                  ${t(`unit_values.types.${unitValueType}.unit`)}
                </span>
              </p>`;
            }

            s += `<p class="mb-1"></p>`;
          }

          return `${s}<p>
            <span style="color: ${point.color}">●</span>
            ${point?.series?.name}:
            <span class="font-bold">${point.y}</span>
          </p>`;
        }, title);

        return `<div class="p-1">${tooltipContent}</div>`;
      },
    },
  };
};
</script>
